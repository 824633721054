import * as FromReport from "src/app/store/report/selectors";
import * as FromSurvey from "src/app/store/survey/selectors";
import { Pipe, PipeTransform } from "@angular/core";
import { ReportDto, SurveyFieldDto } from "@api";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { RootState } from "src/app/store";
import { map } from "rxjs/operators";
import { kebabCaseToMacroCase } from "src/app/utils/kebab-case-to-macro-case";

export type SurveyType = "REPORT" | "SURVEY";

@Pipe({ name: "defaultValue" })
export class DefaultValuePipe implements PipeTransform {
  constructor(private store: Store<RootState>) {}

  public transform(field: SurveyFieldDto, date: Date, slug: string, type: SurveyType): Observable<string> {
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    if (type === "REPORT") {
      return this.store.select(FromReport.selectReportsForDate(kebabCaseToMacroCase(slug) as ReportDto.DataSourceEnum, month, year)).pipe(
        map(reports => {
          if (reports) {
            const report = reports.find(r => r.dataDefinitionId === field.dataDefinitionId);
            if (report) return report.value.toString();
          }

          return field.defaultValue;
        }),
      );
    }

    return this.store.select(FromSurvey.selectSurveyFieldResponse(field.id)).pipe(
      map(response => {
        if (response) return response.value;
        return field.defaultValue;
      }),
    );
  }
}
