import { createReducer, on } from "@ngrx/store";
import { initialState } from "./state";
import * as EventActions from "./actions";

export const eventReducer = createReducer(
  initialState,

  on(
    EventActions.createEvent,
    EventActions.createEventContent,
    EventActions.getEvent,
    EventActions.getEventContent,
    EventActions.getEventContentEvents,
    EventActions.getEventContents,
    EventActions.getEventParticipants,
    EventActions.getEventInvitation,
    EventActions.getEvents,
    EventActions.getReferents,
    EventActions.removeEvent,
    EventActions.removeEventContent,
    EventActions.respondToEvent,
    EventActions.setLoading,
    EventActions.updateEvent,
    EventActions.updateEventContent,
    state => ({
      ...state,
      loading: true,
    }),
  ),

  on(
    EventActions.createEventContentFailure,
    EventActions.createEventFailure,
    EventActions.getEventContentEventsFailure,
    EventActions.getEventContentFailure,
    EventActions.getEventContentsFailure,
    EventActions.getEventParticipantsFailure,
    EventActions.getEventFailure,
    EventActions.getEventInvitationFailure,
    EventActions.getEventsFailure,
    EventActions.getReferentsFailure,
    EventActions.removeEventContentFailure,
    EventActions.removeEventFailure,
    EventActions.respondToEventFailure,
    EventActions.respondToEventForbidden,
    EventActions.updateEventContentFailure,
    EventActions.updateEventFailure,
    state => ({
      ...state,
      loading: false,
    }),
  ),

  on(EventActions.getEventContentsSuccess, (state, { data, page, total, pageCount }) => {
    const entities = {};
    data.forEach(eventContent => (entities[eventContent.id] = eventContent));

    return {
      ...state,
      eventContents: {
        ...entities,
      },
      currentPage: page,
      total,
      pageCount,
      loading: false,
    };
  }),

  on(EventActions.createEventContentSuccess, EventActions.getEventContentSuccess, (state, { eventContent }) => ({
    ...state,
    eventContents: {
      ...state.eventContents,
      [eventContent.id]: eventContent,
    },
    total: state.total + 1,
    loading: false,
  })),

  on(EventActions.updateEventContentSuccess, (state, { eventContent }) => ({
    ...state,
    eventContents: {
      ...state.eventContents,
      [eventContent.id]: eventContent,
    },
    loading: false,
  })),

  on(EventActions.removeEventContentSuccess, (state, { contentId }) => {
    const eventContents = {};
    Object.values(state.eventContents)
      .filter(content => content.id !== contentId)
      .forEach(content => (eventContents[content.id] = content));

    const events = {};
    Object.values(state.events)
      .filter(event => event.contentId !== contentId)
      .forEach(event => (events[event.id] = event));

    return {
      ...state,
      eventContents,
      events,
      total: state.total - 1,
      loading: false,
    };
  }),

  on(EventActions.getEventsSuccess, (state, { data, page, pageCount, total }) => {
    const entities = {};
    data.forEach(event => (entities[event.id] = event));

    return {
      ...state,
      events: {
        ...state.events,
        ...entities,
      },
      currentPage: page,
      pageCount,
      total,
      loading: false,
    };
  }),

  on(EventActions.getEventSuccess, (state, { event }) => ({
    ...state,
    events: {
      ...state.events,
      [event.id]: event,
    },
    loading: false,
  })),

  on(EventActions.getEventContentEventsSuccess, (state, { events }) => {
    const entities = {};
    events.forEach(event => (entities[event.id] = event));

    return {
      ...state,
      events: {
        ...state.events,
        ...entities,
      },
      loading: false,
    };
  }),

  on(EventActions.updateEventSuccess, (state, { event }) => ({
    ...state,
    events: {
      ...state.events,
      [event.id]: event,
    },
    loading: false,
  })),

  on(EventActions.removeEventSuccess, (state, { eventId }) => {
    const entities = {};
    Object.values(state.events)
      .filter(event => event.id !== eventId)
      .forEach(event => (entities[event.id] = event));

    return {
      ...state,
      events: entities,
      loading: false,
    };
  }),

  on(EventActions.getEventInvitationSuccess, EventActions.respondToEventSuccess, (state, { invitation }) =>
    invitation
      ? {
          ...state,
          invitations: {
            ...state.invitations,
            [`${invitation.eventId}_${invitation.eventExecution}`]: invitation,
          },
          loading: false,
        }
      : { ...state, loading: false },
  ),

  on(EventActions.getEventParticipantsSuccess, (state, { data }) => ({
    ...state,
    participants: {
      ...state.participants,
      ...data,
    },
  })),

  on(EventActions.getReferentsSuccess, (state, { referents }) => ({
    ...state,
    referents,
    loading: false,
  })),
);
