import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { EventAvailabilityComponent } from "./event-availability.component";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
  declarations: [EventAvailabilityComponent],
  imports: [CommonModule, TranslateModule],
  exports: [EventAvailabilityComponent],
})
export class EventAvailabilityModule {}
