import { Directive, ElementRef, Renderer2, Input, OnChanges, SimpleChanges } from "@angular/core";

@Directive({ selector: "[appBackgroundImage]" })
export class BackgroundImageDirective implements OnChanges {
  @Input() public appBackgroundImage: string;

  constructor(private renderer: Renderer2, private el: ElementRef) {}

  public ngOnChanges({ appBackgroundImage }: SimpleChanges): void {
    if (appBackgroundImage.previousValue !== appBackgroundImage.currentValue) {
      this.renderer.setStyle(this.el.nativeElement, "backgroundImage", `url('${appBackgroundImage.currentValue}')`);
    }
  }
}
