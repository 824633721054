<ion-app>
  <ion-split-pane contentId="main" [when]="this.menuSidePosition === 'start'">
    <ion-menu [side]="menuSidePosition" type="overlay" contentId="main" class="menu">
      <ion-content>
        <a class="menu__logo" [routerLink]="['/']" (click)="closeMenuAndSetCurrentAsNull()">
          <app-logo class="--light"></app-logo>
        </a>
        <ion-icon class="menu__close" name="close-outline" (click)="closeMenu()"></ion-icon>
        <ion-list class="menu__list">
          <ion-item
            class="menu__list__item"
            routerLinkActive="--active"
            routerDirection="root"
            detail="false"
            [routerLink]="[routes.home]"
            (click)="closeMenuAndSetCurrentAsNull()"
          >
            <div class="menu__list__item__wrapper">
              <div class="menu__list__item__content" (click)="closeMenu()">
                <img src="assets/menu/category.png" alt="Dashboard" />
                <span [innerHTML]="'Dashboard'"></span>
              </div>
            </div>
          </ion-item>

          <ion-item [class.--open]="menuItemOpened === 1" class="menu__list__item" detail="false">
            <div class="menu__list__item__wrapper">
              <div class="menu__list__item__content" (click)="toggleAccordionMenu(1)">
                <img src="assets/menu/shield-done.png" alt="Mein Erfolg" />
                <span [innerHTML]="'Mein Erfolg'"></span>
                <ion-icon name="chevron-down-outline"></ion-icon>
              </div>
              <ion-list class="menu__sublist">
                <ion-item
                  class="menu__sublist__item"
                  routerLinkActive="--active"
                  routerDirection="root"
                  detail="false"
                  [routerLink]="[routes.reports, routes.successReport, routes.history]"
                  [innerHTML]="'SUCCESS_REPORT.BREADCRUMB_NAME' | translate"
                  (click)="closeMenuAndSetCurrentAsNull()"
                ></ion-item>
                <ion-item
                  class="menu__sublist__item"
                  routerLinkActive="--active"
                  routerDirection="root"
                  detail="false"
                  [routerLink]="[routes.reports, routes.bwaReport, routes.history]"
                  [innerHTML]="'BWA_REPORT.BREADCRUMB_NAME' | translate"
                  (click)="closeMenuAndSetCurrentAsNull()"
                ></ion-item>
              </ion-list>
            </div>
          </ion-item>

          <ion-item [class.--open]="menuItemOpened === 2" class="menu__list__item" detail="false">
            <div class="menu__list__item__wrapper">
              <div class="menu__list__item__content" (click)="toggleAccordionMenu(2)">
                <img src="assets/menu/star.png" alt="Community" />
                <span [innerHTML]="'Community'"></span>
                <ion-icon name="chevron-down-outline"></ion-icon>
              </div>
              <ion-list class="menu__sublist">
                <ion-item
                  class="menu__sublist__item"
                  routerLinkActive="--active"
                  routerDirection="root"
                  detail="false"
                  [routerLink]="[routes.events]"
                  [innerHTML]="'Veranstaltungen'"
                  (click)="closeMenuAndSetCurrentAsNull()"
                ></ion-item>
                <ion-item
                  class="menu__sublist__item"
                  routerLinkActive="--active"
                  routerDirection="root"
                  detail="false"
                  [routerLink]="[routes.bookCheckUp]"
                  [innerHTML]="'Check-Up buchen'"
                  (click)="closeMenuAndSetCurrentAsNull()"
                ></ion-item>
                <ion-item
                  class="menu__sublist__item"
                  routerLinkActive="--active"
                  routerDirection="root"
                  detail="false"
                  [routerLink]="[routes.postponeCheckUp]"
                  [innerHTML]="'Check-Up verschieben'"
                  (click)="closeMenuAndSetCurrentAsNull()"
                ></ion-item>
                <ion-item
                  class="menu__sublist__item"
                  detail="false"
                  [innerHTML]="'Facebook- Gruppe'"
                  (click)="openExternalLink('https://www.facebook.com/groups/363715437671639/')"
                ></ion-item>
              </ion-list>
            </div>
          </ion-item>

          <ion-item [class.--open]="menuItemOpened === 7" class="menu__list__item" detail="false">
            <div class="menu__list__item__wrapper">
              <div class="menu__list__item__content" (click)="toggleAccordionMenu(7)">
                <img src="assets/menu/bookmark.png" alt="Meine Webseite" />
                <span [innerHTML]="'Meine Webseite'"></span>
                <ion-icon name="chevron-down-outline"></ion-icon>
              </div>
              <ion-list class="menu__sublist">
                <ion-item
                  class="menu__sublist__item"
                  [class.--active]="currentActive === 'dashboard'"
                  detail="false"
                  [innerHTML]="'Übersicht'"
                  (click)="triggerWebBuilderNavigation($event, 'dashboard')"
                ></ion-item>
                <ion-item
                  class="menu__sublist__item"
                  [class.--active]="currentActive === 'pos-booking'"
                  detail="false"
                  [innerHTML]="'Buchungsanfragen'"
                  (click)="triggerWebBuilderNavigation($event, 'pos-booking')"
                ></ion-item>
                <ion-item
                  class="menu__sublist__item"
                  [class.--active]="currentActive === 'news'"
                  detail="false"
                  [innerHTML]="'Aktuelles'"
                  (click)="triggerWebBuilderNavigation($event, 'news')"
                ></ion-item>
                <ion-item
                  class="menu__sublist__item"
                  [class.--active]="currentActive === 'galleries'"
                  detail="false"
                  [innerHTML]="'Galerien'"
                  (click)="triggerWebBuilderNavigation($event, 'galleries')"
                ></ion-item>
                <ion-item
                  class="menu__sublist__item"
                  [class.--active]="currentActive === 'before-after'"
                  detail="false"
                  [innerHTML]="'Vorher & Nachher Bilder'"
                  (click)="triggerWebBuilderNavigation($event, 'before-after')"
                ></ion-item>
                <ion-item
                  class="menu__sublist__item"
                  [class.--active]="currentActive === 'company-profile'"
                  detail="false"
                  [innerHTML]="'Unternehmensprofil'"
                  (click)="triggerWebBuilderNavigation($event, 'company-profile')"
                ></ion-item>
                <ion-item
                  class="menu__sublist__item"
                  [class.--active]="currentActive === 'employees'"
                  detail="false"
                  [innerHTML]="'Mitarbeiter'"
                  (click)="triggerWebBuilderNavigation($event, 'employees')"
                ></ion-item>
                <ion-item
                  class="menu__sublist__item"
                  [class.--active]="currentActive === 'job-offers'"
                  detail="false"
                  [innerHTML]="'Stellenangebote'"
                  (click)="triggerWebBuilderNavigation($event, 'job-offers')"
                ></ion-item>
                <ion-item
                  class="menu__sublist__item"
                  [class.--active]="currentActive === 'partners'"
                  detail="false"
                  [innerHTML]="'Partner'"
                  (click)="triggerWebBuilderNavigation($event, 'partners')"
                ></ion-item>
                <ion-item
                  class="menu__sublist__item"
                  [class.--active]="currentActive === 'customer-pricelist'"
                  detail="false"
                  [innerHTML]="'Preisliste'"
                  (click)="triggerWebBuilderNavigation($event, 'customer-pricelist')"
                ></ion-item>
                <ion-item
                  class="menu__sublist__item"
                  routerLinkActive="--active"
                  routerDirection="root"
                  detail="false"
                  [routerLink]="[routes.domainAddress]"
                  [innerHTML]="'Deine Internetadresse'"
                  (click)="closeMenuAndSetCurrentAsNull()"
                ></ion-item>
              </ion-list>
            </div>
          </ion-item>

          <ion-item
            *ngIf="(user$ | async)?.role === 'SUPER_ADMIN'"
            [class.--open]="menuItemOpened === 8"
            class="menu__list__item"
            detail="false"
          >
            <div class="menu__list__item__wrapper">
              <div class="menu__list__item__content" (click)="toggleAccordionMenu(8)">
                <img src="assets/menu/settings.png" alt="Admin" />
                <span [innerHTML]="'Admin'"></span>
                <ion-icon name="chevron-down-outline"></ion-icon>
              </div>
              <ion-list class="menu__sublist">
                <ion-item
                  class="menu__sublist__item"
                  [class.--active]="currentActive === 'admin-dashboard'"
                  detail="false"
                  [innerHTML]="'Dashboard'"
                  (click)="triggerWebBuilderNavigation($event, 'admin-dashboard')"
                ></ion-item>
                <ion-item
                  class="menu__sublist__item"
                  detail="false"
                  routerLinkActive="--active"
                  [routerLink]="[routes.eventContents]"
                  [innerHTML]="'Veranstaltungen verwalten'"
                  (click)="closeMenuAndSetCurrentAsNull()"
                ></ion-item>
                <ion-item
                  class="menu__sublist__item"
                  detail="false"
                  routerLinkActive="--active"
                  [routerLink]="[routes.adminModules]"
                  [innerHTML]="'ADMIN_LEARNING_MODULES.BREADCRUMB_NAME' | translate"
                  (click)="closeMenuAndSetCurrentAsNull()"
                ></ion-item>
                <ion-item
                  class="menu__sublist__item"
                  detail="false"
                  routerLinkActive="--active"
                  [routerLink]="[routes.adminSurveyDefinitions]"
                  [innerHTML]="'ADMIN_SURVEYS.BREADCRUMB_NAME' | translate"
                  (click)="closeMenuAndSetCurrentAsNull()"
                ></ion-item>
                <ion-item
                  class="menu__sublist__item"
                  detail="false"
                  routerLinkActive="--active"
                  [routerLink]="[routes.scenarios]"
                  [innerHTML]="'SCENARIOS.SIDEMENU_NAME' | translate"
                  (click)="closeMenuAndSetCurrentAsNull()"
                ></ion-item>
                <ion-item
                  class="menu__sublist__item"
                  [class.--active]="currentActive === 'admin-customers'"
                  detail="false"
                  [innerHTML]="'Kunden verwalten'"
                  (click)="triggerWebBuilderNavigation($event, 'admin-customers')"
                ></ion-item>
                <ion-item
                  class="menu__sublist__item"
                  [class.--active]="currentActive === 'admin-customer-websites'"
                  detail="false"
                  [innerHTML]="'Webseiten'"
                  (click)="triggerWebBuilderNavigation($event, 'admin-customer-websites')"
                ></ion-item>
                <ion-item
                  class="menu__sublist__item"
                  [class.--active]="currentActive === 'admin-before-after'"
                  detail="false"
                  [innerHTML]="'Vorhen & Nachher Bilder (global)'"
                  (click)="triggerWebBuilderNavigation($event, 'admin-before-after')"
                ></ion-item>
                <ion-item
                  class="menu__sublist__item"
                  [class.--active]="currentActive === 'admin-news'"
                  detail="false"
                  [innerHTML]="'Aktuelles (global)'"
                  (click)="triggerWebBuilderNavigation($event, 'admin-news')"
                ></ion-item>
                <ion-item
                  class="menu__sublist__item"
                  [class.--active]="currentActive === 'admin-services'"
                  detail="false"
                  [innerHTML]="'Services verwalten'"
                  (click)="triggerWebBuilderNavigation($event, 'admin-services')"
                ></ion-item>
                <ion-item
                  class="menu__sublist__item"
                  [class.--active]="currentActive === 'admin-services-dimensions'"
                  detail="false"
                  [innerHTML]="'Serviceabmessungen'"
                  (click)="triggerWebBuilderNavigation($event, 'admin-services-dimensions')"
                ></ion-item>
                <ion-item
                  class="menu__sublist__item"
                  [class.--active]="currentActive === 'admin-service-exclusions'"
                  detail="false"
                  [innerHTML]="'Service exclusions'"
                  (click)="triggerWebBuilderNavigation($event, 'admin-service-exclusions')"
                ></ion-item>
                <ion-item
                  class="menu__sublist__item"
                  [class.--active]="currentActive === 'admin-service-combinations'"
                  detail="false"
                  [innerHTML]="'Service Kombinationen'"
                  (click)="triggerWebBuilderNavigation($event, 'admin-service-combinations')"
                ></ion-item>
                <ion-item
                  class="menu__sublist__item"
                  [class.--active]="currentActive === 'employee-qualification-levels'"
                  detail="false"
                  [innerHTML]="'Mitarbeiter Qualifikationen'"
                  (click)="triggerWebBuilderNavigation($event, 'employee-qualification-levels')"
                ></ion-item>
                <ion-item
                  class="menu__sublist__item"
                  [class.--active]="currentActive === 'website-builder'"
                  detail="false"
                  [innerHTML]="'Website Builder'"
                  (click)="triggerWebBuilderNavigation($event, 'website-builder')"
                ></ion-item>
                <ion-item
                  class="menu__sublist__item"
                  [class.--active]="currentActive === 'admin-website-builder'"
                  detail="false"
                  [innerHTML]="'Template Builder'"
                  (click)="triggerWebBuilderNavigation($event, 'admin-website-builder')"
                ></ion-item>
                <ion-item
                  class="menu__sublist__item"
                  [class.--active]="currentActive === 'email-templates'"
                  detail="false"
                  [innerHTML]="'Email templates'"
                  (click)="triggerWebBuilderNavigation($event, 'email-templates')"
                ></ion-item>
                <ion-item
                  class="menu__sublist__item"
                  [class.--active]="currentActive === 'application-forms'"
                  detail="false"
                  [innerHTML]="'Application forms'"
                  (click)="triggerWebBuilderNavigation($event, 'application-forms')"
                ></ion-item>
              </ion-list>
            </div>
          </ion-item>

          <ion-item
            *ngIf="!platform.is('capacitor') && !isSafari"
            [class.--open]="menuItemOpened === 9"
            class="menu__list__item"
            detail="false"
          >
            <div class="menu__list__item__wrapper">
              <div class="menu__list__item__content" (click)="toggleAccordionMenu(9)">
                <img src="assets/menu/shield-done.png" alt="wow Kasse" />
                <span [innerHTML]="'wow Kasse'"></span>
                <ion-icon name="chevron-down-outline"></ion-icon>
              </div>
              <ion-list class="menu__sublist">
                <ion-item
                  class="menu__sublist__item"
                  routerLinkActive="--active"
                  routerDirection="root"
                  detail="false"
                  [routerLink]="[routes.helloCash]"
                  [innerHTML]="'wow Kasse'"
                  (click)="closeMenuAndSetCurrentAsNull()"
                ></ion-item>
                <ion-item
                  class="menu__sublist__item"
                  routerLinkActive="--active"
                  routerDirection="root"
                  detail="false"
                  [routerLink]="[routes.registerPOS]"
                  [innerHTML]="'Kasse registrieren'"
                  (click)="closeMenuAndSetCurrentAsNull()"
                ></ion-item>
              </ion-list>
            </div>
          </ion-item>

          <ion-item
            class="menu__list__item"
            routerLinkActive="--active"
            routerDirection="root"
            detail="false"
            [routerLink]="[routes.kajabi]"
          >
            <div class="menu__list__item__wrapper">
              <div class="menu__list__item__content" (click)="closeMenuAndSetCurrentAsNull()">
                <img src="assets/menu/kajabi.png" alt="Lernmodule" />
                <span [innerHTML]="'Lernmodule'"></span>
              </div>
            </div>
          </ion-item>

          <ion-item class="menu__list__item" detail="false" (click)="openExternalLink('https://shop.wowfamily.de')">
            <div class="menu__list__item__wrapper">
              <div class="menu__list__item__content">
                <img src="assets/menu/shop.png" alt="Shop" />
                <span [innerHTML]="'Shop'"></span>
              </div>
            </div>
          </ion-item>

          <ion-item [class.--open]="menuItemOpened === 10" class="menu__list__item" detail="false">
            <div class="menu__list__item__wrapper">
              <div class="menu__list__item__content" (click)="toggleAccordionMenu(10)">
                <img src="assets/menu/settings.png" [alt]="'SETTINGS.SIDEMENU_NAME' | translate" />
                <span [innerHTML]="'SETTINGS.SIDEMENU_NAME' | translate"></span>
                <ion-icon name="chevron-down-outline"></ion-icon>
              </div>
              <ion-list class="menu__sublist">
                <ion-item
                  class="menu__sublist__item"
                  routerLinkActive="--active"
                  routerDirection="root"
                  detail="false"
                  [routerLink]="[routes.integrations]"
                  [innerHTML]="'INTEGRATIONS.BREADCRUMB_NAME' | translate"
                  (click)="closeMenuAndSetCurrentAsNull()"
                ></ion-item>
              </ion-list>
            </div>
          </ion-item>

          <ion-item [class.--open]="menuItemOpened === 5" class="menu__list__item" detail="false">
            <div class="menu__list__item__wrapper">
              <div class="menu__list__item__content" (click)="toggleAccordionMenu(5)">
                <img src="assets/menu/info-circle.png" alt="Hilfe&Kontakt" />
                <span [innerHTML]="'Hilfe&Kontakt'"></span>
                <ion-icon name="chevron-down-outline"></ion-icon>
              </div>
              <ion-list class="menu__sublist">
                <ion-item
                  class="menu__sublist__item"
                  routerLinkActive="--active"
                  routerDirection="root"
                  detail="false"
                  [routerLink]="[routes.helpCenter]"
                  [innerHTML]="'Häufige Fragen'"
                  (click)="closeMenuAndSetCurrentAsNull()"
                ></ion-item>
                <ion-item
                  class="menu__sublist__item"
                  routerLinkActive="--active"
                  routerDirection="root"
                  detail="false"
                  [routerLink]="[routes.askQuestion]"
                  [innerHTML]="'Frage stellen'"
                  (click)="closeMenuAndSetCurrentAsNull()"
                ></ion-item>
              </ion-list>
            </div>
          </ion-item>

          <ion-item class="menu__list__item" detail="false">
            <div class="menu__list__item__wrapper">
              <div class="menu__list__item__content" (click)="logout()">
                <img src="assets/menu/logout.png" alt="Logout" />
                <span [innerHTML]="'Logout'"></span>
              </div>
            </div>
          </ion-item>
        </ion-list>
      </ion-content>
    </ion-menu>
    <ion-router-outlet id="main"></ion-router-outlet>
  </ion-split-pane>
  <app-offline-indicator></app-offline-indicator>
</ion-app>
