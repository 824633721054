import { ReportDataDefinitionDto } from "../services/api/model/report-data-definition-dto";
import { ReportDto } from "../services/api/model/report-dto";

export const sortByDataDefinition = (a: ReportDto, b: ReportDto, dataDefinitions: ReportDataDefinitionDto[]): number => {
  if (a.dataDefinitionId && !b.dataDefinitionId) return -1;
  if (b.dataDefinitionId && !a.dataDefinitionId) return 1;

  const dataDefinitionA = dataDefinitions.find(dd => dd.id === a.dataDefinitionId);
  const dataDefinitionB = dataDefinitions.find(dd => dd.id === b.dataDefinitionId);

  return dataDefinitionA.order - dataDefinitionB.order;
};
