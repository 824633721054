import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IonicModule } from "@ionic/angular";
import { IframeModal } from "./iframe-modal.page";

@NgModule({
  imports: [CommonModule, IonicModule],
  declarations: [IframeModal],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class IframeModalModule {}
