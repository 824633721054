import * as SurveyActions from "src/app/store/survey/actions";
import * as EventActions from "src/app/store/event/actions";
import * as FromSurvey from "src/app/store/survey/selectors";
import * as FromUser from "src/app/store/user/selectors";
import * as FromEvent from "src/app/store/event/selectors";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ModalController, Platform, ToastController } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { RootState } from "src/app/store";
import { SwipperReportPage } from "../swipper-report/swipper-report.page";
import { first, skipWhile, withLatestFrom } from "rxjs/operators";
import { Observable } from "rxjs";
import { CreateSurveyResponseDto, EventDto, SurveyResponseDto } from "@api";
import { StoreResponseParams } from "../swipper-report/store-response-params.interface";
import { validateSurveyResponseValue } from "src/app/utils/validate-survey-response";
import { HandleChangeParams } from "src/app/components/survey-field/interfaces/handle-change-params.interface";
import { LocalSurveyErrors } from "src/app/store/survey/state";
import { ReportsWithQuestionPipe } from "src/app/pipes/reports-with-question/reports-with-question.pipe";

@Component({
  selector: "app-swipper-survey",
  templateUrl: "./swipper-survey.page.html",
  styleUrls: ["../swipper-report/swipper-report.page.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SwipperSurveyPage extends SwipperReportPage implements OnInit, OnDestroy {
  @Input() public surveyId: string;

  public loading$: Observable<boolean> = this.store.select(FromSurvey.selectLoading);
  public errors$: Observable<LocalSurveyErrors> = this.store.select(FromSurvey.selectSurveyErrors);
  public responses$: Observable<SurveyResponseDto[]>;
  public event$: Observable<EventDto>;

  constructor(
    public readonly store: Store<RootState>,
    public readonly platform: Platform,
    public readonly i18n: TranslateService,
    public readonly modalController: ModalController,
    public readonly toastController: ToastController,
    public readonly changeDetector: ChangeDetectorRef,
    public readonly reportsWithQuestionPipe: ReportsWithQuestionPipe,
  ) {
    super(store, platform, i18n, modalController, toastController, reportsWithQuestionPipe);
  }

  public ngOnInit(): void {
    this.date = new Date();
    this.survey$ = this.store.select(FromSurvey.selectSurveyById(this.surveyId));
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  public nextSlide(): void {
    this.fields$.pipe(withLatestFrom(this.responses$), first()).subscribe(([fields, responses]) => {
      const field = fields[this.currentIndex];
      const value = responses.find(surveyResponse => surveyResponse.surveyFieldId === field.id)?.value?.toString();
      this.storeResponse({ field, month: this.date.getMonth() + 1, year: this.date.getFullYear(), value, goNext: true });
    });
  }

  public handleFieldResponse(response: HandleChangeParams): void {
    if (!response.value) return;
    this.storeResponse({ field: response.field, value: response.value });
  }

  public storeResponse({ field, value, goNext, isLast }: StoreResponseParams): void {
    this.store
      .select(FromUser.selectUser)
      .pipe(withLatestFrom(this.definition$), withLatestFrom(this.survey$), first())
      .subscribe(([[user, definition], survey]) => {
        if (!user.storeUsers) {
          return super.showNoStoresErrorToast();
        }

        const surveyResponse: CreateSurveyResponseDto = { value, date: this.date.toISOString() };
        const { error, params } = validateSurveyResponseValue(field, value);

        if (error) {
          this.store.dispatch(SurveyActions.storeSurveyError({ fieldId: field.id, error, params }));
        } else {
          this.store.dispatch(SurveyActions.cleanSurveyError({ fieldId: field.id }));
          if (value) {
            this.store.dispatch(
              SurveyActions.createSurveyResponse({
                createSurveyResponseDto: surveyResponse,
                surveyId: this.surveyId,
                surveyFieldId: field.id,
              }),
            );
          }
          if (isLast) this.store.dispatch(SurveyActions.sendSurvey({ surveySlug: definition.slug, surveyId: survey.id }));
          if (goNext) this.swiper.slideNext();
        }
      });
  }

  public send(): void {
    this.fields$.pipe(withLatestFrom(this.responses$), first()).subscribe(([fields, responses]) => {
      const field = fields[this.currentIndex];
      const value = responses.find(response => response.surveyFieldId === field.id)?.value?.toString();
      this.storeResponse({ field, month: this.date.getMonth() + 1, year: this.date.getFullYear(), value, isLast: true });
    });
  }

  protected ionViewDidEnter(): void {
    this.store.dispatch(SurveyActions.getSurveyById({ surveyId: this.surveyId }));
    this.store.dispatch(SurveyActions.getSurveyResponses({ surveyId: this.surveyId }));
    this.survey$
      .pipe(
        skipWhile(survey => !survey),
        first(),
      )
      .subscribe(survey => {
        this.definition$ = this.store.select(FromSurvey.selectDefinitionById(survey.definitionId));
        this.fields$ = this.store.select(FromSurvey.selectDefinitionFieldsById(survey.definitionId));
        this.responses$ = this.store.select(FromSurvey.selectSurveyResponsesBySurveyId(this.surveyId));

        if (survey.eventId) {
          this.store.dispatch(EventActions.getEvent({ eventId: survey.eventId, fetchNested: true }));
          this.event$ = this.store.select(FromEvent.selectEventById(survey.eventId));
        }

        this.store.dispatch(SurveyActions.getDefinitionById({ definitionId: survey.definitionId }));
        this.changeDetector.detectChanges();
      });
  }
}
