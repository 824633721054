/**
 * Wow family api
 * The Wow family API description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PublicUser } from './public-user';


export interface EventUserDto { 
    createdAt: string;
    updatedAt: string;
    id: string;
    /**
     * Timestamp referring to the execution of the event we are subscribing to (always UTC time)
     */
    eventExecution: number;
    eventId: string;
    userId: string;
    status: EventUserDto.StatusEnum;
    offPlatformAttendants: Array<string>;
    user?: PublicUser;
}
export namespace EventUserDto {
    export type StatusEnum = 'DECLINED' | 'CONFIRMED' | 'MAYBE' | 'NO_ANSWER';
    export const StatusEnum = {
        Declined: 'DECLINED' as StatusEnum,
        Confirmed: 'CONFIRMED' as StatusEnum,
        Maybe: 'MAYBE' as StatusEnum,
        NoAnswer: 'NO_ANSWER' as StatusEnum
    };
}


