<div class="survey-field">
  <div class="survey-field__image" [appBackgroundImage]="field.imageUrl || defaultImage"></div>

  <div class="survey-field__question">
    <div class="survey-field__question__title" [innerHTML]="field.question"></div>
    <div class="survey-field__question__description" [innerHTML]="field.description"></div>
    <a class="survey-field__question__help" tabindex="-1" (click)="handleHelp.emit(field.helpUrl)">
      <span [innerHTML]="field.helpText"></span>
    </a>
    <div class="survey-field__question__input --is-{{ field.type | lowercase }}" [ngSwitch]="field.type">
      <!-- Boolean -->
      <ion-toggle
        *ngSwitchCase="fieldTypes.Boolean"
        [tabindex]="index"
        [checked]="value === 'true'"
        (ionChange)="handleChange.emit({ field: field, value: $any($event).target.checked ? 'true' : 'false' })"
      ></ion-toggle>

      <!-- Date -->
      <ion-datetime
        *ngSwitchCase="fieldTypes.Date"
        [tabindex]="index"
        presentation="date"
        locale="de-DE"
        [cancelText]="'BUTTONS.CANCEL' | translate"
        [doneText]="'BUTTONS.OK' | translate"
        (ionChange)="handleChangeDateInput($any($event))"
      ></ion-datetime>

      <!-- Datetime -->
      <ion-datetime
        *ngSwitchCase="fieldTypes.Datetime"
        [tabindex]="index"
        presentation="date-time"
        locale="de-DE"
        [cancelText]="'BUTTONS.CANCEL' | translate"
        [doneText]="'BUTTONS.OK' | translate"
        (ionChange)="handleChangeDatetimeInput($any($event))"
      ></ion-datetime>

      <!-- Time -->
      <ion-datetime
        *ngSwitchCase="fieldTypes.Time"
        [tabindex]="index"
        presentation="time"
        locale="de-DE"
        [cancelText]="'BUTTONS.CANCEL' | translate"
        [doneText]="'BUTTONS.OK' | translate"
        (ionChange)="handleChangeTimeInput($any($event))"
      ></ion-datetime>

      <!-- Email -->
      <ion-textarea
        *ngSwitchCase="fieldTypes.Email"
        [tabindex]="index"
        inputmode="email"
        [autoGrow]="false"
        [autocapitalize]="false"
        [placeholder]="'SURVEYS.TEXT_PLACEHOLDER' | translate"
        [rows]="1"
        [value]="value"
        (ionChange)="handleChange.emit({ field: field, value: $any($event).detail.value })"
      ></ion-textarea>

      <!-- JSON -->
      <ion-textarea
        *ngSwitchCase="fieldTypes.Json"
        [tabindex]="index"
        [autoGrow]="true"
        [autocapitalize]="false"
        [placeholder]="'SURVEYS.JSON_PLACEHOLDER' | translate"
        [rows]="5"
        [value]="value"
        (ionChange)="handleChange.emit({ field: field, value: $any($event).detail.value })"
      ></ion-textarea>

      <!-- Number -->
      <ion-item *ngSwitchCase="fieldTypes.Number">
        <ion-input
          type="number"
          inputmode="numeric"
          [placeholder]="'SURVEYS.NUMBER_PLACEHOLDER' | translate"
          [tabindex]="index"
          [min]="field.min"
          [max]="field.max"
          [value]="value"
          [debounce]="800"
          (ionChange)="handleChange.emit({ field: field, value: $any($event).detail.value })"
        >
          <ion-button *ngIf="symbol === '€'" tabindex="-1"><ion-icon name="logo-euro"></ion-icon></ion-button>
        </ion-input>
      </ion-item>

      <!-- Range -->
      <div [tabindex]="index" *ngSwitchCase="fieldTypes.Range">
        <ion-range
          [min]="field.min"
          [max]="field.max"
          [pin]="true"
          [debounce]="800"
          [value]="value"
          [pinFormatter]="formatSliderPin.bind(this)"
          (ionChange)="handleChange.emit({ field: field, value: $any($event).detail.value })"
        ></ion-range>
      </div>

      <!-- Rating -->
      <div *ngSwitchCase="fieldTypes.Rating" [tabindex]="index">
        <ion-icon src="assets/surveys/star.svg" (click)="handleChange.emit({ field: field, value: '1' })"></ion-icon>
        <ion-icon
          [src]="['2', '3', '4', '5'].includes(value) ? 'assets/surveys/star.svg' : 'assets/surveys/star-outline.svg'"
          (click)="handleChange.emit({ field: field, value: '2' })"
        ></ion-icon>
        <ion-icon
          [src]="['3', '4', '5'].includes(value) ? 'assets/surveys/star.svg' : 'assets/surveys/star-outline.svg'"
          (click)="handleChange.emit({ field: field, value: '3' })"
        ></ion-icon>
        <ion-icon
          [src]="['4', '5'].includes(value) ? 'assets/surveys/star.svg' : 'assets/surveys/star-outline.svg'"
          (click)="handleChange.emit({ field: field, value: '4' })"
        ></ion-icon>
        <ion-icon
          [src]="['5'].includes(value) ? 'assets/surveys/star.svg' : 'assets/surveys/star-outline.svg'"
          (click)="handleChange.emit({ field: field, value: '5' })"
        ></ion-icon>
      </div>

      <!-- Select -->
      <ion-list *ngSwitchCase="fieldTypes.Select" [tabindex]="index">
        <ion-radio-group [value]="value" (ionChange)="handleChange.emit({ field: field, value: $any($event).detail.value })">
          <ion-item *ngFor="let option of field.options" lines="none">
            <ion-radio [value]="option"></ion-radio>
            <ion-label [innerHTML]="option"></ion-label>
          </ion-item>
        </ion-radio-group>
      </ion-list>

      <!-- Multiselect -->
      <ion-list *ngSwitchCase="fieldTypes.Multiselect" [tabindex]="index">
        <ion-radio-group *ngFor="let option of field.options" [allowEmptySelection]="true">
          <ion-item lines="none" (click)="handleChangeMultiselect(option)">
            <ion-radio [value]="option"></ion-radio>
            <ion-label [innerHTML]="option"></ion-label>
          </ion-item>
        </ion-radio-group>
      </ion-list>

      <!-- Tel -->
      <ion-textarea
        *ngSwitchCase="fieldTypes.Tel"
        inputmode="tel"
        [tabindex]="index"
        [autoGrow]="false"
        [autocapitalize]="false"
        [placeholder]="'SURVEYS.TEXT_PLACEHOLDER' | translate"
        [rows]="1"
        [value]="value"
        (ionChange)="handleChange.emit({ field: field, value: $any($event).detail.value })"
      ></ion-textarea>

      <!-- Text -->
      <ion-textarea
        *ngSwitchCase="fieldTypes.Text"
        inputmode="text"
        [tabindex]="index"
        [autoGrow]="false"
        [autocapitalize]="false"
        [placeholder]="'SURVEYS.TEXT_PLACEHOLDER' | translate"
        [rows]="1"
        [value]="value"
        (ionChange)="handleChange.emit({ field: field, value: $any($event).detail.value })"
      ></ion-textarea>

      <!-- Textarea -->
      <ion-textarea
        *ngSwitchCase="fieldTypes.Textarea"
        inputmode="text"
        [tabindex]="index"
        [autocapitalize]="false"
        [placeholder]="'SURVEYS.TEXT_PLACEHOLDER' | translate"
        [debounce]="500"
        [rows]="5"
        [value]="value"
        (ionChange)="handleChange.emit({ field: field, value: $any($event).detail.value })"
      ></ion-textarea>

      <!-- Url -->
      <ion-textarea
        *ngSwitchCase="fieldTypes.Url"
        inputmode="url"
        [tabindex]="index"
        [autoGrow]="false"
        [autocapitalize]="false"
        [placeholder]="'SURVEYS.TEXT_PLACEHOLDER' | translate"
        [rows]="1"
        [value]="value"
        (ionChange)="handleChange.emit({ field: field, value: $any($event).detail.value })"
      ></ion-textarea>
    </div>

    <ion-note
      class="survey-field__question__error"
      slot="error"
      color="danger"
      [innerHTML]="error?.error | translate: error?.params"
    ></ion-note>
  </div>
</div>
