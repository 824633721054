import { createReducer, on } from "@ngrx/store";
import { initialState } from "./state";
import * as UserActions from "./actions";
import * as LearningModuleActions from "src/app/store/learning-module/actions";

export const userReducer = createReducer(
  initialState,

  on(UserActions.setAccessToken, (state, { accessToken }) => ({ ...state, accessToken })),

  on(UserActions.setRefreshToken, (state, { refreshToken }) => ({ ...state, refreshToken })),

  on(
    UserActions.register,
    UserActions.confirmRegistration,
    UserActions.login,
    UserActions.requestResetPassword,
    UserActions.resetPassword,
    UserActions.loadUser,
    UserActions.loadCustomer,
    UserActions.markPopoverUIAsSeen,
    UserActions.getUserModules,
    UserActions.getUserChallenges,
    UserActions.getCompletedSteps,
    UserActions.logout,
    state => ({ ...state, loading: true }),
  ),

  on(
    UserActions.checkEmailSuccess,
    UserActions.checkEmailFailure,
    UserActions.registerSuccess,
    UserActions.registerFailure,
    UserActions.confirmRegistrationSuccess,
    UserActions.confirmRegistrationFailure,
    UserActions.loginSuccess,
    UserActions.loginFailure,
    UserActions.requestResetPasswordSuccess,
    UserActions.requestResetPasswordFailure,
    UserActions.resetPasswordSuccess,
    UserActions.resetPasswordFailure,
    UserActions.loadUserFailure,
    UserActions.loadCustomerFailure,
    UserActions.getActionButtonFailure,
    UserActions.getPopoverUIFailure,
    UserActions.markPopoverUIAsSeenFailure,
    UserActions.getUserModulesFailure,
    UserActions.getUserChallengesFailure,
    UserActions.getCompletedStepsFailure,
    state => ({ ...state, loading: false }),
  ),

  on(UserActions.loadUserSuccess, (state, { user }) => ({ ...state, user, loading: false })),

  on(UserActions.loadCustomerSuccess, (state, { customer }) => ({ ...state, customer, loading: false })),

  on(UserActions.getActionButtonSuccess, (state, { actionButton }) => ({ ...state, actionButton })),

  on(UserActions.getPopoverUISuccess, (state, { popoverUI }) => ({ ...state, popoverUI, loading: false })),

  on(UserActions.markPopoverUIAsSeenSuccess, state => ({ ...state, popoverUI: null, loading: false })),

  on(UserActions.getUserModulesSuccess, (state, { moduleIds }) => ({
    ...state,
    enabledModules: moduleIds,
    loading: false,
  })),

  on(UserActions.getUserChallengesSuccess, (state, { challengeIds }) => ({
    ...state,
    enabledChallenges: challengeIds,
    loading: false,
  })),

  on(UserActions.getCompletedStepsSuccess, (state, { chapterStepIds }) => ({
    ...state,
    completedChapterSteps: chapterStepIds,
    loading: false,
  })),

  on(LearningModuleActions.markStepAsCompletedSuccess, (state, { stepId }) => ({
    ...state,
    completedChapterSteps: [...new Set([...state.completedChapterSteps, stepId])],
  })),

  on(UserActions.logoutSuccess, UserActions.resetSuccess, () => initialState),
);
