import { UserDto, ActionButtonDto, PopoverUIDto } from "@api";

export interface UserState {
  accessToken?: string;
  actionButton?: ActionButtonDto;
  completedChapterSteps: string[];
  customer?: any;
  enabledModules: string[];
  enabledChallenges: string[];
  loading: boolean;
  popoverUI?: PopoverUIDto;
  refreshToken?: string;
  user?: UserDto;
}

export const initialState: UserState = {
  accessToken: null,
  actionButton: null,
  completedChapterSteps: [],
  customer: null,
  enabledModules: [],
  enabledChallenges: [],
  loading: false,
  popoverUI: null,
  refreshToken: null,
  user: null,
};

export const persistedKeys: string[] = [
  "user",
  "customer",
  "actionButton",
  "enabledModules",
  "enabledChallenges",
  "accessToken",
  "refreshToken",
];
