import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { ActionButtonPage } from "./action-button.page";
import { BackgroundImageDirectiveModule } from "src/app/directives/background-image/background-image.module";
import { TranslateModule } from "@ngx-translate/core";
import { ChallengeLayoutModule } from "src/app/components/challenge-layout/challenge-layout.module";

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule, BackgroundImageDirectiveModule, TranslateModule, ChallengeLayoutModule],
  declarations: [ActionButtonPage],
})
export class ActionButtonPageModule {}
