import {
  IntegrationControllerCreateIntegrationRequestParams,
  IntegrationControllerSuggestIntegrationRequestParams,
  IntegrationDto,
} from "@api";
import { createAction, props } from "@ngrx/store";

export const getIntegrations = createAction("[INTEGRATION] Get Integrations");
export const getIntegrationsSuccess = createAction("[INTEGRATION] Get Integrations Success", props<{ integrations: IntegrationDto[] }>());
export const getIntegrationsFailure = createAction("[INTEGRATION] Get Integrations Failure", props<{ reason: string }>());

export const sendSuggestion = createAction("[INTEGRATION] Send Suggestion", props<IntegrationControllerSuggestIntegrationRequestParams>());
export const sendSuggestionSuccess = createAction("[INTEGRATION] Send Suggestion Success");
export const sendSuggestionFailure = createAction("[INTEGRATION] Send Suggestion Failure", props<{ reason: string }>());

export const addIntegration = createAction("[INTEGRATION] Add Integration", props<IntegrationControllerCreateIntegrationRequestParams>());
export const addIntegrationSuccess = createAction("[INTEGRATION] Add Integration Success", props<{ integration: IntegrationDto }>());
export const addIntegrationFailure = createAction("[INTEGRATION] Add Integration Failure", props<{ reason: string }>());
