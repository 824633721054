import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NewsService } from "@api";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { catchError, map, mergeMap, tap, withLatestFrom } from "rxjs/operators";
import { RootState } from "..";
import * as NewsActions from "./actions";
import * as FromUser from "src/app/store/user/selectors";

@Injectable()
export class NewsEffects {
  public getNews$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(NewsActions.getNews),
      mergeMap(({ limit, page, event }) =>
        this.newsService.newsControllerGetNews({ limit, page }).pipe(
          map(response => NewsActions.getNewsSuccess({ ...response, event })),
          catchError((error: HttpErrorResponse) => of(NewsActions.getNewsFailure({ reason: error.error?.message }))),
        ),
      ),
    ),
  );

  public getNewsSuccess$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NewsActions.getNewsSuccess),
        tap(({ event }) => (event?.target as any)?.complete()),
      ),
    { dispatch: false },
  );

  public likeNews$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(NewsActions.likeNews),
      withLatestFrom(this.store.select(FromUser.selectUserId)),
      mergeMap(([{ newsId }, userId]) =>
        this.newsService.newsControllerLikeNews({ newsId }).pipe(
          map(() => NewsActions.likeNewsSuccess({ newsId, userId })),
          catchError((error: HttpErrorResponse) => of(NewsActions.likeNewsFailure({ reason: error.error?.message }))),
        ),
      ),
    ),
  );

  public unlikeNews$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(NewsActions.unlikeNews),
      withLatestFrom(this.store.select(FromUser.selectUserId)),
      mergeMap(([{ newsId }, userId]) =>
        this.newsService.newsControllerDislikeNews({ newsId }).pipe(
          map(() => NewsActions.unlikeNewsSuccess({ newsId, userId })),
          catchError((error: HttpErrorResponse) => of(NewsActions.unlikeNewsFailure({ reason: error.error?.message }))),
        ),
      ),
    ),
  );

  constructor(private actions$: Actions, private store: Store<RootState>, private newsService: NewsService) {}
}
