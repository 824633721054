import { Injectable } from "@angular/core";
import { ModalController, NavController } from "@ionic/angular";
import { Browser } from "@capacitor/browser";
import { Store } from "@ngrx/store";
import { RootState } from "src/app/store";
import { AppRoutes } from "src/app/constants";
import * as EventActions from "src/app/store/event/actions";
import * as FromEvent from "src/app/store/event/selectors";
import * as qs from "querystring";
import { first, skipWhile } from "rxjs/operators";
import { EventPage } from "src/app/modals/event/event.page";
import { SwipperReportPage } from "src/app/modals/swipper-report/swipper-report.page";
import { SwipperSurveyPage } from "src/app/modals/swipper-survey/swipper-survey.page";

@Injectable({ providedIn: "root" })
export class NavigationService {
  private locked: boolean = false;

  constructor(private store: Store<RootState>, private navController: NavController, private modalController: ModalController) {}

  public async handleNavigationTo(link: string, presentingElement?: HTMLElement): Promise<void> {
    if (link.startsWith("http")) {
      return this.openInAppBrowser(link);
    } else if (link.startsWith(`/${AppRoutes.events}`)) {
      this.openEventPageModal(link, presentingElement);
    } else if (link.startsWith(`/${AppRoutes.successReport}`) || link.startsWith(`/${AppRoutes.bwaReport}`)) {
      this.openReportPageModal(link, presentingElement);
    } else if (link.startsWith(`/${AppRoutes.surveys}`)) {
      this.openSurveyPageModal(link, presentingElement);
    } else if (link) {
      this.internalNavigation(link);
    }
  }

  private openInAppBrowser(link: string): Promise<void> {
    return Browser.open({ url: link });
  }

  private internalNavigation(link: string): void {
    this.navController.navigateForward(link.split("/"));
  }

  private async openEventPageModal(link: string, presentingElement: HTMLElement): Promise<void> {
    const eventId = link.split("/").pop();
    this.store.dispatch(EventActions.getEvent({ eventId }));
    this.store.dispatch(EventActions.getReferents());

    this.store
      .select(FromEvent.selectEventById(eventId))
      .pipe(
        skipWhile(event => !event),
        first(),
      )
      .subscribe(async event => {
        this.store.dispatch(EventActions.getEventContent({ contentId: event.contentId }));
        const modal = await this.modalController.create({
          component: EventPage,
          componentProps: { event },
          presentingElement,
          cssClass: ["event-detail-modal"],
          swipeToClose: true,
        });

        await modal.present();
      });
  }

  private async openReportPageModal(link: string, presentingElement: HTMLElement): Promise<void> {
    const [path, query] = link.split("?");
    const params = qs.parse(query);

    let slug = path.split("/").pop();
    if (slug.endsWith("s")) slug = slug.slice(0, -1);

    const modal = await this.modalController.create({
      component: SwipperReportPage,
      componentProps: { slug, ...params },
      presentingElement,
      cssClass: ["swiper-survey-modal"],
      swipeToClose: true,
    });

    await modal.present();
    await modal.onDidDismiss();
  }

  private async openSurveyPageModal(link: string, presentingElement: HTMLElement): Promise<void> {
    if (this.locked) return;
    this.locked = true;

    const surveyId = link.split("/").pop();
    const exists = await this.modalController.getTop();
    if (exists) return;

    const modal = await this.modalController.create({
      component: SwipperSurveyPage,
      componentProps: { surveyId },
      presentingElement,
      cssClass: ["swiper-survey-modal", "automatic-survey"],
      swipeToClose: true,
    });

    await modal.present();
    await modal.onDidDismiss();
    this.locked = false;
  }
}
