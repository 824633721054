import { createReducer, on } from "@ngrx/store";
import { initialState } from "./state";
import * as AppActions from "./actions";

export const appReducer = createReducer(
  initialState,

  on(AppActions.showAndroidInstallPrompt, state => state),

  on(AppActions.setAndroidInstallPromptAsShowed, state => ({ ...state, shouldShowAndroidBeforeInstallPrompt: false })),

  on(AppActions.setDeviceToken, (state, { token }) => ({ ...state, deviceToken: token })),

  on(AppActions.getLatestVersionDismissed, (state, { version }) => ({ ...state, dismissedUpdateVersion: version })),

  on(AppActions.updateNetworkConnection, (state, { online }) => ({ ...state, online })),
);
