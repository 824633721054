import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { PopoverUiPage } from "./popover-ui.page";
import { BackgroundImageDirectiveModule } from "src/app/directives/background-image/background-image.module";

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule, BackgroundImageDirectiveModule],
  declarations: [PopoverUiPage],
})
export class PopoverUiPageModule {}
