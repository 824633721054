import { Version, VersionControllerGetLatestRequestParams } from "@api";
import { createAction, props } from "@ngrx/store";

export const showAndroidInstallPrompt = createAction("@wow/app/show-android-install-banner", props<{ event: any }>());
export const setAndroidInstallPromptAsShowed = createAction("@wow/app/set-android-install-banner-as-showed");
export const setDeviceToken = createAction("@wow/app/set-device-token", props<{ token: string }>());

export const getLatestVersion = createAction("@wow/app/get-latest-version", props<VersionControllerGetLatestRequestParams>());
export const getLatestVersionSuccess = createAction("@wow/app/get-latest-version-success", props<{ version: Version }>());
export const getLatestVersionFailure = createAction("@wow/app/get-latest-version-failure", props<{ reason: string }>());
export const getLatestVersionAccepted = createAction("@wow/app/get-latest-version-accepted", props<{ version: Version }>());
export const getLatestVersionDismissed = createAction("@wow/app/get-latest-version-dismissed", props<{ version: Version }>());

export const updateNetworkConnection = createAction("@wow/app/update-network-connection", props<{ online: boolean }>());
