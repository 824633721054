import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { EventResponsePipe } from "./event-response.pipe";

@NgModule({
  imports: [CommonModule],
  declarations: [EventResponsePipe],
  exports: [EventResponsePipe],
})
export class EventResponseModule {}
