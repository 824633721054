import { createReducer, on } from "@ngrx/store";
import { initialState } from "./state";
import * as IntegrationActions from "./actions";

export const integrationReducer = createReducer(
  initialState,

  on(IntegrationActions.getIntegrations, IntegrationActions.sendSuggestion, IntegrationActions.addIntegration, state => ({
    ...state,
    loading: true,
  })),

  on(IntegrationActions.getIntegrationsSuccess, (state, { integrations }) => {
    const entities = {};
    integrations.forEach(integration => (entities[integration.id] = integration));

    return {
      ...state,
      integrations: {
        ...state.integrations,
        ...entities,
      },
      loading: false,
    };
  }),

  on(IntegrationActions.addIntegrationSuccess, (state, { integration }) => ({
    ...state,
    integrations: {
      ...state.integrations,
      [integration.id]: integration,
    },
    loading: false,
  })),

  on(
    IntegrationActions.getIntegrationsFailure,
    IntegrationActions.sendSuggestionSuccess,
    IntegrationActions.sendSuggestionFailure,
    IntegrationActions.addIntegrationFailure,
    state => ({ ...state, loading: false }),
  ),
);
