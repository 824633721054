import { EventContentDto, EventDto, EventUserDto, ReferentDto } from "@api";
import { createSelector, DefaultProjectorFn, MemoizedSelector } from "@ngrx/store";
import { sortByCreatedAt } from "src/app/utils/sort-by-date";
import { sortByName } from "src/app/utils/sort-by-name";
import { sortByNextExecution } from "src/app/utils/sort-by-next-execution";
import { RootState } from "..";
import { selectEventContentId, selectEventId } from "../router/selectors";
import { EventContents, Events, Invitations, Participants } from "./state";

export const selectLoading = (state: RootState): boolean => state.event.loading;

export const selectContents = (state: RootState): EventContents => state.event.eventContents;

export const selectEvents = (state: RootState): Events => state.event.events;

export const selectReferents = (state: RootState): ReferentDto[] => [...state.event.referents].sort(sortByName);

export const selectInvitations = (state: RootState): Invitations => state.event.invitations;

export const selectCurrentPage = (state: RootState): number => state.event.currentPage;

export const selectTotalPages = (state: RootState): number => state.event.pageCount;

export const selectAllContents = createSelector(selectContents, eventContents => Object.values(eventContents).sort(sortByCreatedAt));

export const selectAllEvents = createSelector(selectEvents, events => Object.values(events).sort(sortByNextExecution));

export const selectAllParticipants = (state: RootState): Participants => state.event.participants;

export const selectContentEvents = (contentId: string): MemoizedSelector<RootState, EventDto[], DefaultProjectorFn<EventDto[]>> =>
  createSelector(selectAllEvents, (events: EventDto[]) =>
    events.filter(event => event.contentId === contentId).sort((a, b) => sortByNextExecution(a, b, "asc")),
  );

export const selectContent = createSelector(
  selectEventContentId,
  selectContents,
  (eventContentId, eventContents) => eventContents[eventContentId],
);

export const selectEvent = createSelector(selectEventId, selectEvents, (eventId, events) => events[eventId]);

export const selectEventParticipants = createSelector(selectEventId, selectAllParticipants, (eventId, participants) =>
  participants[eventId]?.sort(sortByCreatedAt),
);

export const selectEventParticipantsByEventId = (
  eventId: string,
): MemoizedSelector<RootState, EventUserDto[], DefaultProjectorFn<EventUserDto[]>> =>
  createSelector(selectAllParticipants, participants =>
    participants[eventId]?.filter(participant => participant.status === EventUserDto.StatusEnum.Confirmed).sort(sortByCreatedAt),
  );

export const selectContentById = (
  eventContentId: string,
): MemoizedSelector<RootState, EventContentDto, DefaultProjectorFn<EventContentDto>> =>
  createSelector(selectContents, eventContents => eventContents[eventContentId]);

export const selectEventById = (eventId: string): MemoizedSelector<RootState, EventDto, DefaultProjectorFn<EventDto>> =>
  createSelector(selectEvents, (events: Events) => events[eventId]);

export const selectReferent = (referentId: string): MemoizedSelector<RootState, ReferentDto, DefaultProjectorFn<ReferentDto>> =>
  createSelector(selectReferents, (referents: ReferentDto[]) => referents.find(referent => referent.id === referentId));

export const selectEventInvitation = (
  eventId: string,
  eventExecution: number,
): MemoizedSelector<RootState, EventUserDto, DefaultProjectorFn<EventUserDto>> =>
  createSelector(selectInvitations, invitations => invitations[`${eventId}_${eventExecution}`]);

export const selectEventAvailablePlaces =
  (eventId: string): ((state: RootState) => number) =>
  (state: RootState): number => {
    const event = state.event.events[eventId];

    if (!event) return null;

    const content = state.event.eventContents[event.contentId];

    if (!content?.participationLimit) return null;

    const participants = state.event.participants[eventId] || [];

    return (
      content.participationLimit -
      participants.reduce((total, participant) => (total += (participant.offPlatformAttendants?.length ?? 0) + 1), 0)
    );
  };
