import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { EventAttendancePage } from "./event-attendance.page";
import { TranslateModule } from "@ngx-translate/core";
import { UsernamePipe } from "src/app/pipes/username/username.pipe";
import { UsernamePipeModule } from "src/app/pipes/username/username.module";
import { RRuleNextExecutionPipe } from "src/app/pipes/rrule-next-execution/rrule-next-execution.pipe";
import { RRuleNextExecutionModule } from "src/app/pipes/rrule-next-execution/rrule-next-execution.module";
import { ToEventContentModule } from "src/app/pipes/to-event-content/to-event-content.module";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    TranslateModule,
    UsernamePipeModule,
    RRuleNextExecutionModule,
    ToEventContentModule,
  ],
  providers: [UsernamePipe, RRuleNextExecutionPipe],
  declarations: [EventAttendancePage],
})
export class EventAttendancePageModule {}
