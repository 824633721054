<ion-header class="header --w-back-button --underline">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button
        *ngIf="event | canShowJoinButton:(event | eventResponse | async)"
        class="header__join-button"
        fill="solid"
        [innerHTML]="'EVENT_DETAIL_COMPONENT.WEBINAR' | translate"
        (click)="joinEvent()"
      ></ion-button>
    </ion-buttons>
    <ion-title class="--logo">
      <span>{{ (event?.contentId | toEventContent | async)?.title }}</span>
    </ion-title>
    <ion-buttons slot="end">
      <ion-icon slot="icon-only" name="close" (click)="dismiss()"></ion-icon>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="event">
  <app-event-detail-component
    [event]="event"
    (joinEvent)="joinEvent()"
    (rescheduleCheckup)="rescheduleCheckUp(event)"
  ></app-event-detail-component>
</ion-content>

<ion-footer class="footer">
  <ion-toolbar>
    <ion-buttons slot="end">
      <app-color-select
        *ngIf="(event?.contentId | toEventContent | async)?.type !== checkUpType; else rescheduleButton"
        [size]="'big'"
        [event]="event"
        [availablePlaces]="event?.id | getAvailablePlaces | async"
        [presentingElement]="modal"
        [capacity]="(event?.contentId | toEventContent | async)?.participationLimit"
      ></app-color-select>
      <ng-template #rescheduleButton>
        <app-reschedule-button [size]="'large'" (clicked)="rescheduleCheckUp(event)"></app-reschedule-button>
      </ng-template>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
