/**
 * Wow family api
 * The Wow family API description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LearningModule } from './learning-module';


export interface EventContentDto { 
    createdAt: string;
    updatedAt: string;
    id: string;
    title: string;
    description: string;
    imageUrl: string;
    priority: EventContentDto.PriorityEnum;
    price: number;
    type: EventContentDto.TypeEnum;
    isPrivate: boolean;
    duration: number;
    participationLimit: number;
    learningModules: Array<LearningModule>;
}
export namespace EventContentDto {
    export type PriorityEnum = 'HIGH' | 'NORMAL' | 'LOW';
    export const PriorityEnum = {
        High: 'HIGH' as PriorityEnum,
        Normal: 'NORMAL' as PriorityEnum,
        Low: 'LOW' as PriorityEnum
    };
    export type TypeEnum = 'CHECK_UP' | 'MEETING' | 'WEBINAR';
    export const TypeEnum = {
        CheckUp: 'CHECK_UP' as TypeEnum,
        Meeting: 'MEETING' as TypeEnum,
        Webinar: 'WEBINAR' as TypeEnum
    };
}


