/**
 * Wow family api
 * The Wow family API description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ChanceNodeLogDto { 
    id: string;
    chanceNodeId: string;
    isManual: boolean;
    runId: number;
    context: string;
    log: string;
    severity: ChanceNodeLogDto.SeverityEnum;
    createdAt: string;
}
export namespace ChanceNodeLogDto {
    export type SeverityEnum = 'ERROR' | 'WARNING' | 'DEBUG';
    export const SeverityEnum = {
        Error: 'ERROR' as SeverityEnum,
        Warning: 'WARNING' as SeverityEnum,
        Debug: 'DEBUG' as SeverityEnum
    };
}


