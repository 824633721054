/**
 * Wow family api
 * The Wow family API description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { StoreUser } from './store-user';
import { SurveyResponse } from './survey-response';
import { Step } from './step';
import { LearningModule } from './learning-module';
import { PopoverUI } from './popover-ui';
import { Challenge } from './challenge';
import { NewsLike } from './news-like';
import { Survey } from './survey';
import { ActionButton } from './action-button';
import { EventUser } from './event-user';
import { News } from './news';
import { Event } from './event';
import { PushToken } from './push-token';


export interface User { 
    createdAt: string;
    updatedAt: string;
    id: string;
    legacyId: number;
    legacyCustomerId: number;
    firstName: string;
    lastName: string;
    avatar: string;
    email: string;
    password: string;
    role: User.RoleEnum;
    subscriptionTBD: Array<string>;
    pushTokens: Array<PushToken>;
    refreshTokens: Array<object>;
    emailVerified: boolean;
    storeUsers: Array<StoreUser>;
    createdEvents: Array<Event>;
    relatedNews: Array<News>;
    likes: Array<NewsLike>;
    invitedEvents: Array<EventUser>;
    actionButton: ActionButton;
    popoverUIs: Array<PopoverUI>;
    surveys: Array<Survey>;
    surveyResponses: Array<SurveyResponse>;
    learningModules: Array<LearningModule>;
    challenges: Array<Challenge>;
    steps: Array<Step>;
}
export namespace User {
    export type RoleEnum = 'SUPER_ADMIN' | 'OWNER' | 'EMPLOYEE';
    export const RoleEnum = {
        SuperAdmin: 'SUPER_ADMIN' as RoleEnum,
        Owner: 'OWNER' as RoleEnum,
        Employee: 'EMPLOYEE' as RoleEnum
    };
}


