import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { IonicModule } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";
import { EventResponseModule } from "src/app/pipes/event-response/event-response.module";
import { RescheduleButtonComponent } from "./reschedule-button.component";

@NgModule({
  imports: [CommonModule, IonicModule, TranslateModule, EventResponseModule],
  declarations: [RescheduleButtonComponent],
  exports: [RescheduleButtonComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class RescheduleButtonComponentModule {}
