import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { EventDto, EventUserDto } from "@api";
import { Store } from "@ngrx/store";
import { Observable, Subscription } from "rxjs";
import { RootState } from "src/app/store";
import * as FromEvent from "src/app/store/event/selectors";

@Component({
  selector: "app-event-participants",
  templateUrl: "./event-participants.component.html",
  styleUrls: ["./event-participants.component.scss"],
})
export class EventParticipantsComponent implements OnInit, OnDestroy {
  @Input() public event: EventDto;
  @Input() public modifier: "--mobile" | "--desktop";

  public participants$: Observable<EventUserDto[]>;
  public total: number = 0;

  private $participants: Subscription;

  constructor(private store: Store<RootState>) {}

  public ngOnInit(): void {
    this.participants$ = this.store.select(FromEvent.selectEventParticipantsByEventId(this.event.id));
    this.$participants = this.participants$.subscribe(participants => {
      this.total = participants?.reduce((total, participant) => (total += participant.offPlatformAttendants.length + 1), 0) || 0;
    });
  }

  public ngOnDestroy(): void {
    this.$participants?.unsubscribe();
  }
}
