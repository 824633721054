import {
  EventContentDto,
  EventContentsDto,
  EventControllerCreateEventContentRequestParams,
  EventControllerCreateEventRequestParams,
  EventControllerDeleteEventContentRequestParams,
  EventControllerDeleteEventRequestParams,
  EventControllerGetContentEventsRequestParams,
  EventControllerGetEventContentRequestParams,
  EventControllerGetEventParticipantsRequestParams,
  EventControllerGetEventRequestParams,
  EventControllerGetManyEventContentsRequestParams,
  EventControllerGetManyEventsRequestParams,
  EventControllerGetUserInvitationsForEventRequestParams,
  EventControllerRespondToInvitationRequestParams,
  EventControllerUpdateEventContentRequestParams,
  EventControllerUpdateEventRequestParams,
  EventDto,
  EventParticipantsResponseDto,
  EventsDto,
  EventUserDto,
  ReferentDto,
} from "@api";
import { InfiniteScrollCustomEvent } from "@ionic/angular";
import { createAction, props } from "@ngrx/store";

interface GetEventParams extends EventControllerGetEventRequestParams {
  fetchNested?: boolean;
}

interface GetEventsInfiniteScroll extends EventControllerGetManyEventsRequestParams {
  event?: InfiniteScrollCustomEvent;
}

interface ManyEventsDto extends EventsDto {
  event?: InfiniteScrollCustomEvent;
}

export const setLoading = createAction("[EVENT] Set Loading");

export const createEventContent = createAction("[EVENT] Create Event Content", props<EventControllerCreateEventContentRequestParams>());
export const createEventContentSuccess = createAction("[EVENT] Create Event Content Success", props<{ eventContent: EventContentDto }>());
export const createEventContentFailure = createAction("[EVENT] Create Event Content Failure", props<{ reason: string }>());

export const updateEventContent = createAction("[EVENT] Update Event Content", props<EventControllerUpdateEventContentRequestParams>());
export const updateEventContentSuccess = createAction("[EVENT] Update Event Content Success", props<{ eventContent: EventContentDto }>());
export const updateEventContentFailure = createAction("[EVENT] Update Event Content Failure", props<{ reason: string }>());

export const getEventContents = createAction("[EVENT] Get Event Contents", props<EventControllerGetManyEventContentsRequestParams>());
export const getEventContentsSuccess = createAction("[EVENT] Get Event Contents Success", props<EventContentsDto>());
export const getEventContentsFailure = createAction("[EVENT] Get Event Contents Failure", props<{ reason: string }>());

export const getEvents = createAction("[EVENT] Get Events", props<GetEventsInfiniteScroll>());
export const getEventsSuccess = createAction("[EVENT] Get Events Success", props<ManyEventsDto>());
export const getEventsFailure = createAction("[EVENT] Get Events Failure", props<{ reason: string }>());

export const getEventParticipants = createAction(
  "[EVENT] Get Event Participants",
  props<EventControllerGetEventParticipantsRequestParams>(),
);
export const getEventParticipantsSuccess = createAction("[EVENT] Get Event Participants Success", props<EventParticipantsResponseDto>());
export const getEventParticipantsFailure = createAction("[EVENT] Get Event Participants Failure", props<{ reason: string }>());

export const getEvent = createAction("[EVENT] Get Event", props<GetEventParams>());
export const getEventSuccess = createAction("[EVENT] Get Event Success", props<{ event: EventDto }>());
export const getEventFailure = createAction("[EVENT] Get Event Failure", props<{ reason: string }>());

export const getEventContent = createAction("[EVENT] Get Event Content", props<EventControllerGetEventContentRequestParams>());
export const getEventContentSuccess = createAction("[EVENT] Get Event Content Success", props<{ eventContent: EventContentDto }>());
export const getEventContentFailure = createAction("[EVENT] Get Event Content Failure", props<{ reason: string }>());

export const getEventContentEvents = createAction(
  "[EVENT] Get Event Content Events",
  props<EventControllerGetContentEventsRequestParams>(),
);
export const getEventContentEventsSuccess = createAction("[EVENT] Get Event Content Events Success", props<{ events: EventDto[] }>());
export const getEventContentEventsFailure = createAction("[EVENT] Get Event Content Events Failure", props<{ reason: string }>());

export const removeEventContent = createAction("[EVENT] Remove Event Content", props<EventControllerDeleteEventContentRequestParams>());
export const removeEventContentSuccess = createAction(
  "[EVENT] Remove Event Content Success",
  props<EventControllerDeleteEventContentRequestParams>(),
);
export const removeEventContentFailure = createAction("[EVENT] Remove Event Content Failure", props<{ reason: string }>());

export const getEventInvitation = createAction(
  "[EVENT] Get Event Invitation",
  props<EventControllerGetUserInvitationsForEventRequestParams>(),
);
export const getEventInvitationSuccess = createAction("[EVENT] Get Event Invitation Success", props<{ invitation: EventUserDto }>());
export const getEventInvitationFailure = createAction("[EVENT] Get Event Invitation Failure", props<{ reason: string }>());

export const createEvent = createAction("[EVENT] Create Event", props<EventControllerCreateEventRequestParams>());
export const createEventSuccess = createAction("[EVENT] Create Event Success", props<{ event: EventDto }>());
export const createEventFailure = createAction("[EVENT] Create Event Failure", props<{ reason: string }>());

export const updateEvent = createAction("[EVENT] Update Event", props<EventControllerUpdateEventRequestParams>());
export const updateEventSuccess = createAction("[EVENT] Update Event Success", props<{ event: EventDto }>());
export const updateEventFailure = createAction("[EVENT] Update Event Failure", props<{ reason: string }>());

export const removeEvent = createAction("[EVENT] Remove Event", props<EventControllerDeleteEventRequestParams>());
export const removeEventSuccess = createAction("[EVENT] Remove Event Success", props<EventControllerDeleteEventRequestParams>());
export const removeEventFailure = createAction("[EVENT] Remove Event Failure", props<{ reason: string }>());

export const respondToEvent = createAction("[EVENT] Respond To Event", props<EventControllerRespondToInvitationRequestParams>());
export const respondToEventSuccess = createAction("[EVENT] Respond To Event Success", props<{ invitation: EventUserDto }>());
export const respondToEventForbidden = createAction("[EVENT] Respond To Event Forbidden", props<{ eventId: string }>());
export const respondToEventClosed = createAction("[EVENT] Respond To Event Closed");
export const respondToEventFull = createAction("[EVENT] Respond To Event Full");
export const respondToEventFailure = createAction("[EVENT] Respond To Event Failure", props<{ reason: string }>());

export const getReferents = createAction("[EVENT] Get Referents");
export const getReferentsSuccess = createAction("[EVENT] Get Referents Success", props<{ referents: ReferentDto[] }>());
export const getReferentsFailure = createAction("[EVENT] Get Referents Failure", props<{ reason: string }>());
