import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { ActionButtonsService } from './api/action-buttons.service';
import { AuthService } from './api/auth.service';
import { ChanceNodesService } from './api/chance-nodes.service';
import { EventsService } from './api/events.service';
import { IntegrationsService } from './api/integrations.service';
import { InvoicesService } from './api/invoices.service';
import { LearningModulesService } from './api/learning-modules.service';
import { NewsService } from './api/news.service';
import { NotificationsService } from './api/notifications.service';
import { OrganizationsService } from './api/organizations.service';
import { PopoverUIService } from './api/popover-ui.service';
import { PushTokensService } from './api/push-tokens.service';
import { ReportsService } from './api/reports.service';
import { StoresService } from './api/stores.service';
import { SurveysService } from './api/surveys.service';
import { UsersService } from './api/users.service';
import { VersionsService } from './api/versions.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
