import {
  SurveyControllerCreateSurveyDefinitionRequestParams,
  SurveyControllerCreateSurveyFieldRequestParams,
  SurveyControllerDeleteSurveyDefinitionRequestParams,
  SurveyControllerDeleteSurveyFieldRequestParams,
  SurveyControllerGetDefinitionSurveyRequestParams,
  SurveyControllerGetSurveyByIdRequestParams,
  SurveyControllerGetSurveyDefinitionByIdRequestParams,
  SurveyControllerGetSurveyDefinitionBySlugRequestParams,
  SurveyControllerGetSurveyResponsesRequestParams,
  SurveyControllerStoreSurveyFieldResponseRequestParams,
  SurveyControllerSurveyFilledRequestParams,
  SurveyControllerUnassignSurveyRequestParams,
  SurveyControllerUpdateSurveyDefinitionRequestParams,
  SurveyControllerUpdateSurveyFieldRequestParams,
  SurveyDefinitionDto,
  SurveyDto,
  SurveyFieldDto,
  SurveyResponseDto,
} from "@api";
import { createAction, props } from "@ngrx/store";

export const setLoading = createAction("[SURVEYS] Set Loading");
export const hideLoading = createAction("[SURVEYS] Hide Loading");

export const getDefinitionBySlug = createAction(
  "[SURVEY] Get Definition By Slug",
  props<SurveyControllerGetSurveyDefinitionBySlugRequestParams>(),
);
export const getDefinitionBySlugSuccess = createAction(
  "[SURVEY] Get Definition By Slug Success",
  props<{ definition: SurveyDefinitionDto }>(),
);
export const getDefinitionBySlugFailure = createAction("[SURVEY] Get Definition By Slug Failure", props<{ reason: string }>());

export const getDefinitionById = createAction(
  "[SURVEY] Get Definition By Id",
  props<SurveyControllerGetSurveyDefinitionByIdRequestParams>(),
);
export const getDefinitionByIdSuccess = createAction("[SURVEY] Get Definition By Id Success", props<{ definition: SurveyDefinitionDto }>());
export const getDefinitionByIdFailure = createAction("[SURVEY] Get Definition By Id Failure", props<{ reason: string }>());

export const getSurveyById = createAction("[SURVEY] Get Survey By Id", props<SurveyControllerGetSurveyByIdRequestParams>());
export const getSurveyByIdSuccess = createAction("[SURVEY] Get Survey By Id Success", props<{ survey: SurveyDto }>());
export const getSurveyByIdFailure = createAction("[SURVEY] Get Survey By Id Failure", props<{ reason: string }>());

export const getSurveyByDefinitionSlug = createAction(
  "[SURVEY] Get Survey By Definition Slug",
  props<SurveyControllerGetDefinitionSurveyRequestParams>(),
);
export const getSurveyByDefinitionSlugSuccess = createAction(
  "[SURVEY] Get Survey By Definition Slug Success",
  props<{ survey: SurveyDto }>(),
);
export const getSurveyByDefinitionSlugFailure = createAction("[SURVEY] Get Survey By Definition Slug Failure", props<{ reason: string }>());

export const getCurrentSuccessReportReports = createAction("[SURVEY] Get Current Success Report Responses");
export const getCurrentSuccessReportReportsSuccess = createAction("[SURVEY] Get Current Success Report Responses Success");
export const getCurrentSuccessReportReportsFailure = createAction(
  "[SURVEY] Get Current Success Report Responses Failure",
  props<{ reason: string }>(),
);

export const getSurveyDefinitions = createAction("[SURVEY] Get Survey Definitions");
export const getSurveyDefinitionsSuccess = createAction(
  "[SURVEY] Get Survey Definitions Success",
  props<{ definitions: SurveyDefinitionDto[] }>(),
);
export const getSurveyDefinitionsFailure = createAction("[SURVEY] Get Survey Definitions failure", props<{ reason: string }>());

export const getAssignedSurveys = createAction("[SURVEY] Get Assigned Surveys");
export const getAssignedSurveysSuccess = createAction("[SURVEY] Get Assigned Surveys Success", props<{ surveys: SurveyDto[] }>());
export const getAssignedSurveysFailure = createAction("[SURVEY] Get Assigned Surveys Failure", props<{ reason: string }>());

export const getSurveyResponses = createAction("[SURVEY] Get Survey Responses", props<SurveyControllerGetSurveyResponsesRequestParams>());
export const getSurveyResponsesSuccess = createAction(
  "[SURVEY] Get Survey Responses Success",
  props<{ surveyId: string; responses: SurveyResponseDto[] }>(),
);
export const getSurveyResponsesFailure = createAction("[SURVEY] Get Survey Responses Failure", props<{ reason: string }>());

export const storeSurveyError = createAction(
  "[SURVEY] Store Survey Error",
  props<{ fieldId: string; error: string; params?: Record<string, any> }>(),
);
export const cleanSurveyError = createAction("[SURVEY] Clean Survey Error", props<{ fieldId: string }>());

export const createSurveyResponse = createAction(
  "[SURVEY] Create Survey Response",
  props<SurveyControllerStoreSurveyFieldResponseRequestParams>(),
);
export const createSurveyResponseSuccess = createAction(
  "[SURVEY] Create Survey Response Success",
  props<{ response: SurveyResponseDto }>(),
);
export const createSurveyResponseFailure = createAction("[SURVEY] Create Survey Response Failure", props<{ reason: string }>());

export const sendSurvey = createAction("[SURVEY] Send Survey", props<SurveyControllerSurveyFilledRequestParams & { surveySlug: string }>());
export const sendSurveySuccess = createAction("[SURVEY] Send Survey Success", props<{ surveySlug: string }>());
export const sendSurveyFailure = createAction("[SURVEY] Send Survey Failure", props<{ reason: string }>());

export const unassignSurvey = createAction("[SURVEY] Unassign Survey", props<SurveyControllerUnassignSurveyRequestParams>());
export const unassignSurveySuccess = createAction("[SURVEY] Unassign Survey Success", props<SurveyControllerUnassignSurveyRequestParams>());
export const unassignSurveyFailure = createAction("[SURVEY] Unassign Survey Failure", props<{ reason: string }>());

export const createSurveyDefinition = createAction(
  "[SURVEY]Create Survey Definition",
  props<SurveyControllerCreateSurveyDefinitionRequestParams>(),
);
export const createSurveyDefinitionSuccess = createAction(
  "[SURVEY] Create Survey Definition Success",
  props<{ surveyDefinition: SurveyDefinitionDto }>(),
);
export const createSurveyDefinitionFailure = createAction("[SURVEY] Create Survey Definition Failure", props<{ reason: string }>());

export const updateSurveyDefinition = createAction(
  "[SURVEY] Update Survey Definition",
  props<SurveyControllerUpdateSurveyDefinitionRequestParams & { goBack: boolean }>(),
);
export const updateSurveyDefinitionSuccess = createAction(
  "[SURVEY] Update Survey Definition Success",
  props<{ surveyDefinition: SurveyDefinitionDto; goBack: boolean }>(),
);
export const updateSurveyDefinitionFailure = createAction("[SURVEY] Update Survey Definition Failure", props<{ reason: string }>());

export const deleteSurveyDefinition = createAction(
  "[SURVEY] Delete Survey Definition",
  props<SurveyControllerDeleteSurveyDefinitionRequestParams>(),
);
export const deleteSurveyDefinitionSuccess = createAction(
  "[SURVEY] Delete Survey Definition Success",
  props<SurveyControllerDeleteSurveyDefinitionRequestParams>(),
);
export const deleteSurveyDefinitionFailure = createAction("[SURVEY] Delete Survey Definition Failure", props<{ reason: string }>());

export const createSurveyField = createAction(
  "[SURVEY] Create Survey Field",
  props<SurveyControllerCreateSurveyFieldRequestParams & { slug: string }>(),
);
export const createSurveyFieldSuccess = createAction(
  "[SURVEY] Create Survey Field Success",
  props<{ definitionId: string; field: SurveyFieldDto; slug: string }>(),
);
export const createSurveyFieldFailure = createAction("[SURVEY] Create Survey Field Failure", props<{ reason: string }>());

export const updateSurveyField = createAction(
  "[SURVEY] Update Survey Field",
  props<SurveyControllerUpdateSurveyFieldRequestParams & { slug: string } & { goBack: boolean }>(),
);
export const updateSurveyFieldSuccess = createAction(
  "[SURVEY] Update Survey Field Success",
  props<{ definitionId: string; field: SurveyFieldDto; slug: string; goBack: boolean }>(),
);
export const updateSurveyFieldFailure = createAction("[SURVEY] Update Survey Field Failure", props<{ reason: string }>());

export const deleteSurveyField = createAction(
  "[SURVEY] Delete Survey Field",
  props<SurveyControllerDeleteSurveyFieldRequestParams & { slug: string }>(),
);
export const deleteSurveyFieldSuccess = createAction(
  "[SURVEY] Delete Survey Field Success",
  props<{ definitionId: string; fieldId: string; slug: string }>(),
);
export const deleteSurveyFieldFailure = createAction("[SURVEY] Delete Survey Field Failure", props<{ reason: string }>());
