import {
  ReportControllerCreateReportRequestParams,
  ReportControllerReportFilledRequestParams,
  ReportDataDefinitionDto,
  ReportDto,
} from "@api";
import { createAction, props } from "@ngrx/store";

export const getReportDataDefinitions = createAction("[REPORT] Get Report Data Definitions");
export const getReportDataDefinitionsSuccess = createAction(
  "[REPORT] Get Report Data Definitions Success",
  props<{ dataDefinitions: ReportDataDefinitionDto[] }>(),
);
export const getReportDataDefinitionsFailure = createAction("[REPORT] Get Report Data Definitions Failure", props<{ reason: string }>());

export const createReport = createAction("[REPORT] Create Report", props<ReportControllerCreateReportRequestParams>());
export const createReportSuccess = createAction("[REPORT] Create Report Success", props<{ report: ReportDto }>());
export const createReportFailure = createAction("[REPORT] Create Report Failure", props<{ reason: string }>());

export const sendSurvey = createAction("[REPORT] Send Survey", props<ReportControllerReportFilledRequestParams>());
export const sendSurveySuccess = createAction(
  "[REPORT] Send Survey Success",
  props<{ reports: ReportDto[]; slug: string; month: number; year: number }>(),
);
export const sendSurveyFailure = createAction("[REPORT] Send Survey Failure", props<{ reason: string }>());

export const storeReportError = createAction(
  "[REPORT] Store Report Error",
  props<{ fieldId: string; error: string; params?: Record<string, any> }>(),
);
export const cleanReportError = createAction("[REPORT] Clean Report Error", props<{ fieldId: string }>());

export const getReportResponses = createAction("[REPORT] Get Report Responses", props<{ slug: string }>());
export const getReportResponsesSuccess = createAction("[REPORT] Get Report Responses Success", props<{ reports: ReportDto[] }>());
export const getReportResponsesFailure = createAction("[REPORT] Get Report Responses Failure", props<{ reason: string }>());
