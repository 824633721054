import { Pipe, PipeTransform } from "@angular/core";
import { PublicUser } from "@api";

@Pipe({ name: "username" })
export class UsernamePipe implements PipeTransform {
  public transform(user: PublicUser): string {
    if (!user) return null;
    if (user.firstName || user.lastName) return `${user.firstName || ""} ${user.lastName || ""}`.trim();
    return user.email;
  }
}
