import { Component, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { EventDto, EventUserDto, UserDto } from "@api";
import { ModalController } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { first } from "rxjs/operators";
import { RRuleNextExecutionPipe } from "src/app/pipes/rrule-next-execution/rrule-next-execution.pipe";
import { UsernamePipe } from "src/app/pipes/username/username.pipe";
import { RootState } from "src/app/store";
import * as EventActions from "src/app/store/event/actions";
import * as FromEvent from "src/app/store/event/selectors";
import * as FromUser from "src/app/store/user/selectors";

@Component({
  selector: "app-event-attendance",
  templateUrl: "./event-attendance.page.html",
  styleUrls: ["./event-attendance.page.scss"],
})
export class EventAttendancePage implements OnInit {
  @Input() public eventDto: EventDto;
  @Input() public response: EventUserDto.StatusEnum;

  public form: FormGroup;
  public availablePlaces$: Observable<number>;

  private eventExecution: Date;
  private user$: Observable<UserDto> = this.store.select(FromUser.selectUser);
  private invitation$: Observable<EventUserDto>;

  constructor(
    private store: Store<RootState>,
    private modalController: ModalController,
    private usernamePipe: UsernamePipe,
    private rruleNextExecution: RRuleNextExecutionPipe,
  ) {}

  public ngOnInit(): void {
    this.eventExecution = this.eventDto.recurrence
      ? this.rruleNextExecution.transform(this.eventDto.recurrence)
      : new Date(this.eventDto.date);

    this.availablePlaces$ = this.store.select(FromEvent.selectEventAvailablePlaces(this.eventDto.id));
    this.invitation$ = this.store.select(FromEvent.selectEventInvitation(this.eventDto.id, this.eventExecution.getTime()));

    this.invitation$.pipe(first()).subscribe(invitation => {
      if (invitation?.status === this.response) {
        this.dismiss();
      }
    });

    this.user$.pipe(first()).subscribe(user => {
      this.form = new FormGroup({
        mainAttendant: new FormControl({ value: this.usernamePipe.transform(user), disabled: true }),
        alone: new FormControl(true),
        firstAttendant: new FormControl(null),
        secondAttendant: new FormControl(null),
        thirdAttendant: new FormControl(null),
      });
    });

    if (this.response === EventUserDto.StatusEnum.Declined) {
      this.save();
    }
  }

  public dismiss(): void {
    this.invitation$.pipe(first()).subscribe(async invitation => {
      this.response = invitation?.status;
      if (await this.modalController.getTop()) {
        this.modalController.dismiss();
      }
    });
  }

  public save(): void {
    const { firstAttendant, secondAttendant, thirdAttendant } = this.form.value;
    const offPlatformAttendants = [firstAttendant, secondAttendant, thirdAttendant].filter(Boolean);

    this.store.dispatch(
      EventActions.respondToEvent({
        eventId: this.eventDto.id,
        updateEventUserDto: {
          status: this.response,
          eventExecution: this.eventExecution.getTime(),
          offPlatformAttendants,
        },
      }),
    );
    this.modalController.dismiss(offPlatformAttendants);
  }
}
