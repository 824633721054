import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ChanceNodesService } from "@api";
import { ModalController, NavController, ToastController } from "@ionic/angular";
import { Actions, concatLatestFrom, createEffect, ofType } from "@ngrx/effects";
import { Action, Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { Observable, of } from "rxjs";
import { catchError, map, mergeMap, tap } from "rxjs/operators";
import { AppRoutes, CHANCE_NODES_PER_PAGE, TOAST_DEFAULT_DURATION } from "src/app/constants";
import { RootState } from "..";
import * as ScenarioActions from "./actions";
import * as FromRouter from "src/app/store/router/selectors";

@Injectable()
export class ScenarioEffects {
  public getChanceNodes$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ScenarioActions.getChanceNodes),
      mergeMap(({ limit, page }) =>
        this.chanceNodeService.chanceNodeControllerGetChanceNodes({ limit, page }).pipe(
          map(chanceNodes => ScenarioActions.getChanceNodesSuccess({ chanceNodes })),
          catchError((error: HttpErrorResponse) => of(ScenarioActions.getChanceNodesFailure({ reason: error.error?.message }))),
        ),
      ),
    ),
  );

  public getChanceNodesSuccess$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ScenarioActions.getChanceNodesSuccess),
        tap(({ chanceNodes }) => {
          if (chanceNodes.pageCount > chanceNodes.page) {
            this.store.dispatch(ScenarioActions.getChanceNodes({ limit: CHANCE_NODES_PER_PAGE, page: chanceNodes.page + 1 }));
          }
        }),
      ),
    { dispatch: false },
  );

  public getTriggers$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ScenarioActions.getTriggers),
      mergeMap(() =>
        this.chanceNodeService.chanceNodeControllerGetTriggers().pipe(
          map(triggers => ScenarioActions.getTriggersSuccess({ triggers })),
          catchError((error: HttpErrorResponse) => of(ScenarioActions.getTriggersFailure({ reason: error.error?.message }))),
        ),
      ),
    ),
  );

  public getActions$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ScenarioActions.getActions),
      mergeMap(() =>
        this.chanceNodeService.chanceNodeControllerGetActions().pipe(
          map(actions => ScenarioActions.getActionsSuccess({ actions })),
          catchError((error: HttpErrorResponse) => of(ScenarioActions.getActionsFailure({ reason: error.error?.message }))),
        ),
      ),
    ),
  );

  public runScenario$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ScenarioActions.runScenario),
      mergeMap(({ chanceNodeId, storeId }) =>
        this.chanceNodeService.chanceNodeControllerRunScenarioForStore({ chanceNodeId, storeId }).pipe(
          map(logs => ScenarioActions.runScenarioSuccess({ chanceNodeId, runId: 0, logs })),
          catchError((error: HttpErrorResponse) => of(ScenarioActions.runScenarioFailure({ reason: error.error?.message }))),
        ),
      ),
    ),
  );

  public getScenarioRuns$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ScenarioActions.getScenarioRuns),
      mergeMap(({ chanceNodeId, limit, page, event }) =>
        this.chanceNodeService.chanceNodeControllerGetScenarioRuns({ chanceNodeId, limit, page }).pipe(
          map(runs => ScenarioActions.getScenarioRunsSuccess({ chanceNodeId, runs, event })),
          catchError((error: HttpErrorResponse) => of(ScenarioActions.getScenarioRunsFailure({ reason: error.error?.message }))),
        ),
      ),
    ),
  );

  public getScenarioRunsSuccess$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ScenarioActions.getScenarioRunsSuccess),
        tap(({ event }) => event?.target.complete()),
      ),
    { dispatch: false },
  );

  public getScenarioRunLogs$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ScenarioActions.getScenarioRunLogs),
      concatLatestFrom(() => this.store.select(FromRouter.selectScenarioId)),
      mergeMap(([{ limit, page, runId, event }, chanceNodeId]) =>
        this.chanceNodeService.chanceNodeControllerGetRunLogs({ limit, page, chanceNodeId, runId }).pipe(
          map(logs => {
            if (event) event.target.complete();
            return ScenarioActions.getScenarioRunLogsSuccess({ chanceNodeId, logs, runId });
          }),
          catchError((error: HttpErrorResponse) => of(ScenarioActions.getScenarioRunLogsFailure({ reason: error.error?.message }))),
        ),
      ),
    ),
  );

  public createChanceNode$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ScenarioActions.createChanceNode),
      mergeMap(({ createChanceNodeDto }) =>
        this.chanceNodeService.chanceNodeControllerCreateChanceNode({ createChanceNodeDto }).pipe(
          map(chanceNode => ScenarioActions.createChanceNodeSuccess({ chanceNode })),
          catchError((error: HttpErrorResponse) => of(ScenarioActions.createChanceNodeFailure({ reason: error.error?.message }))),
        ),
      ),
    ),
  );

  public createChanceNodeSuccess$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ScenarioActions.createChanceNodeSuccess),
        tap(async ({ chanceNode }) => {
          if (await this.modalController.getTop()) this.modalController.dismiss();
          await this.navController.navigateForward([AppRoutes.scenarios, chanceNode.id]);
        }),
      ),
    { dispatch: false },
  );

  public updateChanceNode$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ScenarioActions.updateChanceNode),
      mergeMap(({ chanceNodeId, updateChanceNodeDto }) =>
        this.chanceNodeService.chanceNodeControllerUpdateChanceNode({ chanceNodeId, updateChanceNodeDto }).pipe(
          map(chanceNode => ScenarioActions.updateChanceNodeSuccess({ chanceNode })),
          catchError((error: HttpErrorResponse) => of(ScenarioActions.updateChanceNodeFailure({ reason: error.error?.message }))),
        ),
      ),
    ),
  );

  public updateChanceNodeSuccess$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ScenarioActions.updateChanceNodeSuccess,
          ScenarioActions.updateChanceNodeOnScenarioSuccess,
          ScenarioActions.deleteChanceNodeOnScenarioSuccess,
        ),
        tap(async () => {
          if (await this.modalController.getTop()) this.modalController.dismiss();
        }),
      ),
    { dispatch: false },
  );

  public deleteChanceNode$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ScenarioActions.deleteChanceNode),
      mergeMap(({ chanceNodeId }) =>
        this.chanceNodeService.chanceNodeControllerDeleteChanceNode({ chanceNodeId }).pipe(
          map(() => ScenarioActions.deleteChanceNodeSuccess({ chanceNodeId })),
          catchError((error: HttpErrorResponse) => of(ScenarioActions.deleteChanceNodeFailure({ reason: error.error?.message }))),
        ),
      ),
    ),
  );

  public createChanceNodeOnScenario$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ScenarioActions.createChanceNodeOnScenario),
      mergeMap(({ createChanceNodeDto, parentId, node }) =>
        this.chanceNodeService.chanceNodeControllerCreateChanceNode({ createChanceNodeDto }).pipe(
          mergeMap(chanceNode =>
            this.chanceNodeService
              .chanceNodeControllerUpdateChanceNode({ chanceNodeId: parentId, updateChanceNodeDto: { [node]: chanceNode.id } })
              .pipe(
                mergeMap(parentNode => [
                  ScenarioActions.createChanceNodeOnScenarioSuccess({ chanceNode }),
                  ScenarioActions.updateChanceNodeSuccess({ chanceNode: parentNode }),
                ]),
                catchError((error: HttpErrorResponse) =>
                  of(ScenarioActions.createChanceNodeOnScenarioFailure({ reason: error.error?.message })),
                ),
              ),
          ),
          catchError((error: HttpErrorResponse) => of(ScenarioActions.createChanceNodeOnScenarioFailure({ reason: error.error?.message }))),
        ),
      ),
    ),
  );

  public updateChanceNodeOnScenario$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ScenarioActions.updateChanceNodeOnScenario),
      mergeMap(({ chanceNodeId, updateChanceNodeDto }) =>
        this.chanceNodeService.chanceNodeControllerUpdateChanceNode({ chanceNodeId, updateChanceNodeDto }).pipe(
          map(chanceNode => ScenarioActions.updateChanceNodeOnScenarioSuccess({ chanceNode })),
          catchError((error: HttpErrorResponse) => of(ScenarioActions.updateChanceNodeOnScenarioFailure({ reason: error.error?.message }))),
        ),
      ),
      catchError((error: HttpErrorResponse) => of(ScenarioActions.updateChanceNodeOnScenarioFailure({ reason: error.error?.message }))),
    ),
  );

  public deleteChanceNodeOnScenario$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ScenarioActions.deleteChanceNodeOnScenario),
      mergeMap(({ chanceNodeId }) =>
        this.chanceNodeService.chanceNodeControllerDeleteChanceNode({ chanceNodeId }).pipe(
          map(() => ScenarioActions.deleteChanceNodeOnScenarioSuccess({ chanceNodeId })),
          catchError((error: HttpErrorResponse) => of(ScenarioActions.deleteChanceNodeOnScenarioFailure({ reason: error.error?.message }))),
        ),
      ),
      catchError((error: HttpErrorResponse) => of(ScenarioActions.deleteChanceNodeOnScenarioFailure({ reason: error.error?.message }))),
    ),
  );

  public addActionToChanceNode$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ScenarioActions.addActionToChanceNode),
      mergeMap(({ chanceNodeId, createChanceNodeActionDto }) =>
        this.chanceNodeService.chanceNodeControllerCreateChanceNodeAction({ chanceNodeId, createChanceNodeActionDto }).pipe(
          map(chanceNodeAction => ScenarioActions.addActionToChanceNodeSuccess({ chanceNodeId, chanceNodeAction })),
          catchError((error: HttpErrorResponse) => of(ScenarioActions.deleteChanceNodeOnScenarioFailure({ reason: error.error?.message }))),
        ),
      ),
    ),
  );

  public editChanceNodeAction$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ScenarioActions.editChanceNodeAction),
      mergeMap(({ chanceNodeActionId, chanceNodeId, updateChanceNodeActionDto }) =>
        this.chanceNodeService
          .chanceNodeControllerUpdateChanceNodeAction({ chanceNodeActionId, chanceNodeId, updateChanceNodeActionDto })
          .pipe(
            map(chanceNodeAction => ScenarioActions.editChanceNodeActionSuccess({ chanceNodeAction, chanceNodeId })),
            catchError((error: HttpErrorResponse) => of(ScenarioActions.editChanceNodeActionFailure({ reason: error.error?.message }))),
          ),
      ),
    ),
  );

  public deleteChanceNodeAction$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ScenarioActions.deleteChanceNodeAction),
      mergeMap(({ chanceNodeActionId, chanceNodeId }) =>
        this.chanceNodeService.chanceNodeControllerDeleteChanceNodeAction({ chanceNodeActionId, chanceNodeId }).pipe(
          map(() => ScenarioActions.deleteChanceNodeActionSuccess({ chanceNodeActionId, chanceNodeId })),
          catchError((error: HttpErrorResponse) => of(ScenarioActions.deleteChanceNodeActionFailure({ reason: error.error?.message }))),
        ),
      ),
    ),
  );

  public closeActionSelectorModal$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ScenarioActions.addActionToChanceNodeSuccess,
          ScenarioActions.editChanceNodeActionSuccess,
          ScenarioActions.deleteChanceNodeActionSuccess,
        ),
        tap(async () => {
          const modal = await this.modalController.getTop();
          modal.dismiss();
        }),
      ),
    { dispatch: false },
  );

  public showErrorToast$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ScenarioActions.getChanceNodesFailure,
          ScenarioActions.getTriggersFailure,
          ScenarioActions.getActionsFailure,
          ScenarioActions.getScenarioRunsFailure,
          ScenarioActions.createChanceNodeFailure,
          ScenarioActions.updateChanceNodeFailure,
          ScenarioActions.deleteChanceNodeFailure,
          ScenarioActions.createChanceNodeOnScenarioFailure,
          ScenarioActions.updateChanceNodeOnScenarioFailure,
          ScenarioActions.deleteChanceNodeOnScenarioFailure,
          ScenarioActions.addActionToChanceNodeFailure,
          ScenarioActions.editChanceNodeActionFailure,
          ScenarioActions.deleteChanceNodeActionFailure,
        ),
        tap(async ({ reason }) => this.showErrorToast(reason)),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private store: Store<RootState>,
    private chanceNodeService: ChanceNodesService,
    private navController: NavController,
    private modalController: ModalController,
    private toastController: ToastController,
    private translateService: TranslateService,
  ) {}

  private async showErrorToast(message: string): Promise<void> {
    const toast = await this.toastController.create({
      color: "danger",
      message: this.translateService.instant((message || "DEFAULT_ERROR_MESSAGE").toString()),
      duration: TOAST_DEFAULT_DURATION,
    });
    await toast.present();
  }
}
