import { ReportDataDefinitionDto, ReportDto } from "@api";
import { createSelector, DefaultProjectorFn, MemoizedSelector } from "@ngrx/store";
import { isReport } from "src/app/utils/is-report";
import { sortByKPI } from "src/app/utils/sort-by-kpi";
import { sortByDataDefinition } from "src/app/utils/sort-by-data-definition";
import { RootState } from "..";
import { ReportState } from "./state";

const selectFeature = (state: RootState): ReportState => state.report;

export const selectLoading = createSelector(selectFeature, state => state.loading);

export const selectDataDefinitionById = (
  id: string,
): MemoizedSelector<RootState, ReportDataDefinitionDto, DefaultProjectorFn<ReportDataDefinitionDto>> =>
  createSelector(selectFeature, state => state.dataDefinitions[id]);

export const selectReportErrors = createSelector(selectFeature, state => state.localReportErrors);

export const selectBestReport = (
  dataSource: ReportDto.DataSourceEnum,
  month: number,
  year: number,
): MemoizedSelector<RootState, ReportDto, DefaultProjectorFn<ReportDto>> =>
  createSelector(selectFeature, state => {
    const reports = Object.values(state.reports)
      .filter(isReport)
      .filter(report => report.dataSource === dataSource)
      .filter(report => report.month === month && report.year === year);

    return reports.sort((a, b) => sortByKPI(a, b, reports, Object.values(state.dataDefinitions)))[0];
  });

export const selectReportsForDate = (
  dataSource: ReportDto.DataSourceEnum,
  month: number,
  year: number,
): MemoizedSelector<RootState, ReportDto[], DefaultProjectorFn<ReportDto[]>> =>
  createSelector(selectFeature, state => {
    const reports = Object.values(state.reports)
      .filter(isReport)
      .filter(report => report.dataSource === dataSource)
      .filter(report => report.month === month && report.year === year);

    return reports.sort((a, b) => sortByDataDefinition(a, b, Object.values(state.dataDefinitions)));
  });
