import { Pipe, PipeTransform } from "@angular/core";
import RRule from "rrule";

@Pipe({ name: "rruleNextExecution" })
export class RRuleNextExecutionPipe implements PipeTransform {
  public transform(rule: string): Date {
    if (!rule) return null;

    return RRule.fromString(rule).after(new Date());
  }
}
