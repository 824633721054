import { EventContentDto, EventDto, EventUserDto, ReferentDto } from "@api";

export interface EventContents {
  [key: string]: EventContentDto;
}

export interface Events {
  [key: string]: EventDto;
}

export interface Invitations {
  [key: string]: EventUserDto;
}

export interface Participants {
  [key: string]: EventUserDto[];
}

export interface EventState {
  currentPage: number;
  eventContents: EventContents;
  events: Events;
  invitations: Invitations;
  loading: boolean;
  pageCount: number;
  participants: Participants;
  referents: ReferentDto[];
  total: number;
}

export const initialState: EventState = {
  currentPage: 1,
  eventContents: {},
  events: {},
  invitations: {},
  loading: false,
  pageCount: 0,
  participants: {},
  referents: [],
  total: 0,
};

export const persistedKeys: string[] = ["eventContents", "referents"];
