/**
 * Wow family api
 * The Wow family API description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ReportDto { 
    createdAt: string;
    updatedAt: string;
    id: string;
    storeId: string;
    dataDefinitionId: string;
    value: number;
    month: number;
    year: number;
    dataSource: ReportDto.DataSourceEnum;
    status: ReportDto.StatusEnum;
    isBottleneck: boolean;
}
export namespace ReportDto {
    export type DataSourceEnum = 'HELLO_CASH' | 'GOOGLE_FORM' | 'GOOGLE' | 'TRIPADVISOR' | 'BWA_REPORT' | 'SUCCESS_REPORT';
    export const DataSourceEnum = {
        HelloCash: 'HELLO_CASH' as DataSourceEnum,
        GoogleForm: 'GOOGLE_FORM' as DataSourceEnum,
        Google: 'GOOGLE' as DataSourceEnum,
        Tripadvisor: 'TRIPADVISOR' as DataSourceEnum,
        BwaReport: 'BWA_REPORT' as DataSourceEnum,
        SuccessReport: 'SUCCESS_REPORT' as DataSourceEnum
    };
    export type StatusEnum = 'success' | 'warning';
    export const StatusEnum = {
        Success: 'success' as StatusEnum,
        Warning: 'warning' as StatusEnum
    };
}


