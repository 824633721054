import { Injectable } from "@angular/core";
import { Meta } from "@angular/platform-browser";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { routerNavigatedAction } from "@ngrx/router-store";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

@Injectable()
export class RouterEffects {
  public routerNavigated$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(routerNavigatedAction),
        tap(({ payload }) => {
          this.meta.updateTag({ name: "apple-itunes-app", content: `app-id=1582603591, app-argument=${payload.routerState.url}` });
        }),
      ),
    { dispatch: false },
  );

  constructor(private actions$: Actions, private meta: Meta) {}
}
