import { Component, Input } from "@angular/core";
import { EventDto, EventUserDto } from "@api";
import { AlertController, ModalController } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { first } from "rxjs/operators";
import { EventAttendancePage } from "src/app/modals/event-attendance/event-attendance.page";
import { EventResponsePipe } from "src/app/pipes/event-response/event-response.pipe";
import { RRuleNextExecutionPipe } from "src/app/pipes/rrule-next-execution/rrule-next-execution.pipe";
import { RootState } from "src/app/store";
import * as EventActions from "src/app/store/event/actions";

@Component({
  selector: "app-color-select",
  templateUrl: "./color-select.component.html",
  styleUrls: ["./color-select.component.scss"],
})
export class ColorSelectComponent {
  @Input() public size: "big" | string = "";
  @Input() public event: EventDto;
  @Input() public availablePlaces: number;
  @Input() public capacity: number;
  @Input() public presentingElement?: HTMLElement;

  public options = Object.values(EventUserDto.StatusEnum).filter(
    status => ![EventUserDto.StatusEnum.NoAnswer, EventUserDto.StatusEnum.Maybe].includes(status),
  );

  constructor(
    private store: Store<RootState>,
    private i18n: TranslateService,
    private eventResponsePipe: EventResponsePipe,
    private alertController: AlertController,
    private modalController: ModalController,
    private rruleNextExecution: RRuleNextExecutionPipe,
  ) {}

  public handleClick(event: Event): void {
    event.stopPropagation();
  }

  public async openSelector(): Promise<void> {
    this.eventResponsePipe
      .transform(this.event)
      .pipe(first())
      .subscribe(async currentResponse => {
        const alert = await this.alertController.create({
          header: "Bitte Teilnahme auswählen",
          backdropDismiss: false,
          keyboardClose: false,
          inputs: this.options.map(option => ({
            type: "radio",
            value: option,
            label: this.i18n.instant(`EVENT_RESPONSE_SELECTOR.${option}`),
            checked: this.shouldCheckOption(currentResponse, option),
          })),
          buttons: [
            { text: "zurück", role: "destructive" },
            { text: "ok", handler: this.handleResponse.bind(this) },
          ],
        });

        await alert.present();
      });
  }

  private handleResponse(response: EventUserDto.StatusEnum): void {
    this.eventResponsePipe
      .transform(this.event)
      .pipe(first())
      .subscribe(async currentResponse => {
        if (response && currentResponse !== response) {
          if (response === EventUserDto.StatusEnum.Confirmed && (!this.capacity || this.availablePlaces > 1)) {
            const modal = await this.modalController.create({
              component: EventAttendancePage,
              componentProps: { eventDto: this.event, response },
              showBackdrop: true,
              presentingElement: this.presentingElement,
            });

            await modal.present();
          } else {
            const eventExecution = this.event.recurrence
              ? this.rruleNextExecution.transform(this.event.recurrence)
              : new Date(this.event.date);

            this.store.dispatch(
              EventActions.respondToEvent({
                eventId: this.event.id,
                updateEventUserDto: {
                  status: response,
                  eventExecution: eventExecution.getTime(),
                },
              }),
            );
          }
        }
      });
  }

  private shouldCheckOption(currentResponse: EventUserDto.StatusEnum, option: EventUserDto.StatusEnum): boolean {
    return (
      currentResponse === option ||
      ((!currentResponse || currentResponse === EventUserDto.StatusEnum.NoAnswer) && option === EventUserDto.StatusEnum.Confirmed)
    );
  }
}
