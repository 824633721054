<div class="event__attendees {{ modifier }}">
  <span class="event__attendees__title" [innerHTML]="'EVENT_DETAIL_COMPONENT.PARTICIPANTS' | translate"></span>

  <ng-container *ngIf="(event?.contentId | toEventContent | async)?.participationLimit">
    <span class="event__attendees__label" [innerHTML]="'EVENT_DETAIL_COMPONENT.SEATS' | translate"></span>
    <app-event-availability
      class="event__attendees__label"
      [total]="(event?.contentId | toEventContent | async)?.participationLimit"
      [free]="(event?.contentId | toEventContent | async)?.participationLimit - total"
    ></app-event-availability>
  </ng-container>

  <span class="event__attendees__label" [innerHTML]="'EVENT_DETAIL_COMPONENT.CONFIRMED_PARTICIPANTS' | translate"></span>

  <ul *ngIf="(event?.id | toEventParticipants | async)?.length; else emptyMessage" class="event__attendees__list">
    <li
      *ngFor="let participant of event?.id | toEventParticipants | async"
      class="event__attendees__list__item"
      [class.--sublist]="participant.offPlatformAttendants?.length"
    >
      <span class="event__attendees__list__item__icon" [innerHTML]="participant.user | initials"></span>
      <span class="event__attendees__list__item__text" [innerHTML]="participant.user | username"></span>

      <ul *ngIf="participant.offPlatformAttendants?.length" class="event__attendees__list__item__sublist">
        <li *ngFor="let offPlatformAttendant of participant.offPlatformAttendants" class="event__attendees__list__item__sublist__item">
          <span class="event__attendees__list__item__sublist__item__icon" [innerHTML]="offPlatformAttendant | initials"></span>
          <span class="event__attendees__list__item__sublist__item__text" [innerHTML]="offPlatformAttendant"></span>
        </li>
      </ul>
    </li>
  </ul>

  <ng-template #emptyMessage>
    <p class="event__attendees__label --empty" [innerHTML]="'EVENT_DETAIL_COMPONENT.EMPTY_MESSAGE' | translate"></p>
  </ng-template>
</div>
