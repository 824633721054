import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReportsWithQuestionPipe } from "./reports-with-question.pipe";

@NgModule({
  declarations: [ReportsWithQuestionPipe],
  imports: [CommonModule],
  exports: [ReportsWithQuestionPipe],
})
export class ReportsWithQuestionModule {}
