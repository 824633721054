import { Pipe, PipeTransform } from "@angular/core";
import { PublicUser } from "@api";

@Pipe({ name: "initials" })
export class InitialsPipe implements PipeTransform {
  public transform(user: PublicUser | string): string {
    if (!user) return "w";

    if (typeof user === "string") return this.getInitialsForString(user as string);
    else return this.getInitialsForUser(user as PublicUser);
  }

  private getInitialsForString(user: string): string {
    const [firstName, lastName] = user.split(" ");
    return `${firstName?.charAt(0) || ""}${lastName?.charAt(0) || ""}`;
  }

  private getInitialsForUser(user: PublicUser): string {
    if (user.firstName || user.lastName) return `${user.firstName?.charAt(0) || ""}${user.lastName?.charAt(0) || ""}`;
    return user.email[0];
  }
}
