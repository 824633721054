import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { SurveyFieldDto } from "@api";
import { DatetimeCustomEvent } from "@ionic/angular";
import { format } from "date-fns";
import { ValidateSurveyResponseValueResponse } from "src/app/utils/validate-survey-response-value-response.interface";
import { HandleChangeParams } from "./interfaces/handle-change-params.interface";

@Component({
  selector: "app-survey-field",
  templateUrl: "./survey-field.component.html",
  styleUrls: ["./survey-field.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SurveyFieldComponent implements OnInit {
  @Input() public field: SurveyFieldDto;
  @Input() public value: string;
  @Input() public index: number;
  @Input() public error?: ValidateSurveyResponseValueResponse;
  @Input() public symbol?: string;

  @Output() public handleChange: EventEmitter<HandleChangeParams> = new EventEmitter();
  @Output() public handleHelp: EventEmitter<string> = new EventEmitter();

  public defaultImage: string = "assets/challenge/challenge.jpg";
  public fieldTypes: typeof SurveyFieldDto.TypeEnum = SurveyFieldDto.TypeEnum;
  public currentValue: string;

  constructor() {}

  public ngOnInit(): void {
    if (this.field?.type === SurveyFieldDto.TypeEnum.Rating) {
      this.value = this.value || this.field.defaultValue || "1";
    } else if (this.field?.type === SurveyFieldDto.TypeEnum.Multiselect) {
      this.value = this.value || this.field.defaultValue || "[]";
    }
  }

  public handleChangeDateInput(event: DatetimeCustomEvent): void {
    this.handleChange.emit({ field: this.field, value: format(new Date(event.detail.value), "yyyy-MM-dd") });
  }

  public handleChangeTimeInput(event: DatetimeCustomEvent): void {
    this.handleChange.emit({ field: this.field, value: format(new Date(event.detail.value), "HH:mm") });
  }

  public handleChangeDatetimeInput(event: DatetimeCustomEvent): void {
    this.handleChange.emit({ field: this.field, value: format(new Date(event.detail.value), "yyyy-MM-dd HH:mm") });
  }

  public handleChangeMultiselect(value: string): void {
    if (!this.value) return this.handleChange.emit({ field: this.field, value: JSON.stringify([value]) });

    let values: string[] = JSON.parse(this.value);
    if (values.includes(value)) values = values.filter(v => v !== value);
    else values.push(value);

    this.handleChange.emit({ field: this.field, value: JSON.stringify(values.length ? values : []) });
  }

  public formatSliderPin(value: number): string | number {
    return this.symbol ? `${Math.round(value)}${this.symbol}` : Math.round(value);
  }
}
