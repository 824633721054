<ion-content class="event-attendance">
  <h2 class="event-attendance__title" [innerHTML]="'EVENT_ATTENDANCE.TITLE' | translate"></h2>

  <form class="event-attendance__form" [formGroup]="form">
    <ion-item class="event-attendance__form__item">
      <ion-label
        class="event-attendance__form__item__label"
        position="stacked"
        [innerHTML]="'EVENT_ATTENDANCE.MAIN_ATTENDANT' | translate"
      ></ion-label>
      <ion-input class="event-attendance__form__item__input" type="text" formControlName="mainAttendant"></ion-input>
    </ion-item>

    <ion-item class="event-attendance__form__item --toggle">
      <ion-label class="event-attendance__form__item__label" [innerHTML]="'EVENT_ATTENDANCE.ALONE' | translate"></ion-label>
      <ion-toggle
        class="event-attendance__form__item__toggle"
        [attr.disabled]="((availablePlaces$ | async) <= 1) && ((eventDto?.contentId | toEventContent | async)?.participationLimit !== 0)"
        slot="start"
        formControlName="alone"
      ></ion-toggle>
    </ion-item>

    <ng-container *ngIf="!form.value.alone">
      <ion-item
        class="event-attendance__form__item"
        *ngIf="(availablePlaces$ | async) >= 2 || ((eventDto?.contentId | toEventContent | async)?.participationLimit === 0)"
      >
        <ion-label
          class="event-attendance__form__item__label"
          position="stacked"
          [innerHTML]="'EVENT_ATTENDANCE.FIRST_ATTENDANT' | translate"
        ></ion-label>
        <ion-input
          class="event-attendance__form__item__input"
          type="text"
          formControlName="firstAttendant"
          [placeholder]="'EVENT_ATTENDANCE.FULL_NAME' | translate"
        ></ion-input>
      </ion-item>
      <ion-item
        class="event-attendance__form__item"
        *ngIf="(availablePlaces$ | async) >= 3  || ((eventDto?.contentId | toEventContent | async)?.participationLimit === 0)"
      >
        <ion-label
          class="event-attendance__form__item__label"
          position="stacked"
          [innerHTML]="'EVENT_ATTENDANCE.SECOND_ATTENDANT' | translate"
        ></ion-label>
        <ion-input
          class="event-attendance__form__item__input"
          type="text"
          formControlName="secondAttendant"
          [placeholder]="'EVENT_ATTENDANCE.FULL_NAME' | translate"
        ></ion-input>
      </ion-item>
      <ion-item
        class="event-attendance__form__item"
        *ngIf="(availablePlaces$ | async) >= 4  || ((eventDto?.contentId | toEventContent | async)?.participationLimit === 0)"
      >
        <ion-label
          class="event-attendance__form__item__label"
          position="stacked"
          [innerHTML]="'EVENT_ATTENDANCE.THIRD_ATTENDANT' | translate"
        ></ion-label>
        <ion-input
          class="event-attendance__form__item__input"
          type="text"
          formControlName="thirdAttendant"
          [placeholder]="'EVENT_ATTENDANCE.FULL_NAME' | translate"
        ></ion-input>
      </ion-item>
    </ng-container>
  </form>
</ion-content>

<ion-footer class="event-attendance__footer">
  <ion-button class="button --outline --small" [innerHTML]="'BUTTONS.CANCEL' | translate" (click)="dismiss()"></ion-button>
  <ion-button class="button --primary --small" [innerHTML]="'BUTTONS.SAVE' | translate" (click)="save()"></ion-button>
</ion-footer>
