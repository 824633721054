import { IntegrationDto } from "@api";

interface Integrations {
  [id: string]: IntegrationDto;
}

export interface IntegrationState {
  integrations: Integrations;
  loading: boolean;
}

export const initialState: IntegrationState = {
  integrations: {},
  loading: false,
};
