import { Component, Input, OnInit } from "@angular/core";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { ModalController } from "@ionic/angular";

@Component({
  selector: "app-iframe-modal",
  templateUrl: "./iframe-modal.page.html",
  styleUrls: ["./iframe-modal.page.scss"],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class IframeModal implements OnInit {
  @Input() public url: string;

  public safeUrl: SafeUrl;

  constructor(private domSanitizer: DomSanitizer, private modalController: ModalController) {}

  public ngOnInit(): void {
    this.safeUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(this.url);
  }

  public dismiss(): void {
    this.modalController.dismiss();
  }
}
