import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { IonicModule } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";
import { OfflineIndicatorComponent } from "./offline-indicator.component";

@NgModule({
  imports: [CommonModule, IonicModule, TranslateModule],
  declarations: [OfflineIndicatorComponent],
  exports: [OfflineIndicatorComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class OfflineIndicatorComponentModule {}
