/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { AppRoutes } from "./constants";
import { AuthGuard } from "./guards/auth/auth.guard";

const routes: Routes = [
  {
    path: "",
    loadChildren: () => import("./tabs/tabs.module").then(m => m.TabsPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: AppRoutes.login,
    loadChildren: () => import("./pages/login/login.module").then(m => m.LoginPageModule),
  },
  {
    path: AppRoutes.resetPassword,
    loadChildren: () => import("./pages/reset-password/reset-password.module").then(m => m.ResetPasswordPageModule),
  },
  {
    path: AppRoutes.resetDone,
    loadChildren: () => import("./pages/reset-done/reset-done.module").then(m => m.ResetDonePageModule),
  },
  {
    path: AppRoutes.register,
    loadChildren: () => import("./pages/register/register.module").then(m => m.RegisterPageModule),
  },
  {
    path: AppRoutes.confirmRegistration,
    loadChildren: () => import("./pages/confirm-registration/confirm-registration.module").then(m => m.ConfirmRegistrationPageModule),
  },
  {
    path: "**",
    redirectTo: "",
  },
  {
    path: "report-detail",
    loadChildren: () => import("./pages/report-detail/report-detail.module").then(m => m.ReportDetailPageModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, scrollPositionRestoration: "top" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
