import {
  ActionDto,
  ChanceNodeActionDto,
  ChanceNodeControllerCreateChanceNodeActionRequestParams,
  ChanceNodeControllerCreateChanceNodeRequestParams,
  ChanceNodeControllerDeleteChanceNodeActionRequestParams,
  ChanceNodeControllerDeleteChanceNodeRequestParams,
  ChanceNodeControllerGetChanceNodesRequestParams,
  ChanceNodeControllerGetRunLogsRequestParams,
  ChanceNodeControllerGetScenarioRunsRequestParams,
  ChanceNodeControllerRunScenarioForStoreRequestParams,
  ChanceNodeControllerUpdateChanceNodeActionRequestParams,
  ChanceNodeControllerUpdateChanceNodeRequestParams,
  ChanceNodeDto,
  ChanceNodeLogDto,
  ChanceNodeLogsDto,
  ChanceNodeRunsDto,
  ChanceNodesDto,
  TriggerDto,
} from "@api";
import { InfiniteScrollCustomEvent } from "@ionic/angular";
import { createAction, props } from "@ngrx/store";

interface CreateChanceNodeOnScenarioRequestParams extends ChanceNodeControllerCreateChanceNodeRequestParams {
  parentId: string;
  node: "nextIfTrueId" | "nextIfFalseId";
}

interface GetScenarioRunsParams extends ChanceNodeControllerGetScenarioRunsRequestParams {
  event?: InfiniteScrollCustomEvent;
}

interface GetRunLogsParams extends ChanceNodeControllerGetRunLogsRequestParams {
  event?: InfiniteScrollCustomEvent;
}

export const getChanceNodes = createAction("[SCENARIO] Get Chance Nodes", props<ChanceNodeControllerGetChanceNodesRequestParams>());
export const getChanceNodesSuccess = createAction("[SCENARIO] Get Chance Nodes Success", props<{ chanceNodes: ChanceNodesDto }>());
export const getChanceNodesFailure = createAction("[SCENARIO] Get Chance Nodes Failure", props<{ reason: string }>());

export const getScenarioRuns = createAction("[SCENARIO] Get Scenario Runs", props<GetScenarioRunsParams>());
export const getScenarioRunsSuccess = createAction(
  "[SCENARIO] Get Scenario Runs Success",
  props<{ chanceNodeId: string; runs: ChanceNodeRunsDto; event?: InfiniteScrollCustomEvent }>(),
);
export const getScenarioRunsFailure = createAction("[SCENARIO] Get Scenario Runs Failure", props<{ reason: string }>());

export const getScenarioRunLogs = createAction("[SCENARIO] Get Scenario Run Logs", props<GetRunLogsParams>());
export const getScenarioRunLogsSuccess = createAction(
  "[SCENARIO] Get Scenario Run Logs Success",
  props<{ chanceNodeId: string; runId: number; logs: ChanceNodeLogsDto }>(),
);
export const getScenarioRunLogsFailure = createAction("[SCENARIO] Get Scenario Run Logs Failure", props<{ reason: string }>());

export const cleanRunLogs = createAction("[SCENARIO] Clean Run Logs", props<{ chanceNodeId: string }>());

export const runScenario = createAction("[SCENARIO] Run Scenario", props<ChanceNodeControllerRunScenarioForStoreRequestParams>());
export const runScenarioSuccess = createAction(
  "[SCENARIO] Run Scenario Success",
  props<{ chanceNodeId: string; runId: number; logs: ChanceNodeLogDto[] }>(),
);
export const runScenarioFailure = createAction("[SCENARIO] Run Scenario Failure", props<{ reason: string }>());

export const getTriggers = createAction("[SCENARIO] Get Triggers");
export const getTriggersSuccess = createAction("[SCENARIO] Get Triggers Success", props<{ triggers: TriggerDto[] }>());
export const getTriggersFailure = createAction("[SCENARIO] Get Triggers Failure", props<{ reason: string }>());

export const getActions = createAction("[SCENARIO] Get Actions");
export const getActionsSuccess = createAction("[SCENARIO] Get Actions Success", props<{ actions: ActionDto[] }>());
export const getActionsFailure = createAction("[SCENARIO] Get Actions Failure", props<{ reason: string }>());

export const createChanceNode = createAction("[SCENARIO] Create Chance Node", props<ChanceNodeControllerCreateChanceNodeRequestParams>());
export const createChanceNodeSuccess = createAction("[SCENARIO] Create Chance Node Success", props<{ chanceNode: ChanceNodeDto }>());
export const createChanceNodeFailure = createAction("[SCENARIO] Create Chance Node Failure", props<{ reason: string }>());

export const createChanceNodeOnScenario = createAction(
  "[SCENARIO] Create Chance Node On Scenario",
  props<CreateChanceNodeOnScenarioRequestParams>(),
);
export const createChanceNodeOnScenarioSuccess = createAction(
  "[SCENARIO] Create Chance Node On Scenario Success",
  props<{ chanceNode: ChanceNodeDto }>(),
);
export const createChanceNodeOnScenarioFailure = createAction(
  "[SCENARIO] Create Chance Node On Scenario Failure",
  props<{ reason: string }>(),
);

export const updateChanceNode = createAction("[SCENARIO] Update Chance Node", props<ChanceNodeControllerUpdateChanceNodeRequestParams>());
export const updateChanceNodeSuccess = createAction("[SCENARIO] Update Chance Node Success", props<{ chanceNode: ChanceNodeDto }>());
export const updateChanceNodeFailure = createAction("[SCENARIO] Update Chance Node Failure", props<{ reason: string }>());

export const updateChanceNodeOnScenario = createAction(
  "[SCENARIO] Update Chance Node On Scenario",
  props<ChanceNodeControllerUpdateChanceNodeRequestParams>(),
);
export const updateChanceNodeOnScenarioSuccess = createAction(
  "[SCENARIO] Update Chance On Scenario Node Success",
  props<{ chanceNode: ChanceNodeDto }>(),
);
export const updateChanceNodeOnScenarioFailure = createAction(
  "[SCENARIO] Update Chance On Scenario Node Failure",
  props<{ reason: string }>(),
);

export const deleteChanceNode = createAction("[SCENARIO] Delete Chance Node", props<ChanceNodeControllerDeleteChanceNodeRequestParams>());
export const deleteChanceNodeSuccess = createAction(
  "[SCENARIO] Delete Chance Node Success",
  props<ChanceNodeControllerDeleteChanceNodeRequestParams>(),
);
export const deleteChanceNodeFailure = createAction("[SCENARIO] Delete Chance Node Failure", props<{ reason: string }>());

export const deleteChanceNodeOnScenario = createAction(
  "[SCENARIO] Delete Chance Node On Scenario",
  props<ChanceNodeControllerDeleteChanceNodeRequestParams>(),
);
export const deleteChanceNodeOnScenarioSuccess = createAction(
  "[SCENARIO] Delete Chance Node On Scenario Success",
  props<ChanceNodeControllerDeleteChanceNodeRequestParams>(),
);
export const deleteChanceNodeOnScenarioFailure = createAction(
  "[SCENARIO] Delete Chance Node On Scenario Failure",
  props<{ reason: string }>(),
);

export const addActionToChanceNode = createAction(
  "[SCENARIO] Add Action To Chance Node",
  props<ChanceNodeControllerCreateChanceNodeActionRequestParams>(),
);
export const addActionToChanceNodeSuccess = createAction(
  "[SCENARIO] Add Action To Chance Node Success",
  props<{ chanceNodeId: string; chanceNodeAction: ChanceNodeActionDto }>(),
);
export const addActionToChanceNodeFailure = createAction("[SCENARIO] Add Action To Chance Node Failure", props<{ reason: string }>());

export const editChanceNodeAction = createAction(
  "[SCENARIO] Edit Chance Node Action",
  props<ChanceNodeControllerUpdateChanceNodeActionRequestParams>(),
);
export const editChanceNodeActionSuccess = createAction(
  "[SCENARIO] Edit Chance Node Action Success",
  props<{ chanceNodeId: string; chanceNodeAction: ChanceNodeActionDto }>(),
);
export const editChanceNodeActionFailure = createAction("[SCENARIO] Edit Chance Node Action Failure", props<{ reason: string }>());

export const deleteChanceNodeAction = createAction(
  "[SCENARIO] Delete Chance node Action",
  props<ChanceNodeControllerDeleteChanceNodeActionRequestParams>(),
);
export const deleteChanceNodeActionSuccess = createAction(
  "[SCENARIO] Delete Chance node Action Success",
  props<ChanceNodeControllerDeleteChanceNodeActionRequestParams>(),
);
export const deleteChanceNodeActionFailure = createAction("[SCENARIO] Delete Chance node Action Failure", props<{ reason: string }>());
