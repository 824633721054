import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { SurveysService } from "@api";
import { ModalController, NavController, ToastController } from "@ionic/angular";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { Observable, of } from "rxjs";
import { catchError, map, mergeMap, tap } from "rxjs/operators";
import { AppRoutes, QueryParams, TOAST_DEFAULT_DURATION } from "src/app/constants";
import { NavigationService } from "src/app/services/navigation/navigation.service";
import * as SurveyActions from "./actions";

@Injectable()
export class SurveyEffects {
  public getDefinitionBySlug$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SurveyActions.getDefinitionBySlug),
      mergeMap(({ slug }) =>
        this.surveyService.surveyControllerGetSurveyDefinitionBySlug({ slug }).pipe(
          map(definition => SurveyActions.getDefinitionBySlugSuccess({ definition })),
          catchError((error: HttpErrorResponse) => of(SurveyActions.getDefinitionBySlugFailure({ reason: error.error?.message }))),
        ),
      ),
    ),
  );

  public getSurveyById$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SurveyActions.getSurveyById),
      mergeMap(({ surveyId }) =>
        this.surveyService.surveyControllerGetSurveyById({ surveyId }).pipe(
          map(survey => SurveyActions.getSurveyByIdSuccess({ survey })),
          catchError((error: HttpErrorResponse) => of(SurveyActions.getSurveyByIdFailure({ reason: error.error?.message }))),
        ),
      ),
    ),
  );

  public getSurveyByDefinitionSlug$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SurveyActions.getSurveyByDefinitionSlug),
      mergeMap(({ definitionSlug }) =>
        this.surveyService.surveyControllerGetDefinitionSurvey({ definitionSlug }).pipe(
          map(survey => SurveyActions.getSurveyByDefinitionSlugSuccess({ survey })),
          catchError((error: HttpErrorResponse) => of(SurveyActions.getSurveyByDefinitionSlugFailure({ reason: error.error?.message }))),
        ),
      ),
    ),
  );

  public getDefinitionById$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SurveyActions.getDefinitionById),
      mergeMap(({ definitionId }) =>
        this.surveyService.surveyControllerGetSurveyDefinitionById({ definitionId }).pipe(
          map(definition => SurveyActions.getDefinitionByIdSuccess({ definition })),
          catchError((error: HttpErrorResponse) => of(SurveyActions.getDefinitionByIdFailure({ reason: error.error?.message }))),
        ),
      ),
    ),
  );

  public getSurveyDefinitions$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SurveyActions.getSurveyDefinitions),
      mergeMap(() =>
        this.surveyService.surveyControllerGetSurveyDefinitions().pipe(
          map(definitions => SurveyActions.getSurveyDefinitionsSuccess({ definitions })),
          catchError((error: HttpErrorResponse) =>
            of(
              SurveyActions.getSurveyDefinitionsFailure({
                reason: error.error?.message,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  public getAssignedSurveys$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SurveyActions.getAssignedSurveys),
      mergeMap(() =>
        this.surveyService.surveyControllerGetUserAssignedSurveys().pipe(
          map(surveys => SurveyActions.getAssignedSurveysSuccess({ surveys })),
          catchError((error: HttpErrorResponse) => of(SurveyActions.getAssignedSurveysFailure({ reason: error.error?.message }))),
        ),
      ),
    ),
  );

  public getAssignedSurveysSuccess$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SurveyActions.getAssignedSurveysSuccess),
        tap(async ({ surveys }) => {
          const survey = [...surveys]?.pop();
          const modal = await this.modalController.getTop();
          if (survey && !modal) this.navigationService.handleNavigationTo(`/${AppRoutes.surveys}/${survey.id}`);
        }),
      ),
    { dispatch: false },
  );

  public createSurveyDefinition$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SurveyActions.createSurveyDefinition),
      mergeMap(({ createSurveyDefinitionDto }) =>
        this.surveyService
          .surveyControllerCreateSurveyDefinition({
            createSurveyDefinitionDto,
          })
          .pipe(
            map(surveyDefinition => SurveyActions.createSurveyDefinitionSuccess({ surveyDefinition })),
            catchError((error: HttpErrorResponse) => of(SurveyActions.createSurveyDefinitionFailure({ reason: error.error?.message }))),
          ),
      ),
    ),
  );

  public createSurveyDefinitionSuccess$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SurveyActions.createSurveyDefinitionSuccess),
        tap(({ surveyDefinition }) => this.navController.navigateForward([AppRoutes.adminSurveyDefinitions, surveyDefinition.slug])),
      ),
    { dispatch: false },
  );

  public updateSurveyDefinition$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SurveyActions.updateSurveyDefinition),
      mergeMap(({ updateSurveyDefinitionDto, definitionId, goBack }) =>
        this.surveyService.surveyControllerUpdateSurveyDefinition({ updateSurveyDefinitionDto, definitionId }).pipe(
          map(surveyDefinition => SurveyActions.updateSurveyDefinitionSuccess({ surveyDefinition, goBack })),
          catchError((error: HttpErrorResponse) => of(SurveyActions.updateSurveyDefinitionFailure({ reason: error.error?.message }))),
        ),
      ),
    ),
  );

  public updateSurveyDefinitionSuccess$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SurveyActions.updateSurveyDefinitionSuccess),
        tap(
          ({ surveyDefinition, goBack }) =>
            goBack && this.navController.navigateBack([AppRoutes.adminSurveyDefinitions, surveyDefinition.slug]),
        ),
      ),
    { dispatch: false },
  );

  public updateSurveyField$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SurveyActions.updateSurveyField),
      mergeMap(({ definitionId, fieldId, slug, updateSurveyFieldDto, goBack }) =>
        this.surveyService.surveyControllerUpdateSurveyField({ definitionId, fieldId, updateSurveyFieldDto }).pipe(
          map(field => SurveyActions.updateSurveyFieldSuccess({ definitionId, field, slug, goBack })),
          catchError((error: HttpErrorResponse) => of(SurveyActions.updateSurveyFieldFailure({ reason: error?.error?.message }))),
        ),
      ),
    ),
  );

  public updateSurveyFieldSuccess$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SurveyActions.updateSurveyFieldSuccess),
        tap(
          ({ definitionId, field, slug, goBack }) =>
            goBack && this.navController.navigateBack([AppRoutes.adminSurveyDefinitions, slug, AppRoutes.surveyFields, field.id]),
        ),
      ),
    { dispatch: false },
  );

  public deleteSurveyField$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SurveyActions.deleteSurveyField),
      mergeMap(({ definitionId, fieldId, slug }) =>
        this.surveyService.surveyControllerDeleteSurveyField({ definitionId, fieldId }).pipe(
          map(() => SurveyActions.deleteSurveyFieldSuccess({ definitionId, fieldId, slug })),
          catchError((error: HttpErrorResponse) => of(SurveyActions.deleteSurveyFieldFailure({ reason: error?.error?.message }))),
        ),
      ),
    ),
  );

  public deleteSurveyFieldSuccess$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SurveyActions.deleteSurveyFieldSuccess),
        tap(({ slug }) => this.navController.navigateBack([AppRoutes.adminSurveyDefinitions, slug])),
      ),
    { dispatch: false },
  );

  public createSurveyResponse$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SurveyActions.createSurveyResponse),
      mergeMap(({ createSurveyResponseDto, surveyFieldId, surveyId }) =>
        this.surveyService.surveyControllerStoreSurveyFieldResponse({ createSurveyResponseDto, surveyFieldId, surveyId }).pipe(
          map(response => SurveyActions.createSurveyResponseSuccess({ response })),
          catchError((error: HttpErrorResponse) => of(SurveyActions.createSurveyResponseFailure({ reason: error.error?.message }))),
        ),
      ),
    ),
  );

  public sendSurvey$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SurveyActions.sendSurvey),
      mergeMap(({ surveyId, surveySlug }) =>
        this.surveyService.surveyControllerSurveyFilled({ surveyId }).pipe(
          map(() => SurveyActions.sendSurveySuccess({ surveySlug })),
          catchError((error: HttpErrorResponse) => of(SurveyActions.createSurveyResponseFailure({ reason: error.error?.message }))),
        ),
      ),
    ),
  );

  public sendSurveySuccess$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SurveyActions.sendSurveySuccess),
        tap(async ({ surveySlug }) => {
          const modal = await this.modalController.getTop();
          modal?.dismiss();
          this.navController.navigateRoot([AppRoutes.success], { queryParams: { [QueryParams.surveySlug]: surveySlug } });
        }),
      ),
    { dispatch: false },
  );

  public getSurveyResponses$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SurveyActions.getSurveyResponses),
      mergeMap(({ surveyId }) =>
        this.surveyService.surveyControllerGetSurveyResponses({ surveyId }).pipe(
          map(responses => SurveyActions.getSurveyResponsesSuccess({ surveyId, responses })),
          catchError((error: HttpErrorResponse) => of(SurveyActions.getSurveyResponsesFailure({ reason: error.error?.message }))),
        ),
      ),
    ),
  );

  public unassignSurvey$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SurveyActions.unassignSurvey),
      mergeMap(({ surveyId }) =>
        this.surveyService.surveyControllerUnassignSurvey({ surveyId }).pipe(
          map(() => SurveyActions.unassignSurveySuccess({ surveyId })),
          catchError((error: HttpErrorResponse) => of(SurveyActions.unassignSurveyFailure({ reason: error.error?.message }))),
        ),
      ),
    ),
  );
  public deleteSurveyDefinition$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SurveyActions.deleteSurveyDefinition),
      mergeMap(({ definitionId }) =>
        this.surveyService.surveyControllerDeleteSurveyDefinition({ definitionId }).pipe(
          map(() => SurveyActions.deleteSurveyDefinitionSuccess({ definitionId })),
          catchError((error: HttpErrorResponse) => of(SurveyActions.deleteSurveyDefinitionFailure({ reason: error?.error?.message }))),
        ),
      ),
    ),
  );
  public deleteSurveyDefinitionSuccess$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SurveyActions.deleteSurveyDefinitionSuccess),
        tap(() => this.navController.navigateBack(AppRoutes.adminSurveyDefinitions)),
      ),
    { dispatch: false },
  );

  public createSurveyField$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SurveyActions.createSurveyField),
      mergeMap(({ definitionId, createSurveyFieldDto, slug }) =>
        this.surveyService.surveyControllerCreateSurveyField({ definitionId, createSurveyFieldDto }).pipe(
          map(field => SurveyActions.createSurveyFieldSuccess({ definitionId, field, slug })),
          catchError((error: HttpErrorResponse) => of(SurveyActions.createSurveyFieldFailure({ reason: error?.error?.message }))),
        ),
      ),
    ),
  );

  public createSurveyFieldSuccess$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SurveyActions.createSurveyFieldSuccess),
        tap(({ definitionId, field, slug }) => {
          this.navController.navigateForward([AppRoutes.adminSurveyDefinitions, slug, AppRoutes.surveyFields, field.id]);
        }),
      ),
    { dispatch: false },
  );

  public showErrorToast$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          SurveyActions.getDefinitionBySlugFailure,
          SurveyActions.getSurveyByDefinitionSlugFailure,
          SurveyActions.getCurrentSuccessReportReportsFailure,
          SurveyActions.getAssignedSurveysFailure,
          SurveyActions.getDefinitionByIdFailure,
          SurveyActions.getSurveyResponsesFailure,
          SurveyActions.sendSurveyFailure,
          SurveyActions.unassignSurveyFailure,
        ),
        tap(async ({ reason }) => this.showErrorToast(reason)),
      ),
    { dispatch: false },
  );

  constructor(
    private readonly actions$: Actions,
    private readonly surveyService: SurveysService,
    private readonly i18n: TranslateService,
    private readonly navController: NavController,
    private readonly toastController: ToastController,
    private readonly modalController: ModalController,
    private readonly navigationService: NavigationService,
  ) {}

  private async showErrorToast(message: string): Promise<void> {
    const toast = await this.toastController.create({
      color: "danger",
      message: this.i18n.instant((message || "DEFAULT_ERROR_MESSAGE").toString()),
      duration: TOAST_DEFAULT_DURATION,
    });
    await toast.present();
  }
}
