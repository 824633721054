import "capacitor-plugin-app-tracking-transparency";
import * as AppActions from "src/app/store/app/actions";
import * as UserActions from "src/app/store/user/actions";
import * as ReportActions from "src/app/store/report/actions";
import * as SurveyActions from "src/app/store/survey/actions";
import * as IntegrationsActions from "src/app/store/integration/actions";
import * as FromUser from "src/app/store/user/selectors";
import { parse } from "querystring";
import { EnvironmentService } from "./services/environment/environment.service";
import { Store } from "@ngrx/store";
import { RootState } from "./store";
import { first, skipWhile } from "rxjs/operators";
import { App } from "@capacitor/app";
import { PushNotificationsService } from "./services/push-notifications/push-notifications.service";
import { AngularFireAnalytics } from "@angular/fire/compat/analytics";
import { AppTrackingTransparency, AppTrackingStatusResponse } from "capacitor-plugin-app-tracking-transparency";
import { Platform } from "@ionic/angular";
import { Version } from "@api";
import { QueryParams } from "./constants";

export const appInitializer =
  (
    platform: Platform,
    environmentService: EnvironmentService,
    pushNotificationService: PushNotificationsService,
    analyticsService: AngularFireAnalytics,
    store: Store<RootState>,
  ): (() => Promise<void>) =>
  async (): Promise<void> => {
    const appPlatform = platform.is("android") ? Version.IdEnum.Android : platform.is("ios") ? Version.IdEnum.Ios : Version.IdEnum.Www;
    const qs = parse(window.location.search?.slice(1));

    if (qs && qs[QueryParams.refreshToken]) {
      store.dispatch(UserActions.setAccessToken({ accessToken: "-" }));
      store.dispatch(UserActions.setRefreshToken({ refreshToken: qs[QueryParams.refreshToken].toString() }));
    }

    App.addListener("appStateChange", ({ isActive }) => {
      if (isActive) {
        store
          .select(FromUser.selectUser)
          .pipe(
            skipWhile(user => !user),
            first(),
          )
          .subscribe(() => {
            pushNotificationService.clearBadge();
            store.dispatch(AppActions.getLatestVersion({ platform: appPlatform }));
            store.dispatch(UserActions.getActionButton());
            store.dispatch(UserActions.getPopoverUI());
            store.dispatch(SurveyActions.getAssignedSurveys());
            store.dispatch(IntegrationsActions.getIntegrations());
          });
      }
    });

    const isProduction = environmentService.get<EnvironmentName>("name") === "production";

    store
      .select(FromUser.selectUser)
      .pipe(
        skipWhile(user => !user),
        first(),
      )
      .subscribe(async user => {
        if (user) {
          let trackingPermission: AppTrackingStatusResponse;

          if (platform.is("ios")) {
            trackingPermission = await AppTrackingTransparency.getStatus();

            if (trackingPermission.status === "notDetermined") {
              trackingPermission = await AppTrackingTransparency.requestPermission();
            }
          }

          const canTrackUser = isProduction && (!platform.is("ios") || trackingPermission.status !== "denied");

          await analyticsService.setAnalyticsCollectionEnabled(canTrackUser);
          await analyticsService.setUserId(user.id);
          await pushNotificationService.register();

          store.dispatch(ReportActions.getReportDataDefinitions());
          store.dispatch(UserActions.loadUser());
          store.dispatch(IntegrationsActions.getIntegrations());
          store.dispatch(AppActions.getLatestVersion({ platform: appPlatform }));
        }
      });
  };
