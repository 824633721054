import { createAction, props } from "@ngrx/store";
import {
  ActionButton,
  AuthControllerGetResetPasswordTokenRequestParams,
  AuthControllerLoginRequestParams,
  AuthControllerRegisterRequestParams,
  AuthControllerResetPasswordRequestParams,
  CheckEmailExistsResponseDto,
  PopoverUIDto,
  UserDto,
} from "@api";

interface CheckEmailSuccessProps extends CheckEmailExistsResponseDto {
  email: string;
}

export const setAccessToken = createAction("[USER] Set Access Token", props<{ accessToken: string }>());
export const setRefreshToken = createAction("[USER] Set Refresh Token", props<{ refreshToken: string }>());

export const checkEmailSuccess = createAction("[USER] Check Email Success", props<CheckEmailSuccessProps>());
export const checkEmailFailure = createAction("[USER] Check Email Failure", props<{ reason: string }>());

export const register = createAction("[USER] Register", props<AuthControllerRegisterRequestParams>());
export const registerSuccess = createAction("[USER] Register Success", props<AuthControllerRegisterRequestParams>());
export const registerFailure = createAction("[USER] Register Failure", props<{ reason: string }>());

export const confirmRegistration = createAction("[USER] Confirm Registration");
export const confirmRegistrationSuccess = createAction("[USER] Confirm Registration Success");
export const confirmRegistrationFailure = createAction("[USER] Confirm Registration Failure", props<{ reason: string }>());

export const login = createAction("[USER] Login", props<AuthControllerLoginRequestParams>());
export const loginSuccess = createAction("[USER] Login Success");
export const loginFailure = createAction("[USER] Login Failure", props<{ status: number }>());

export const requestResetPassword = createAction(
  "[USER] Request Reset Password",
  props<AuthControllerGetResetPasswordTokenRequestParams>(),
);
export const requestResetPasswordSuccess = createAction("[USER] Request Reset Password Success");
export const requestResetPasswordFailure = createAction(
  "[USER] Request Reset Password Failure",
  props<{ reason: string; email: string }>(),
);
export const resetPassword = createAction("[USER] Reset Password", props<AuthControllerResetPasswordRequestParams>());
export const resetPasswordSuccess = createAction("[USER] Reset Password Success");
export const resetPasswordFailure = createAction("[USER] Reset Password Failure", props<{ reason: string }>());

export const loadUser = createAction("[USER] Load User");
export const loadUserSuccess = createAction("[USER] Load User Success", props<{ user: UserDto }>());
export const loadUserFailure = createAction("[USER] Load User Failure", props<{ reason: string }>());

export const loadCustomer = createAction("[USER] Load Customer");
export const loadCustomerSuccess = createAction("[USER] Load Customer Success", props<{ customer: any }>());
export const loadCustomerFailure = createAction("[USER] Load Customer Failure", props<{ reason: string }>());

export const getActionButton = createAction("[USER] Get Action Button");
export const getActionButtonSuccess = createAction("[USER] Get Action Button Success", props<{ actionButton: ActionButton }>());
export const getActionButtonFailure = createAction("[USER] Get Action Button Failure", props<{ reason: string }>());

export const getPopoverUI = createAction("[USER] Get Popover UI");
export const getPopoverUISuccess = createAction("[USER] Get Popover UI Success", props<{ popoverUI: PopoverUIDto }>());
export const getPopoverUIFailure = createAction("[USER] Get Popover UI Failure", props<{ reason: string }>());

export const markPopoverUIAsSeen = createAction("[USER] Mark Popover UI As Seen", props<{ popoverId: string }>());
export const markPopoverUIAsSeenSuccess = createAction("[USER] Mark Popover UI As Seen Success");
export const markPopoverUIAsSeenFailure = createAction("[USER] Mark Popover UI As Seen Failure", props<{ reason: string }>());

export const getUserModules = createAction("[USER] Get User Modules");
export const getUserModulesSuccess = createAction("[USER] Get User Modules Success", props<{ moduleIds: string[] }>());
export const getUserModulesFailure = createAction("[USER] Get User Modules Failure", props<{ reason: string }>());

export const getUserChallenges = createAction("[USER] Get User Challenges");
export const getUserChallengesSuccess = createAction("[USER] Get User Challenges Success", props<{ challengeIds: string[] }>());
export const getUserChallengesFailure = createAction("[USER] Get User Challenges Failure", props<{ reason: string }>());

export const getCompletedSteps = createAction("[USER] Get Completed Steps");
export const getCompletedStepsSuccess = createAction("[USER] Get Completed Steps Success", props<{ chapterStepIds: string[] }>());
export const getCompletedStepsFailure = createAction("[USER] Get Completed Steps Failure", props<{ reason: string }>());

export const logout = createAction("[USER] Logout");
export const logoutSuccess = createAction("[USER] Logout Success");
export const logoutFailure = createAction("[USER] Logout Failure", props<{ reason: string }>());

export const reset = createAction("[USER] Reset");
export const resetSuccess = createAction("[USER] Reset Success");
export const resetFailure = createAction("[USER] Reset Failure", props<{ reason: string }>());
