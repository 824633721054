import { Pipe, PipeTransform } from "@angular/core";
import { CreateReportDto, SurveyFieldDto } from "@api";
import { validateSurveyResponseValue } from "src/app/utils/validate-survey-response";

@Pipe({ name: "isValidReport" })
export class IsValidReportPipe implements PipeTransform {
  public transform(fields: SurveyFieldDto[], responses: CreateReportDto[]): boolean {
    return fields.every(field => this.isValidField(field, responses));
  }

  private isValidField(field: SurveyFieldDto, responses: CreateReportDto[]): boolean {
    const response = responses.find(r => r.dataDefinitionId === field.dataDefinitionId);
    const { error } = validateSurveyResponseValue(field, response?.value?.toString());
    return !error;
  }
}
