import { Component, Input } from "@angular/core";
import { PopoverUIDto } from "@api";
import { ModalController } from "@ionic/angular";

@Component({
  selector: "app-popover-ui",
  templateUrl: "./popover-ui.page.html",
  styleUrls: ["./popover-ui.page.scss"],
})
export class PopoverUiPage {
  @Input() public popoverUI: PopoverUIDto;

  constructor(private modalController: ModalController) {}

  public dismiss(): void {
    this.modalController.dismiss();
  }
}
