import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IntegrationDto, IntegrationsService } from "@api";
import { NavController, ToastController } from "@ionic/angular";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action, Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { Observable, of } from "rxjs";
import { catchError, map, mergeMap, tap } from "rxjs/operators";
import { AppRoutes, NO_GOOGLE_REVIEWS_INTEGRATION_ACTION_BUTTON, TOAST_DEFAULT_DURATION } from "src/app/constants";
import { RootState } from "..";
import * as IntegrationActions from "./actions";
import * as UserActions from "src/app/store/user/actions";

@Injectable()
export class IntegrationEffects {
  public getIntegrations$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(IntegrationActions.getIntegrations),
      mergeMap(() =>
        this.integrationService.integrationControllerGetIntegrations().pipe(
          map(integrations => IntegrationActions.getIntegrationsSuccess({ integrations })),
          catchError((error: HttpErrorResponse) => of(IntegrationActions.getIntegrationsFailure({ reason: error.error?.message }))),
        ),
      ),
    ),
  );

  public getIntegrationsSuccess$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(IntegrationActions.getIntegrationsSuccess),
        tap(({ integrations }) => {
          if (!integrations?.some(integration => integration.type === IntegrationDto.TypeEnum.GoogleReviews)) {
            this.store.dispatch(UserActions.getActionButtonSuccess({ actionButton: NO_GOOGLE_REVIEWS_INTEGRATION_ACTION_BUTTON }));
          }
        }),
      ),
    { dispatch: false },
  );

  public suggestIntegration$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(IntegrationActions.sendSuggestion),
      mergeMap(({ suggestIntegrationDto }) =>
        this.integrationService.integrationControllerSuggestIntegration({ suggestIntegrationDto }).pipe(
          map(() => IntegrationActions.sendSuggestionSuccess()),
          catchError((error: HttpErrorResponse) => of(IntegrationActions.sendSuggestionFailure({ reason: error.error?.message }))),
        ),
      ),
    ),
  );

  public suggestIntegrationSuccess$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(IntegrationActions.sendSuggestionSuccess),
        tap(async () => await this.showToast("INTEGRATIONS.SUGGESTION_SENT", "dark")),
      ),
    { dispatch: false },
  );

  public addIntegration$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(IntegrationActions.addIntegration),
      mergeMap(({ createIntegrationDto }) =>
        this.integrationService.integrationControllerCreateIntegration({ createIntegrationDto }).pipe(
          map(integration => IntegrationActions.addIntegrationSuccess({ integration })),
          catchError((error: HttpErrorResponse) => of(IntegrationActions.addIntegrationFailure({ reason: error.error?.message }))),
        ),
      ),
    ),
  );

  public addIntegrationSuccess$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(IntegrationActions.addIntegrationSuccess),
        tap(async () => {
          await this.showToast("INTEGRATIONS.SUCCESSFULLY_SAVED", "dark");
          await this.navController.navigateBack([AppRoutes.home]);
        }),
      ),
    { dispatch: false },
  );

  public showErrorToast$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(IntegrationActions.getIntegrationsFailure, IntegrationActions.addIntegrationFailure),
        tap(async ({ reason }) => this.showToast(reason)),
      ),
    { dispatch: false },
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<RootState>,
    private readonly integrationService: IntegrationsService,
    private readonly i18n: TranslateService,
    private readonly toastController: ToastController,
    private readonly navController: NavController,
  ) {}

  private async showToast(message: string, color: "danger" | "dark" = "danger"): Promise<void> {
    const toast = await this.toastController.create({
      color,
      message: this.i18n.instant((message || "DEFAULT_ERROR_MESSAGE").toString()),
      duration: TOAST_DEFAULT_DURATION,
    });
    await toast.present();
  }
}
