import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IsValidReportPipe } from "./is-valid-report.pipe";

@NgModule({
  declarations: [IsValidReportPipe],
  imports: [CommonModule],
  exports: [IsValidReportPipe],
})
export class IsValidReportModule {}
