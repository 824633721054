/* eslint-disable no-shadow */
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthService } from "@api";
import { Store } from "@ngrx/store";
import { Observable, throwError } from "rxjs";
import { catchError, first, mergeMap, switchMap } from "rxjs/operators";
import { NO_INTERCEPTOR_REQUESTS, NO_INTERCEPTOR_ROUTES } from "src/app/constants";
import { RootState } from "src/app/store";
import * as UserActions from "src/app/store/user/actions";
import * as SurveyActions from "src/app/store/survey/actions";
import * as FromUser from "src/app/store/user/selectors";

@Injectable({ providedIn: "root" })
export class AuthInterceptor implements HttpInterceptor {
  private refreshToken: string;

  constructor(private store: Store<RootState>, private authService: AuthService) {
    this.store.select(FromUser.selectRefreshToken).subscribe(token => (this.refreshToken = token));
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.store.select(FromUser.selectAccessToken).pipe(
      first(),
      switchMap(token => {
        if (token) req = req.clone({ setHeaders: { authorization: `Bearer ${token}` } });
        return next.handle(req).pipe(
          catchError((error: HttpErrorResponse) => {
            if (error.status >= 500) console.error(error);

            if (
              error.status === 401 &&
              !NO_INTERCEPTOR_ROUTES.some(route => req.url.includes(route)) &&
              !NO_INTERCEPTOR_REQUESTS.some(request => req.url.includes(request))
            ) {
              if (!!this.refreshToken) {
                return this.authService.authControllerRefresh({ refresh: { refreshToken: this.refreshToken } }).pipe(
                  mergeMap(tokens => {
                    this.store.dispatch(UserActions.setAccessToken({ accessToken: tokens.accessToken }));
                    this.store.dispatch(UserActions.setRefreshToken({ refreshToken: tokens.refreshToken }));
                    this.store.dispatch(UserActions.loadUser());
                    this.store.dispatch(UserActions.loadCustomer());
                    this.store.dispatch(UserActions.getActionButton());
                    this.store.dispatch(UserActions.getPopoverUI());
                    this.store.dispatch(SurveyActions.getAssignedSurveys());
                    return next.handle(req.clone({ setHeaders: { authorization: `Bearer ${tokens.accessToken}` } }));
                  }),
                  catchError((e: any) => {
                    console.error(error);
                    this.store.dispatch(UserActions.logout());
                    return throwError(e);
                  }),
                );
              }
              return throwError(error);
            }
            return throwError(error);
          }),
        );
      }),
      catchError(error => throwError(error)),
    );
  }
}
