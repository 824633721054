import { Component, Input, OnInit } from "@angular/core";
import { EventContentDto, EventDto, UserDto } from "@api";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { AppRoutes } from "src/app/constants";
import { RootState } from "src/app/store";
import * as FromUser from "src/app/store/user/selectors";
import { AlertController, ModalController } from "@ionic/angular";
import { Browser } from "@capacitor/browser";
import { IframeModal } from "src/app/modals/iframe-modal/iframe-modal.page";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-event",
  templateUrl: "./event.page.html",
  styleUrls: ["./event.page.scss"],
})
export class EventPage implements OnInit {
  @Input() public event: EventDto;

  public routes: typeof AppRoutes = AppRoutes;
  public modal: any;
  public checkUpType: EventContentDto.TypeEnum = EventContentDto.TypeEnum.CheckUp;
  public user$: Observable<UserDto> = this.store.select(FromUser.selectUser);

  constructor(
    private store: Store<RootState>,
    private modalController: ModalController,
    private alertController: AlertController,
    private i18n: TranslateService,
  ) {}

  public async ngOnInit(): Promise<void> {
    this.modal = await this.modalController.getTop();
  }

  public dismiss(): void {
    this.modalController.dismiss();
  }

  public async rescheduleCheckUp(event: EventDto): Promise<void> {
    const url = event.externalLink;

    if (!url) {
      const alert = await this.alertController.create({
        header: this.i18n.instant("EVENT_RESPONSE.RESCHEDULE_ALERT_HEADER"),
        message: this.i18n.instant("EVENT_RESPONSE.RESCHEDULE_ALERT_MESSAGE"),
        buttons: [{ role: "cancel", text: this.i18n.instant("EVENT_RESPONSE.RESCHEDULE_ALERT_BUTTON") }],
      });

      return alert.present();
    }

    const modal = await this.modalController.create({
      component: IframeModal,
      componentProps: { url: event.externalLink },
      presentingElement: await this.modalController.getTop(),
      cssClass: ["event-reschedule-modal"],
      swipeToClose: true,
    });

    await modal.present();
  }

  public joinEvent(): void {
    Browser.open({ url: this.event.location });
  }
}
