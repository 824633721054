import { InjectionToken } from "@angular/core";
import { ActionButtonDto } from "@api";

/* eslint-disable @typescript-eslint/naming-convention */
export enum AppRoutes {
  add = "add",
  adminModules = "admin-modules",
  adminSurveyDefinitions = "admin-survey-definitions",
  askQuestion = "ask-question",
  bookCheckUp = "book-check-up",
  bwaReport = "bwa-report",
  challengeChecklist = "challenge-checklist",
  challengeLocked = "challenge-locked",
  challengeOnboarding = "challenge-onboarding",
  challenges = "challenges",
  challengeStep = "challenge-step",
  chapters = "chapters",
  companyProfile = "company-profile",
  confirmRegistration = "confirm-registration",
  createOrganization = "create-organization",
  detail = "detail",
  domainAddress = "domain-address",
  edit = "edit",
  eventContents = "event-contents",
  events = "events",
  eventsLayout = "events-layout",
  formBWA = "form-bwa",
  googleReviews = "google-reviews",
  helloCash = "wow-kasse",
  helpCenter = "help-center",
  history = "history",
  home = "home",
  integrations = "integrations",
  kajabi = "lernmodule",
  learningModules = "learning-modules",
  login = "login",
  logo = "logo",
  news = "news",
  notifications = "notifications",
  postponeCheckUp = "postpone-check-up",
  profile = "profile",
  register = "register",
  registerPOS = "register-pos",
  reportForm = "report-form",
  reports = "reports",
  resetDone = "reset-done",
  resetPassword = "reset-password",
  scenarios = "scenarios",
  settings = "settings",
  steps = "steps",
  success = "success",
  successReport = "success-report",
  summary = "summary",
  surveyFields = "survey-fields",
  surveys = "surveys",
  webBuilder = "web-builder",
  webinar = "webinar",
  welcome = "welcome",
}

export enum RouteParams {
  challengeId = "challengeId",
  chapterId = "chapterId",
  contentId = "contentId",
  eventId = "eventId",
  moduleId = "moduleId",
  reportId = "reportId",
  scenarioId = "scenarioId",
  stepId = "stepId",
  surveySlug = "surveySlug",
  surveyFieldId = "surveyFieldId",
}

export enum QueryParams {
  month = "month",
  refreshToken = "refresh_token",
  surveySlug = "survey_slug",
  year = "year",
}

export enum LocalStorage {
  activeEnvironment = "activeEnvironment",
}

export enum Miliseconds {
  OneMinute = 60000,
  TenMinutes = 600000,
  OneHour = 3600000,
}

export const REGEX = {
  phoneNumber: /\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}/g,
};

export const DEFAULT_LANGUAGE = "de";

export const TOAST_DEFAULT_DURATION = 6000;

export const EVENT_CONTENTS_PER_PAGE = 200;

export const EVENTS_PER_PAGE = 20;

export const NEWS_PER_PAGE = 20;

export const CHANCE_NODES_PER_PAGE = 20;

export const RUNS_PER_PAGE = 50;

export const LOGS_PER_PAGE = 100;

export const MIN_DESKTOP_VIEW = 1200;

export const NO_INTERCEPTOR_ROUTES: AppRoutes[] = [AppRoutes.login];

export const NO_INTERCEPTOR_REQUESTS: string[] = ["refresh", "unregister"];

export const APP_RELEASE = new InjectionToken<string>("APP-RELEASE");

export const DEFAULT_ACTION_BUTTON: ActionButtonDto = {
  actionLink: `/${AppRoutes.kajabi}`,
  actionText: "jetzt starten!",
  createdAt: new Date().toISOString(),
  id: "-",
  type: "-",
  imageUrl: "assets/challenge/challenge.jpg",
  title: "Zu den <br />Lernmodulen",
  updatedAt: new Date().toISOString(),
  force: false,
  message: "",
  userId: "-",
};

export const NO_GOOGLE_REVIEWS_INTEGRATION_ACTION_BUTTON: ActionButtonDto = {
  actionLink: `/${AppRoutes.integrations}/${AppRoutes.googleReviews}`,
  actionText: "jetzt starten!",
  createdAt: new Date().toISOString(),
  id: "-",
  type: "-",
  imageUrl: "assets/challenge/google-reviews.jpg",
  title: "Mit Google verbinden",
  updatedAt: new Date().toISOString(),
  force: true,
  message: "",
  userId: "-",
};

export const REGEXP = {
  DATE: /^((\d\d)(([02468][048])|([13579][26]))-02-29)|(((\d\d)(\d\d)))-(((0\d)|(1[0-2]))-((0\d)|(1\d)|(2[0-8])))|((((0[13578])|(1[02]))-31)$)/, // eslint-disable-line max-len
  TIME: /^(([0-1]{0,1}[0-9])|(2[0-3])):[0-5]{0,1}[0-9]$/,
};

export const successFace = "https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/144/apple/285/grinning-face_1f600.png";
export const warningFace = "https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/144/apple/285/flushed-face_1f633.png";
export const dangerFace = "https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/144/apple/285/weary-face_1f629.png";

export const CDK_EDITOR_CONFIG = {
  removePlugins: ["MediaEmbed"],
};
