import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LearningModulesService } from "@api";
import { NavController, ToastController } from "@ionic/angular";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { Observable, of } from "rxjs";
import { catchError, map, mergeMap, tap } from "rxjs/operators";
import { AppRoutes, TOAST_DEFAULT_DURATION } from "src/app/constants";
import * as LearningModuleActions from "src/app/store/learning-module/actions";

@Injectable()
export class LearningModuleEffects {
  public getLearningModules$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LearningModuleActions.getLearningModules),
      mergeMap(() =>
        this.learningModuleService.learningModuleControllerGetLearningModules().pipe(
          map(learningModules => LearningModuleActions.getLearningModulesSuccess({ learningModules })),
          catchError((error: HttpErrorResponse) => of(LearningModuleActions.getLearningModulesFailure({ reason: error.error?.message }))),
        ),
      ),
    ),
  );

  public getLearningModule$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LearningModuleActions.getLearningModule),
      mergeMap(({ learningModuleId }) =>
        this.learningModuleService.learningModuleControllerGetLearningModule({ learningModuleId }).pipe(
          map(learningModule => LearningModuleActions.getLearningModuleSuccess({ learningModule })),
          catchError((error: HttpErrorResponse) => of(LearningModuleActions.getLearningModulesFailure({ reason: error.error?.message }))),
        ),
      ),
    ),
  );

  public getLearningModuleChapters$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LearningModuleActions.getLearningModuleChapters),
      mergeMap(({ learningModuleId }) =>
        this.learningModuleService.learningModuleControllerGetLearningModuleChapters({ learningModuleId }).pipe(
          map(chapters => LearningModuleActions.getLearningModuleChaptersSuccess({ chapters })),
          catchError((error: HttpErrorResponse) =>
            of(LearningModuleActions.getLearningModuleChaptersFailure({ reason: error.error?.message })),
          ),
        ),
      ),
    ),
  );

  public createLearningModule$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LearningModuleActions.createLearningModule),
      mergeMap(({ createLearningModuleDto }) =>
        this.learningModuleService.learningModuleControllerCreateLearningModule({ createLearningModuleDto }).pipe(
          map(learningModule => LearningModuleActions.createLearningModuleSuccess({ learningModule })),
          catchError((error: HttpErrorResponse) => of(LearningModuleActions.createLearningModuleFailure({ reason: error.error?.message }))),
        ),
      ),
    ),
  );

  public createLearningModuleSuccess$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LearningModuleActions.createLearningModuleSuccess),
        tap(({ learningModule }) => this.navController.navigateForward([AppRoutes.adminModules, learningModule.id])),
      ),
    { dispatch: false },
  );

  public updateLearningModule$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LearningModuleActions.updateLearningModule),
      mergeMap(({ updateLearningModuleDto, learningModuleId, goBack }) =>
        this.learningModuleService.learningModuleControllerUpdateLearningModule({ updateLearningModuleDto, learningModuleId }).pipe(
          map(learningModule => LearningModuleActions.updateLearningModuleSuccess({ learningModule, goBack })),
          catchError((error: HttpErrorResponse) => of(LearningModuleActions.updateLearningModuleFailure({ reason: error.error?.message }))),
        ),
      ),
    ),
  );

  public updateLearningModuleSuccess$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LearningModuleActions.updateLearningModuleSuccess),
        tap(({ learningModule, goBack }) => goBack && this.navController.navigateBack([AppRoutes.adminModules, learningModule.id])),
      ),
    { dispatch: false },
  );

  public deleteLearningModule$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LearningModuleActions.deleteLearningModule),
      mergeMap(({ learningModuleId }) =>
        this.learningModuleService.learningModuleControllerDeleteLearningModule({ learningModuleId }).pipe(
          map(() => LearningModuleActions.deleteLearningModuleSuccess({ learningModuleId })),
          catchError((error: HttpErrorResponse) =>
            of(LearningModuleActions.deleteLearningModuleFailure({ reason: error?.error?.message })),
          ),
        ),
      ),
    ),
  );

  public deleteLearningModuleSuccess$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LearningModuleActions.deleteLearningModuleSuccess),
        tap(() => this.navController.navigateBack([AppRoutes.adminModules])),
      ),
    { dispatch: false },
  );

  public getChapterChallenges$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LearningModuleActions.getChapterChallenges),
      mergeMap(({ learningModuleId, chapterId }) =>
        this.learningModuleService.learningModuleControllerGetChapterChallenges({ learningModuleId, chapterId }).pipe(
          map(challenges => LearningModuleActions.getChapterChallengesSuccess({ challenges })),
          catchError((error: HttpErrorResponse) =>
            of(LearningModuleActions.getChapterChallengesFailure({ reason: error?.error?.message })),
          ),
        ),
      ),
    ),
  );

  public getChapterChallengesCount$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LearningModuleActions.getChapterChallengesCount),
      mergeMap(({ learningModuleId, chapterId }) =>
        this.learningModuleService.learningModuleControllerGetChapterChallengesCount({ learningModuleId, chapterId }).pipe(
          map(challenges => LearningModuleActions.getChapterChallengesCountSuccess({ chapterId, challenges })),
          catchError((error: HttpErrorResponse) =>
            of(LearningModuleActions.getChapterChallengesCountFailure({ reason: error?.error?.message })),
          ),
        ),
      ),
    ),
  );

  public getChapter$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LearningModuleActions.getChapter),
      mergeMap(({ learningModuleId, chapterId }) =>
        this.learningModuleService.learningModuleControllerGetChapterById({ learningModuleId, chapterId }).pipe(
          map(chapter => LearningModuleActions.getChapterSuccess({ chapter })),
          catchError((error: HttpErrorResponse) => of(LearningModuleActions.getChapterFailure({ reason: error?.error?.message }))),
        ),
      ),
    ),
  );

  public createChapter$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LearningModuleActions.createChapter),
      mergeMap(({ learningModuleId, createChapterDto }) =>
        this.learningModuleService.learningModuleControllerCreateChapter({ learningModuleId, createChapterDto }).pipe(
          map(chapter => LearningModuleActions.createChapterSuccess({ learningModuleId, chapter })),
          catchError((error: HttpErrorResponse) => of(LearningModuleActions.createChapterFailure({ reason: error?.error?.message }))),
        ),
      ),
    ),
  );

  public createChapterSuccess$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LearningModuleActions.createChapterSuccess),
        tap(({ learningModuleId, chapter }) =>
          this.navController.navigateForward([AppRoutes.adminModules, learningModuleId, AppRoutes.chapters, chapter.id]),
        ),
      ),
    { dispatch: false },
  );

  public updateChapter$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LearningModuleActions.updateChapter),
      mergeMap(({ learningModuleId, chapterId, updateChapterDto, goBack }) =>
        this.learningModuleService.learningModuleControllerUpdateChapter({ learningModuleId, chapterId, updateChapterDto }).pipe(
          map(chapter => LearningModuleActions.updateChapterSuccess({ learningModuleId, chapter, goBack })),
          catchError((error: HttpErrorResponse) => of(LearningModuleActions.updateChapterFailure({ reason: error?.error?.message }))),
        ),
      ),
    ),
  );

  public updateChapterSuccess$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LearningModuleActions.updateChapterSuccess),
        tap(
          ({ learningModuleId, chapter, goBack }) =>
            goBack && this.navController.navigateBack([AppRoutes.adminModules, learningModuleId, AppRoutes.chapters, chapter.id]),
        ),
      ),
    { dispatch: false },
  );

  public deleteChapter$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LearningModuleActions.deleteChapter),
      mergeMap(({ learningModuleId, chapterId, goBack }) =>
        this.learningModuleService.learningModuleControllerDeleteChapter({ learningModuleId, chapterId }).pipe(
          map(() => LearningModuleActions.deleteChapterSuccess({ learningModuleId, chapterId, goBack })),
          catchError((error: HttpErrorResponse) => of(LearningModuleActions.deleteChapterFailure({ reason: error?.error?.message }))),
        ),
      ),
    ),
  );

  public deleteChapterSuccess$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LearningModuleActions.deleteChapterSuccess),
        tap(
          ({ learningModuleId, chapterId, goBack }) =>
            goBack && this.navController.navigateBack([AppRoutes.adminModules, learningModuleId, AppRoutes.chapters, chapterId]),
        ),
      ),
    { dispatch: false },
  );

  public getChallenge$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LearningModuleActions.getChallenge),
      mergeMap(({ learningModuleId, chapterId, challengeId }) =>
        this.learningModuleService.learningModuleControllerGetChallengeById({ learningModuleId, chapterId, challengeId }).pipe(
          map(challenge => LearningModuleActions.getChallengeSuccess({ challenge })),
          catchError((error: HttpErrorResponse) => of(LearningModuleActions.getChallengeFailure({ reason: error?.error?.message }))),
        ),
      ),
    ),
  );

  public createChallenge$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LearningModuleActions.createChallenge),
      mergeMap(({ learningModuleId, chapterId, createChallengeDto }) =>
        this.learningModuleService.learningModuleControllerCreateChallenge({ learningModuleId, chapterId, createChallengeDto }).pipe(
          map(challenge => LearningModuleActions.createChallengeSuccess({ learningModuleId, chapterId, challenge })),
          catchError((error: HttpErrorResponse) => of(LearningModuleActions.createChallengeFailure({ reason: error?.error?.message }))),
        ),
      ),
    ),
  );

  public createChallengeSuccess$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LearningModuleActions.createChallengeSuccess),
        tap(({ learningModuleId, chapterId, challenge }) => {
          this.navController.navigateForward([
            AppRoutes.adminModules,
            learningModuleId,
            AppRoutes.chapters,
            chapterId,
            AppRoutes.challenges,
            challenge.id,
          ]);
        }),
      ),
    { dispatch: false },
  );

  public updateChallenge$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LearningModuleActions.updateChallenge),
      mergeMap(({ learningModuleId, chapterId, challengeId, updateChallengeDto, goBack }) =>
        this.learningModuleService
          .learningModuleControllerUpdateChallenge({ learningModuleId, chapterId, challengeId, updateChallengeDto })
          .pipe(
            map(challenge => LearningModuleActions.updateChallengeSuccess({ learningModuleId, chapterId, challenge, goBack })),
            catchError((error: HttpErrorResponse) => of(LearningModuleActions.updateChallengeFailure({ reason: error?.error?.message }))),
          ),
      ),
    ),
  );

  public updateChallengeSuccess$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LearningModuleActions.updateChallengeSuccess),
        tap(
          ({ learningModuleId, chapterId, challenge, goBack }) =>
            goBack &&
            this.navController.navigateBack([
              AppRoutes.adminModules,
              learningModuleId,
              AppRoutes.chapters,
              chapterId,
              AppRoutes.challenges,
              challenge.id,
            ]),
        ),
      ),
    { dispatch: false },
  );

  public deleteChallenge$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LearningModuleActions.deleteChallenge),
      mergeMap(({ learningModuleId, chapterId, challengeId, goBack }) =>
        this.learningModuleService.learningModuleControllerDeleteChallenge({ learningModuleId, chapterId, challengeId }).pipe(
          map(() => LearningModuleActions.deleteChallengeSuccess({ learningModuleId, chapterId, challengeId, goBack })),
          catchError((error: HttpErrorResponse) => of(LearningModuleActions.deleteChallengeFailure({ reason: error?.error?.message }))),
        ),
      ),
    ),
  );

  public deleteChallengeSuccess$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LearningModuleActions.deleteChallengeSuccess),
        tap(
          ({ learningModuleId, chapterId, goBack }) =>
            goBack && this.navController.navigateBack([AppRoutes.adminModules, learningModuleId, AppRoutes.chapters, chapterId]),
        ),
      ),
    { dispatch: false },
  );

  public getChallengeSteps$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LearningModuleActions.getChallengeSteps),
      mergeMap(({ learningModuleId, chapterId, challengeId }) =>
        this.learningModuleService.learningModuleControllerGetChallengeSteps({ learningModuleId, chapterId, challengeId }).pipe(
          map(steps => LearningModuleActions.getChallengeStepsSuccess({ steps })),
          catchError((error: HttpErrorResponse) => of(LearningModuleActions.getChallengeStepsFailure({ reason: error?.error?.message }))),
        ),
      ),
    ),
  );

  public getChallengeStepsCount$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LearningModuleActions.getChallengeStepsCount),
      mergeMap(({ learningModuleId, chapterId, challengeId }) =>
        this.learningModuleService.learningModuleControllerGetChallengeStepsCount({ learningModuleId, chapterId, challengeId }).pipe(
          map(steps => LearningModuleActions.getChallengeStepsCountSuccess({ challengeId, steps })),
          catchError((error: HttpErrorResponse) =>
            of(LearningModuleActions.getChallengeStepsCountFailure({ reason: error?.error?.message })),
          ),
        ),
      ),
    ),
  );

  public getStep$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LearningModuleActions.getStep),
      mergeMap(({ learningModuleId, chapterId, challengeId, stepId }) =>
        this.learningModuleService.learningModuleControllerGetStep({ learningModuleId, chapterId, challengeId, stepId }).pipe(
          map(step => LearningModuleActions.getStepSuccess({ step })),
          catchError((error: HttpErrorResponse) => of(LearningModuleActions.getStepFailure({ reason: error?.error?.message }))),
        ),
      ),
    ),
  );

  public createStep$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LearningModuleActions.createStep),
      mergeMap(({ learningModuleId, chapterId, challengeId, createStepDto }) =>
        this.learningModuleService.learningModuleControllerCreateStep({ learningModuleId, chapterId, challengeId, createStepDto }).pipe(
          map(step => LearningModuleActions.createStepSuccess({ learningModuleId, challengeId, chapterId, step })),
          catchError((error: HttpErrorResponse) => of(LearningModuleActions.createStepFailure({ reason: error?.error?.message }))),
        ),
      ),
    ),
  );

  public createStepSuccess$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LearningModuleActions.createStepSuccess),
        tap(({ learningModuleId, challengeId, chapterId, step }) =>
          this.navController.navigateForward([
            AppRoutes.adminModules,
            learningModuleId,
            AppRoutes.chapters,
            chapterId,
            AppRoutes.challenges,
            challengeId,
            AppRoutes.steps,
            step.id,
          ]),
        ),
      ),
    { dispatch: false },
  );

  public updateStep$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LearningModuleActions.updateStep),
      mergeMap(({ learningModuleId, stepId, challengeId, chapterId, updateStepDto, shouldNavigate }) =>
        this.learningModuleService
          .learningModuleControllerUpdateStep({ learningModuleId, challengeId, chapterId, stepId, updateStepDto })
          .pipe(
            map(step => LearningModuleActions.updateStepSuccess({ learningModuleId, chapterId, challengeId, step, shouldNavigate })),
            catchError((error: HttpErrorResponse) => of(LearningModuleActions.updateStepFailure({ reason: error?.error?.message }))),
          ),
      ),
    ),
  );

  public updateStepSuccess$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LearningModuleActions.updateStepSuccess),
        tap(
          ({ learningModuleId, challengeId, chapterId, step, shouldNavigate }) =>
            shouldNavigate &&
            this.navController.navigateBack([
              AppRoutes.adminModules,
              learningModuleId,
              AppRoutes.chapters,
              chapterId,
              AppRoutes.challenges,
              challengeId,
              AppRoutes.steps,
              step.id,
            ]),
        ),
      ),
    { dispatch: false },
  );

  public deleteStep$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LearningModuleActions.deleteStep),
      mergeMap(({ learningModuleId, chapterId, challengeId, stepId, goBack }) =>
        this.learningModuleService.learningModuleControllerDeleteStep({ learningModuleId, chapterId, challengeId, stepId }).pipe(
          map(() => LearningModuleActions.deleteStepSuccess({ learningModuleId, chapterId, challengeId, stepId, goBack })),
          catchError((error: HttpErrorResponse) => of(LearningModuleActions.deleteStepFailure({ reason: error?.error?.message }))),
        ),
      ),
    ),
  );

  public deleteStepSuccess$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LearningModuleActions.deleteStepSuccess),
        tap(
          ({ learningModuleId, challengeId, chapterId, goBack }) =>
            goBack &&
            this.navController.navigateBack([
              AppRoutes.adminModules,
              learningModuleId,
              AppRoutes.chapters,
              chapterId,
              AppRoutes.challenges,
              challengeId,
            ]),
        ),
      ),
    { dispatch: false },
  );

  public markStepAsCompleted$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LearningModuleActions.markStepAsCompleted),
      mergeMap(({ learningModuleId, chapterId, challengeId, stepId }) =>
        this.learningModuleService.learningModuleControllerMarkStepAsCompleted({ learningModuleId, chapterId, challengeId, stepId }).pipe(
          map(() => LearningModuleActions.markStepAsCompletedSuccess({ stepId })),
          catchError((error: HttpErrorResponse) => of(LearningModuleActions.markStepAsCompletedFailure({ reason: error?.error?.message }))),
        ),
      ),
    ),
  );

  public showErrorToast$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          LearningModuleActions.getLearningModulesFailure,
          LearningModuleActions.getLearningModuleFailure,
          LearningModuleActions.getLearningModuleChaptersFailure,
          LearningModuleActions.createLearningModuleFailure,
          LearningModuleActions.updateLearningModuleFailure,
          LearningModuleActions.deleteLearningModuleFailure,
          LearningModuleActions.createChallengeFailure,
          LearningModuleActions.updateChallengeFailure,
          LearningModuleActions.deleteChallengeFailure,
          LearningModuleActions.getChallengeStepsFailure,
          LearningModuleActions.getChallengeStepsCountFailure,
          LearningModuleActions.getChapterChallengesFailure,
          LearningModuleActions.getChapterChallengesCountFailure,
          LearningModuleActions.createChapterFailure,
          LearningModuleActions.getStepFailure,
          LearningModuleActions.createStepFailure,
          LearningModuleActions.updateStepFailure,
          LearningModuleActions.deleteStepFailure,
          LearningModuleActions.markStepAsCompletedFailure,
        ),
        tap(async ({ reason }) => this.showToast(reason)),
      ),
    { dispatch: false },
  );

  constructor(
    private readonly actions$: Actions,
    private readonly learningModuleService: LearningModulesService,
    private readonly i18n: TranslateService,
    private readonly toastController: ToastController,
    private readonly navController: NavController,
  ) {}

  private async showToast(message: string, color: "danger" | "dark" = "danger"): Promise<void> {
    const toast = await this.toastController.create({
      color,
      message: this.i18n.instant((message || "DEFAULT_ERROR_MESSAGE").toString()),
      duration: TOAST_DEFAULT_DURATION,
    });
    await toast.present();
  }
}
