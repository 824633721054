import { ToastController } from "@ionic/angular";
import { Injectable } from "@angular/core";
import { ActionPerformed, PushNotificationSchema, PushNotifications, Token } from "@capacitor/push-notifications";
import { Browser } from "@capacitor/browser";
import { Device } from "@capacitor/device";
import { Router } from "@angular/router";
import { AngularFireMessaging } from "@angular/fire/compat/messaging";
import { filter, first, mergeMapTo } from "rxjs/operators";
import { PushTokensService, UserDto } from "@api";
import { Observable } from "rxjs";
import { Store } from "@ngrx/store";
import { RootState } from "src/app/store";
import * as FromUser from "src/app/store/user/selectors";
import * as AppActions from "src/app/store/app/actions";
import { TOAST_DEFAULT_DURATION } from "src/app/constants";

@Injectable({ providedIn: "root" })
export class PushNotificationsService {
  private platform: "ios" | "android" | "web";
  private user$: Observable<UserDto> = this.store.select(FromUser.selectUser);

  constructor(
    private toastController: ToastController,
    private router: Router,
    private messagingService: AngularFireMessaging,
    private store: Store<RootState>,
    private pushTokenService: PushTokensService,
  ) {
    Device.getInfo().then(deviceInfo => {
      this.platform = deviceInfo.platform;
      if (this.platform === "web") return;

      PushNotifications.addListener("registration", this.registration.bind(this));
      PushNotifications.addListener("registrationError", this.registrationError.bind(this));
      PushNotifications.addListener("pushNotificationReceived", this.pushNotificationReceived.bind(this));
      PushNotifications.addListener("pushNotificationActionPerformed", this.pushNotificationActionPerformed.bind(this));
    });
  }

  public async register(): Promise<void> {
    this.platform = (await Device.getInfo()).platform;
    if (this.platform === "web") {
      this.messagingService.requestPermission
        .pipe(mergeMapTo(this.messagingService.tokenChanges))
        .subscribe(token => this.registration({ value: token }));
    } else {
      const { receive } = await PushNotifications.requestPermissions();
      if (receive === "granted") PushNotifications.register();
    }
  }

  public async clearBadge(): Promise<void> {
    this.platform = (await Device.getInfo()).platform;
    if (this.platform === "web") return;
    PushNotifications.removeAllDeliveredNotifications();
  }

  private registration({ value: token }: Token): void {
    this.user$
      .pipe(
        first(),
        filter(user => !!user),
      )
      .subscribe(user => {
        this.store.dispatch(AppActions.setDeviceToken({ token }));
        this.pushTokenService.pushTokenControllerRegisterPushToken({ registerPushTokenDto: { token, userId: user.id } }).toPromise();
      });
  }

  private async registrationError(error: any): Promise<void> {
    const toast = await this.toastController.create({
      message: JSON.stringify(error),
      color: "danger",
      duration: TOAST_DEFAULT_DURATION,
    });
    await toast.present();
  }

  private async pushNotificationReceived(notification: PushNotificationSchema): Promise<void> {
    const toast = await this.toastController.create({
      message: notification.title,
      buttons: notification.data.urlToOpen
        ? [
            {
              text: "View",
              handler: (): void => this.pushNotificationActionPerformed({ notification } as ActionPerformed),
            },
          ]
        : undefined,
      color: "primary",
      duration: TOAST_DEFAULT_DURATION,
    });
    toast.present();
  }

  private pushNotificationActionPerformed({ notification: { data } }: ActionPerformed): void {
    if (!data.urlToOpen) return;
    if (data.urlToOpen.startsWith("/")) this.router.navigateByUrl(data.urlToOpen);
    else Browser.open({ url: data.urlToOpen });
  }
}
