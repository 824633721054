<div class="white-wrapper">
  <div class="event">
    <div class="event__col">
      <div class="event__title">
        <span [innerHTML]="(event?.contentId | toEventContent | async)?.title"></span>
        <ion-button
          *ngIf="event | canShowJoinButton: (event | eventResponse | async)"
          class="event__join-button"
          fill="solid"
          [innerHTML]="'EVENT_DETAIL_COMPONENT.WEBINAR' | translate"
          (click)="joinEvent.emit()"
        ></ion-button>
      </div>
      <div class="event__description">
        <span class="event__description__label" [innerHTML]="'EVENT_CONTENT_FORM.DESCRIPTION' | translate"></span>
        <p class="event__description__text" [innerHTML]="(event?.contentId | toEventContent | async)?.description | linebreaks"></p>
        <img *ngIf="$any(event)?.private" class="event__description__state" src="assets/event/private-true.png" />
      </div>
      <div class="event__information">
        <div class="event__information__item">
          <span class="event__information__item__label" [innerHTML]="'EVENT_DETAIL_COMPONENT.DATE' | translate"></span>
          <span class="event__information__item__value" *ngIf="event?.date" [innerHTML]="event?.date | date: 'dd. MMMM YYYY HH:mm'"></span>
        </div>
        <div class="event__information__item">
          <span class="event__information__item__label" [innerHTML]="'EVENT_DETAIL_COMPONENT.REFERENT' | translate"></span>
          <span class="event__information__item__value" [innerHTML]="(event?.referentId | toReferent | async)?.name"></span>
        </div>
        <div class="event__information__item">
          <span class="event__information__item__label" [innerHTML]="'EVENT_DETAIL_COMPONENT.MODULE' | translate"></span>
          <span
            class="event__information__item__value"
            *ngFor="let module of (event?.contentId | toEventContent | async)?.learningModules"
            [innerHTML]="module.name"
          ></span>
        </div>
        <div class="event__information__item">
          <span class="event__information__item__label" [innerHTML]="'EVENT_DETAIL_COMPONENT.TYPE' | translate"></span>
          <span
            class="event__information__item__value"
            [innerHTML]="'EVENT_TYPE.' + (event?.contentId | toEventContent | async)?.type | translate"
          ></span>
        </div>
        <div class="event__information__item">
          <span class="event__information__item__label" [innerHTML]="'EVENT_DETAIL_COMPONENT.PRICE' | translate"></span>
          <span
            class="event__information__item__value"
            [innerHTML]="(event?.contentId | toEventContent | async)?.price | currency: 'EUR':'symbol'"
          ></span>
        </div>
      </div>

      <app-event-participants [event]="event" [modifier]="'--mobile'"></app-event-participants>
    </div>

    <div class="event__col">
      <img
        class="event__image"
        [src]="(event?.contentId | toEventContent | async)?.imageUrl || 'assets/icon/apple-touch-icon.png'"
        [alt]="(event?.contentId | toEventContent | async)?.title"
      />
      <app-event-participants [event]="event" [modifier]="'--desktop'"></app-event-participants>
    </div>
  </div>
</div>
