import { ActionDto, ChanceNodeDto, ChanceNodeLogsDto, ChanceNodeRunDto, ChanceNodeRunsDto, TriggerDto } from "@api";

export interface ChanceNodes {
  [key: string]: ChanceNodeDto;
}

export interface ScenarioRunLogs {
  [chanceNodeId: string]: { [runId: number]: ChanceNodeLogsDto };
}

export interface ScenarioRuns {
  [chanceNodeId: string]: ChanceNodeRunsDto;
}

export interface ScenarioState {
  actions: ActionDto[];
  chanceNodes: ChanceNodes;
  loading: boolean;
  logs: ScenarioRunLogs;
  runs: ScenarioRuns;
  triggers: TriggerDto[];
}

export const initialState: ScenarioState = {
  actions: [],
  chanceNodes: {},
  loading: false,
  logs: {},
  runs: {},
  triggers: [],
};

export const persistedKeys: string[] = ["triggers", "actions"];
