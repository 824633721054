import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { EventDetailComponent } from "./event-detail.component";
import { ToEventContentModule } from "src/app/pipes/to-event-content/to-event-content.module";
import { EventResponseModule } from "src/app/pipes/event-response/event-response.module";
import { CanShowJoinButtonModule } from "src/app/modals/event/pipes/can-show-join-button/can-show-join-button.module";
import { IonicModule } from "@ionic/angular";
import { ToReferentModule } from "src/app/pipes/to-referent/to-referent.module";
import { ColorSelectModule } from "src/app/components/color-select/color-select.module";
import { TranslateModule } from "@ngx-translate/core";
import { LinebreaksModule } from "src/app/pipes/linebreaks/linebreaks.module";
import { ToEventParticipantsPipeModule } from "src/app/pipes/to-event-participants/to-event-participants.module";
import { UsernamePipeModule } from "src/app/pipes/username/username.module";
import { RescheduleButtonComponentModule } from "src/app/components/reschedule-button/reschedule-button.module";
import { InitialsPipeModule } from "src/app/pipes/initials/initials.module";
import { EventParticipantsModule } from "../event-participants/event-participants.module";

@NgModule({
  declarations: [EventDetailComponent],
  imports: [
    IonicModule,
    CommonModule,
    ToEventContentModule,
    EventResponseModule,
    CanShowJoinButtonModule,
    ToReferentModule,
    ToEventParticipantsPipeModule,
    ColorSelectModule,
    RescheduleButtonComponentModule,
    LinebreaksModule,
    UsernamePipeModule,
    InitialsPipeModule,
    TranslateModule,
    EventParticipantsModule,
  ],
  exports: [EventDetailComponent],
})
export class EventDetailModule {}
