import {
  ChallengeDto,
  ChapterDto,
  LearningModuleControllerCreateChallengeRequestParams,
  LearningModuleControllerCreateChapterRequestParams,
  LearningModuleControllerCreateLearningModuleRequestParams,
  LearningModuleControllerCreateStepRequestParams,
  LearningModuleControllerDeleteChallengeRequestParams,
  LearningModuleControllerDeleteChapterRequestParams,
  LearningModuleControllerDeleteLearningModuleRequestParams,
  LearningModuleControllerDeleteStepRequestParams,
  LearningModuleControllerGetChallengeByIdRequestParams,
  LearningModuleControllerGetChallengeStepsCountRequestParams,
  LearningModuleControllerGetChallengeStepsRequestParams,
  LearningModuleControllerGetChapterByIdRequestParams,
  LearningModuleControllerGetChapterChallengesCountRequestParams,
  LearningModuleControllerGetChapterChallengesRequestParams,
  LearningModuleControllerGetLearningModuleChaptersRequestParams,
  LearningModuleControllerGetLearningModuleRequestParams,
  LearningModuleControllerGetStepRequestParams,
  LearningModuleControllerMarkStepAsCompletedRequestParams,
  LearningModuleControllerUpdateChallengeRequestParams,
  LearningModuleControllerUpdateChapterRequestParams,
  LearningModuleControllerUpdateLearningModuleRequestParams,
  LearningModuleControllerUpdateStepRequestParams,
  LearningModuleDto,
  StepDto,
} from "@api";
import { createAction, props } from "@ngrx/store";

export const setLoading = createAction("[LEARNING MODULES] Set Loading");
export const hideLoading = createAction("[LEARNING MODULES] Hide Loading");

// Learning Modules
export const getLearningModules = createAction("[LEARNING MODULES] Get Learning Modules");
export const getLearningModulesSuccess = createAction(
  "[LEARNING MODULES] Get Learning Modules Success",
  props<{ learningModules: LearningModuleDto[] }>(),
);
export const getLearningModulesFailure = createAction("[LEARNING MODULES] Get Learning Modules Failure", props<{ reason: string }>());

export const getLearningModule = createAction(
  "[LEARNING MODULE] Get Learning Module",
  props<LearningModuleControllerGetLearningModuleRequestParams>(),
);
export const getLearningModuleSuccess = createAction(
  "[LEARNING MODULE] Get Learning Module Success",
  props<{ learningModule: LearningModuleDto }>(),
);
export const getLearningModuleFailure = createAction("[LEARNING MODULE] Get Learning Module Failure", props<{ reason: string }>());

export const createLearningModule = createAction(
  "[LEARNING MODULE] Create Learning Module",
  props<LearningModuleControllerCreateLearningModuleRequestParams>(),
);
export const createLearningModuleSuccess = createAction(
  "[LEARNING MODULE] Create Learning Module Success",
  props<{ learningModule: LearningModuleDto }>(),
);
export const createLearningModuleFailure = createAction("[LEARNING MODULE] Create Learning Module Failure", props<{ reason: string }>());

export const updateLearningModule = createAction(
  "[LEARNING MODULE] Update Learning Module",
  props<LearningModuleControllerUpdateLearningModuleRequestParams & { goBack: boolean }>(),
);
export const updateLearningModuleSuccess = createAction(
  "[LEARNING MODULE] Update Learning Module Success",
  props<{ learningModule: LearningModuleDto; goBack: boolean }>(),
);
export const updateLearningModuleFailure = createAction("[LEARNING MODULE] Update Learning Module Failure", props<{ reason: string }>());

export const deleteLearningModule = createAction(
  "[LEARNING MODULE] Delete Learning Module",
  props<LearningModuleControllerDeleteLearningModuleRequestParams>(),
);
export const deleteLearningModuleSuccess = createAction(
  "[LEARNING MODULE] Delete Learning Module Success",
  props<LearningModuleControllerDeleteLearningModuleRequestParams>(),
);
export const deleteLearningModuleFailure = createAction("[LEARNING MODULE] Delete Learning Module Failure", props<{ reason: string }>());

// Chapters
export const getLearningModuleChapters = createAction(
  "[LEARNING MODULE] Get Learning Module Chapters",
  props<LearningModuleControllerGetLearningModuleChaptersRequestParams>(),
);
export const getLearningModuleChaptersSuccess = createAction(
  "[LEARNING MODULE] Get Learning Module Chapters Success",
  props<{ chapters: ChapterDto[] }>(),
);
export const getLearningModuleChaptersFailure = createAction(
  "[LEARNING MODULE] Get Learning Module Chapters Failure",
  props<{ reason: string }>(),
);

export const getChapter = createAction("[LEARNING MODULE] Get Chapter", props<LearningModuleControllerGetChapterByIdRequestParams>());
export const getChapterSuccess = createAction("[LEARNING MODULE] Get Chapter Success", props<{ chapter: ChapterDto }>());
export const getChapterFailure = createAction("[LEARNING MODULE] Get Chapter Failure", props<{ reason: string }>());

export const createChapter = createAction("[LEARNING MODULE] Create Chapter", props<LearningModuleControllerCreateChapterRequestParams>());
export const createChapterSuccess = createAction(
  "[LEARNING MODULE] Create Chapter Success",
  props<{ learningModuleId: string; chapter: ChapterDto }>(),
);
export const createChapterFailure = createAction("[LEARNING MODULE] Create Chapter Failure", props<{ reason: string }>());

export const updateChapter = createAction(
  "[LEARNING MODULE] Update Chapter",
  props<LearningModuleControllerUpdateChapterRequestParams & { goBack: boolean }>(),
);
export const updateChapterSuccess = createAction(
  "[LEARNING MODULE] Update Chapter Success",
  props<{ learningModuleId: string; chapter: ChapterDto; goBack: boolean }>(),
);
export const updateChapterFailure = createAction("[LEARNING MODULE] Update Chapter Failure", props<{ reason: string }>());

export const deleteChapter = createAction(
  "[LEARNING MODULE] Delete Chapter",
  props<LearningModuleControllerDeleteChapterRequestParams & { goBack: boolean }>(),
);
export const deleteChapterSuccess = createAction(
  "[LEARNING MODULE] Delete Chapter Success",
  props<{ learningModuleId: string; chapterId: string; goBack: boolean }>(),
);
export const deleteChapterFailure = createAction("[LEARNING MODULE] Delete Chapter Failure", props<{ reason: string }>());

// Challenges
export const getChapterChallenges = createAction(
  "[LEARNING MODULE] Get Chapter Challenges",
  props<LearningModuleControllerGetChapterChallengesRequestParams>(),
);
export const getChapterChallengesSuccess = createAction(
  "[LEARNING MODULE] Get Chapter Challenges Success",
  props<{ challenges: ChallengeDto[] }>(),
);
export const getChapterChallengesFailure = createAction("[LEARNING MODULE] Get Chapter Challenges Failure", props<{ reason: string }>());

export const getChapterChallengesCount = createAction(
  "[LEARNING MODULE] Get Chapter Challenges Count",
  props<LearningModuleControllerGetChapterChallengesCountRequestParams>(),
);
export const getChapterChallengesCountSuccess = createAction(
  "[LEARNING MODULE] Get Chapter Challenges Count Success",
  props<{ chapterId: string; challenges: number }>(),
);
export const getChapterChallengesCountFailure = createAction(
  "[LEARNING MODULE] Get Chapter Challenges Count Failure",
  props<{ reason: string }>(),
);

export const getChallenge = createAction("[LEARNING MODULE] Get Challenge", props<LearningModuleControllerGetChallengeByIdRequestParams>());
export const getChallengeSuccess = createAction("[LEARNING MODULE] Get Challenge Success", props<{ challenge: ChallengeDto }>());
export const getChallengeFailure = createAction("[LEARNING MODULE] Get Challenge Failure", props<{ reason: string }>());

export const createChallenge = createAction(
  "[LEARNING MODULE] Create Challenge",
  props<LearningModuleControllerCreateChallengeRequestParams>(),
);
export const createChallengeSuccess = createAction(
  "[LEARNING MODULE] Create Challenge Success",
  props<{ learningModuleId: string; chapterId: string; challenge: ChallengeDto }>(),
);
export const createChallengeFailure = createAction("[LEARNING MODULE] Create Challenge Failure", props<{ reason: string }>());

export const updateChallenge = createAction(
  "[LEARNING MODULE] Update Challenge",
  props<LearningModuleControllerUpdateChallengeRequestParams & { goBack: boolean }>(),
);
export const updateChallengeSuccess = createAction(
  "[LEARNING MODULE] Update Challenge Success",
  props<{ learningModuleId: string; chapterId: string; challenge: ChallengeDto; goBack: boolean }>(),
);
export const updateChallengeFailure = createAction("[LEARNING MODULE] Update Challenge Failure", props<{ reason: string }>());

export const deleteChallenge = createAction(
  "[LEARNING MODULE] Delete Challenge",
  props<LearningModuleControllerDeleteChallengeRequestParams & { goBack: boolean }>(),
);
export const deleteChallengeSuccess = createAction(
  "[LEARNING MODULE] Delete Challenge Success",
  props<{ learningModuleId: string; chapterId: string; challengeId: string; goBack: boolean }>(),
);
export const deleteChallengeFailure = createAction("[LEARNING MODULE] Delete Challenge Failure", props<{ reason: string }>());

// Steps
export const getChallengeSteps = createAction(
  "[LEARNING MODULE] Get Challenge Steps",
  props<LearningModuleControllerGetChallengeStepsRequestParams>(),
);
export const getChallengeStepsSuccess = createAction("[LEARNING MODULE] Get Challenge Steps Success", props<{ steps: StepDto[] }>());
export const getChallengeStepsFailure = createAction("[LEARNING MODULE] Get Challenge Steps Failure", props<{ reason: string }>());

export const getChallengeStepsCount = createAction(
  "[LEARNING MODULE] Get Challenge Steps Count",
  props<LearningModuleControllerGetChallengeStepsCountRequestParams>(),
);
export const getChallengeStepsCountSuccess = createAction(
  "[LEARNING MODULE] Get Challenge Steps Count Success",
  props<{ challengeId: string; steps: number }>(),
);
export const getChallengeStepsCountFailure = createAction(
  "[LEARNING MODULE] Get Challenge Steps Count Failure",
  props<{ reason: string }>(),
);

export const getStep = createAction("[LEARNING MODULE] Get Step", props<LearningModuleControllerGetStepRequestParams>());
export const getStepSuccess = createAction("[LEARNING MODULE] Get Step Success", props<{ step: StepDto }>());
export const getStepFailure = createAction("[LEARNING MODULE] Get Step Failure", props<{ reason: string }>());

export const createStep = createAction("[LEARNING MODULE] Create Step", props<LearningModuleControllerCreateStepRequestParams>());
export const createStepSuccess = createAction(
  "[LEARNING MODULE] Create Step Success",
  props<{ learningModuleId: string; challengeId: string; chapterId: string; step: StepDto }>(),
);
export const createStepFailure = createAction("[LEARNING MODULE] Create Step Failure", props<{ reason: string }>());

export const updateStep = createAction(
  "[LEARNING MODULE] Update Step",
  props<LearningModuleControllerUpdateStepRequestParams & { shouldNavigate: boolean }>(),
);
export const updateStepSuccess = createAction(
  "[LEARNING MODULE] Update Step Success",
  props<{ learningModuleId: string; challengeId: string; chapterId: string; step: StepDto; shouldNavigate: boolean }>(),
);
export const updateStepFailure = createAction("[LEARNING MODULE] Update Step Failure", props<{ reason: string }>());

export const deleteStep = createAction(
  "[LEARNING MODULE] Delete Step",
  props<LearningModuleControllerDeleteStepRequestParams & { goBack: boolean }>(),
);
export const deleteStepSuccess = createAction(
  "[LEARNING MODULE] Delete Step Success",
  props<LearningModuleControllerDeleteStepRequestParams & { goBack: boolean }>(),
);
export const deleteStepFailure = createAction("[LEARNING MODULE] Delete Step Failure", props<{ reason: string }>());

export const markStepAsCompleted = createAction(
  "[LEARNING MODULE] Mark Step As Completed",
  props<LearningModuleControllerMarkStepAsCompletedRequestParams>(),
);
export const markStepAsCompletedSuccess = createAction("[LEARNING MODULE] Mark Step As Completed Success", props<{ stepId: string }>());
export const markStepAsCompletedFailure = createAction("[LEARNING MODULE] Mark Step As Completed Failure", props<{ reason: string }>());
