import { isDefined, isEmail, isJSON, isPhoneNumber, isURL } from "class-validator";
import { REGEXP } from "../constants";
import { SurveyFieldDto } from "../services/api/model/survey-field-dto";
import { ValidateSurveyResponseValueResponse } from "./validate-survey-response-value-response.interface";

export const validateSurveyResponseValue = (field: SurveyFieldDto, value: string): ValidateSurveyResponseValueResponse => {
  if (!value && field.required) return { error: "SURVEYS.FIELD_REQUIRED" };

  switch (field.type) {
    case SurveyFieldDto.TypeEnum.Boolean:
      if (["true", "false"].includes(value)) return {};
      return { error: "SURVEYS.INVALID_VALUE" };

    case SurveyFieldDto.TypeEnum.Date:
      if (!!value.match(REGEXP.DATE)) return {};
      return { error: "SURVEYS.INVALID_VALUE" };

    case SurveyFieldDto.TypeEnum.Time:
      if (!!value.match(REGEXP.TIME)) return {};
      return { error: "SURVEYS.INVALID_VALUE" };

    case SurveyFieldDto.TypeEnum.Datetime:
      const [date = "", time = ""] = value.split(" ");
      if (!!date.match(REGEXP.DATE) && !!time.match(REGEXP.TIME)) return {};
      return { error: "SURVEYS.INVALID_VALUE" };

    case SurveyFieldDto.TypeEnum.Email:
      if (isEmail(value)) return {};
      return { error: "SURVEYS.INVALID_VALUE" };

    case SurveyFieldDto.TypeEnum.Json:
      if (isJSON(value)) return {};
      return { error: "SURVEYS.INVALID_VALUE" };

    case SurveyFieldDto.TypeEnum.Multiselect:
      try {
        if (Array.isArray(JSON.parse(value))) return {};
        return { error: "SURVEYS.INVALID_VALUE" };
      } catch (e) {
        return { error: "SURVEYS.INVALID_VALUE" };
      }

    case SurveyFieldDto.TypeEnum.Number:
    case SurveyFieldDto.TypeEnum.Range:
    case SurveyFieldDto.TypeEnum.Rating:
      if (!value && !field.required) return {};
      if (isNaN(+value)) return { error: "SURVEYS.VALUE_MUST_BE_A_NUMBER" };
      if (isDefined(field.min) && +value < field.min) return { error: "SURVEYS.VALUE_MUST_BE_GREATER_THAN", params: { min: field.min } };
      if (isDefined(field.max) && +value > field.max) return { error: "SURVEYS.VALUE_MUST_BE_LOWER_THAN", params: { max: field.max } };
      return {};

    case SurveyFieldDto.TypeEnum.Select:
    case SurveyFieldDto.TypeEnum.Text:
    case SurveyFieldDto.TypeEnum.Textarea:
      if (!!value || !field.required) return {};
      return { error: "SURVEYS.INVALID_VALUE" };

    case SurveyFieldDto.TypeEnum.Tel:
      if (isPhoneNumber(value)) return {};
      return { error: "SURVEYS.INVALID_VALUE" };

    case SurveyFieldDto.TypeEnum.Url:
      if (isURL(value, { require_protocol: true })) return {}; // eslint-disable-line @typescript-eslint/naming-convention
      return { error: "SURVEYS.INVALID_VALUE" };
  }
};
