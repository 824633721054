/**
 * Wow family api
 * The Wow family API description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface IntegrationDto { 
    createdAt: string;
    updatedAt: string;
    id: string;
    storeId: string;
    type: IntegrationDto.TypeEnum;
    metadata: object;
}
export namespace IntegrationDto {
    export type TypeEnum = 'GOOGLE_BUSINESS' | 'GOOGLE_REVIEWS' | 'HELLO_CASH';
    export const TypeEnum = {
        GoogleBusiness: 'GOOGLE_BUSINESS' as TypeEnum,
        GoogleReviews: 'GOOGLE_REVIEWS' as TypeEnum,
        HelloCash: 'HELLO_CASH' as TypeEnum
    };
}


