import { Pipe, PipeTransform } from "@angular/core";
import { EventDto, EventUserDto } from "@api";
import { Miliseconds } from "src/app/constants";

@Pipe({ name: "canShowJoinButton" })
export class CanShowJoinButtonPipe implements PipeTransform {
  public transform(event: EventDto, response: EventUserDto.StatusEnum): boolean {
    if (!event?.location?.startsWith("http")) return false;
    if (!response || response !== EventUserDto.StatusEnum.Confirmed) return false;

    const eventDate = new Date(event.date);
    const diffTime = eventDate.getTime() - Date.now();

    return diffTime < Miliseconds.OneHour && diffTime > Miliseconds.TenMinutes * -1;
  }
}
