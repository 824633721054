import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { EventParticipantsComponent } from "./event-participants.component";
import { ToEventParticipantsPipeModule } from "src/app/pipes/to-event-participants/to-event-participants.module";
import { ToEventContentModule } from "src/app/pipes/to-event-content/to-event-content.module";
import { TranslateModule } from "@ngx-translate/core";
import { InitialsPipeModule } from "src/app/pipes/initials/initials.module";
import { UsernamePipeModule } from "src/app/pipes/username/username.module";
import { EventAvailabilityModule } from "../event-availability/event-availability.module";

@NgModule({
  declarations: [EventParticipantsComponent],
  imports: [
    CommonModule,
    ToEventContentModule,
    ToEventParticipantsPipeModule,
    TranslateModule,
    InitialsPipeModule,
    UsernamePipeModule,
    EventAvailabilityModule,
  ],
  exports: [EventParticipantsComponent],
})
export class EventParticipantsModule {}
