import { Component } from "@angular/core";
import { AlertController } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs";
import { RootState } from "src/app/store";
import * as FromApp from "src/app/store/app/selectors";

@Component({
  selector: "app-offline-indicator",
  templateUrl: "./offline-indicator.component.html",
  styleUrls: ["./offline-indicator.component.scss"],
})
export class OfflineIndicatorComponent {
  public online$: Observable<boolean> = this.store.select(FromApp.selectOnline);

  constructor(private store: Store<RootState>, private alertController: AlertController, private translate: TranslateService) {}

  public async showAlert(): Promise<void> {
    const alert = await this.alertController.create({
      header: this.translate.instant("OFFLINE.offlineHeader"),
      message: this.translate.instant("OFFLINE.offlineMessage"),
      buttons: [{ text: this.translate.instant("OFFLINE.understood") }],
    });
    await alert.present();
  }
}
