import { Pipe, PipeTransform } from "@angular/core";
import { EventDto, EventUserDto } from "@api";
import { Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { RootState } from "src/app/store";
import * as FromEvent from "src/app/store/event/selectors";
import RRule from "rrule";

@Pipe({ name: "eventResponse" })
export class EventResponsePipe implements PipeTransform {
  constructor(private store: Store<RootState>) {}

  public transform(event: EventDto): Observable<EventUserDto.StatusEnum> {
    if (!event) return of(EventUserDto.StatusEnum.NoAnswer);

    const eventNextExecution = event.recurrence ? RRule.fromString(event.recurrence).after(new Date()) : new Date(event.date);

    return this.store
      .select(FromEvent.selectEventInvitation(event.id, eventNextExecution.getTime()))
      .pipe(map(invitation => invitation?.status ?? EventUserDto.StatusEnum.NoAnswer));
  }
}
