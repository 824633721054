import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CanShowJoinButtonPipe } from "./can-show-join-button.pipe";

@NgModule({
  imports: [CommonModule],
  declarations: [CanShowJoinButtonPipe],
  exports: [CanShowJoinButtonPipe],
})
export class CanShowJoinButtonModule {}
