import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { VersionsService } from "@api";
import { AlertController, ToastController } from "@ionic/angular";
import { Actions, concatLatestFrom, createEffect, ofType } from "@ngrx/effects";
import { Action, Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { catchError, map, mergeMap, tap } from "rxjs/operators";
import { RootState } from "..";
import * as semver from "semver";
import * as AppActions from "./actions";
import * as FromApp from "./selectors";
import { EnvironmentService } from "src/app/services/environment/environment.service";
import { differenceInDays } from "date-fns";
import { Browser } from "@capacitor/browser";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class AppEffects {
  public showAndroidInstallPrompt$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AppActions.showAndroidInstallPrompt),
        concatLatestFrom(() => this.store.select(FromApp.selectShouldShowAndroidBeforeInstallPrompt)),
        tap(async ([{ event }, shouldShow]) => {
          event.preventDefault();

          if (!shouldShow) return;

          const alert = await this.toastController.create({
            header: this.i18n.instant("ALERTS.INSTALL_PROMPT.HEADER"),
            message: this.i18n.instant("ALERTS.INSTALL_PROMPT.MESSAGE"),
            mode: "md",
            cssClass: ["beforeinstallprompt"],
            buttons: [
              {
                text: this.i18n.instant("ALERTS.INSTALL_PROMPT.BUTTON_OK"),
                handler: (): void => event.prompt(),
              },
              { role: "cancel", icon: "close" },
            ],
          });

          await alert.present();
          this.store.dispatch(AppActions.setAndroidInstallPromptAsShowed());
        }),
      ),
    { dispatch: false },
  );

  public getLatestVersion$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.getLatestVersion),
      mergeMap(({ platform }) =>
        this.versionService.versionControllerGetLatest({ platform }).pipe(
          map(version => AppActions.getLatestVersionSuccess({ version })),
          catchError((error: HttpErrorResponse) => of(AppActions.getLatestVersionFailure({ reason: error.error?.message }))),
        ),
      ),
    ),
  );

  public getLatestVersionSuccess$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AppActions.getLatestVersionSuccess),
        concatLatestFrom(() => this.store.select(FromApp.getDismissedUpdateVersion)),
        tap(async ([{ version }, dismissed]) => {
          const current = semver.clean(this.environmentService.get<string>("release").split("#")[0]);
          const daysSinceUpdate = differenceInDays(new Date(), new Date(version.updatedAt));

          if (
            current &&
            semver.gt(version.version, current) &&
            daysSinceUpdate >= 7 &&
            (!dismissed || dismissed.version !== version.version)
          ) {
            const alert = await this.alertController.create({
              header: this.i18n.instant("ALERTS.NEW_VERSION.HEADER", { version: version.version }),
              message: this.i18n.instant("ALERTS.NEW_VERSION.MESSAGE"),
              buttons: [
                {
                  text: this.i18n.instant("ALERTS.NEW_VERSION.BUTTON_CANCEL"),
                  role: "destructive",
                  handler: (): void => this.store.dispatch(AppActions.getLatestVersionDismissed({ version })),
                },
                {
                  text: this.i18n.instant("ALERTS.NEW_VERSION.BUTTON_OK"),
                  handler: (): void => this.store.dispatch(AppActions.getLatestVersionAccepted({ version })),
                },
              ],
            });

            await alert.present();
          }
        }),
      ),
    { dispatch: false },
  );

  public getLatestVersionAccepted$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AppActions.getLatestVersionAccepted),
        tap(({ version }) => {
          switch (version.id) {
            case "android":
              Browser.open({ url: "https://play.google.com/store/apps/details?id=de.wowfamily.app" });
              break;
            case "ios":
              Browser.open({ url: "https://apps.apple.com/us/app/wow-family/id1582603591" });
              break;
            case "www":
              window.location.reload();
              break;
          }
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private store: Store<RootState>,
    private environmentService: EnvironmentService,
    private versionService: VersionsService,
    private i18n: TranslateService,
    private toastController: ToastController,
    private alertController: AlertController,
  ) {}
}
