import { createReducer, on } from "@ngrx/store";
import { initialState } from "./state";
import * as SurveyActions from "./actions";

export const surveyReducer = createReducer(
  initialState,

  on(
    SurveyActions.setLoading,
    SurveyActions.getDefinitionBySlug,
    SurveyActions.getSurveyById,
    SurveyActions.getSurveyByDefinitionSlug,
    SurveyActions.getAssignedSurveys,
    SurveyActions.getDefinitionById,
    SurveyActions.getSurveyResponses,
    SurveyActions.createSurveyDefinition,
    SurveyActions.updateSurveyDefinition,
    SurveyActions.createSurveyResponse,
    SurveyActions.unassignSurvey,
    SurveyActions.getSurveyDefinitions,
    SurveyActions.deleteSurveyDefinition,
    SurveyActions.createSurveyField,
    state => ({ ...state, loading: true }),
  ),

  on(SurveyActions.getDefinitionBySlugSuccess, (state, { definition }) => ({
    ...state,
    definitions: {
      ...state.definitions,
      [definition.id]: definition,
    },
    loading: false,
  })),

  on(SurveyActions.getSurveyByIdSuccess, SurveyActions.getSurveyByDefinitionSlugSuccess, (state, { survey }) => ({
    ...state,
    surveys: {
      ...state.surveys,
      [survey.id]: survey,
    },
    loading: false,
  })),

  on(SurveyActions.getAssignedSurveysSuccess, (state, { surveys }) => ({
    ...state,
    surveys: {
      ...state.surveys,
      ...surveys.reduce((acc, cur) => {
        acc[cur.id] = cur;
        return acc;
      }, {}),
    },
    loading: false,
  })),

  on(SurveyActions.getSurveyDefinitionsSuccess, (state, { definitions }) => ({
    ...state,
    definitions: {
      ...state.definitions,
      ...definitions.reduce((acc, cur) => {
        acc[cur.id] = cur;
        return acc;
      }, {}),
    },
    loading: false,
  })),

  on(SurveyActions.createSurveyDefinitionSuccess, SurveyActions.updateSurveyDefinitionSuccess, (state, { surveyDefinition }) => ({
    ...state,
    definitions: {
      ...state.definitions,
      [surveyDefinition.id]: surveyDefinition,
    },
    loading: false,
  })),

  on(SurveyActions.getDefinitionByIdSuccess, (state, { definition }) => ({
    ...state,
    definitions: {
      ...state.definitions,
      [definition.id]: definition,
    },
    loading: false,
  })),

  on(SurveyActions.createSurveyFieldSuccess, SurveyActions.updateSurveyFieldSuccess, (state, { definitionId, field }) => ({
    ...state,
    definitions: {
      ...state.definitions,
      [definitionId]: {
        ...state.definitions[definitionId],
        surveyFields: [...state.definitions[definitionId].surveyFields, field],
      },
    },
    loading: false,
  })),

  on(SurveyActions.getSurveyResponsesSuccess, (state, { responses, surveyId }) => ({
    ...state,
    responses: {
      ...state.responses,
      [surveyId]: responses,
    },
    loading: false,
  })),

  on(SurveyActions.createSurveyResponseSuccess, (state, { response }) => {
    const responses = [...(state.responses[response.surveyId] || [])];
    const index = responses.findIndex(r => r.surveyFieldId === response.surveyFieldId);

    if (index >= 0) responses.splice(index, 1, response);
    else responses.push(response);

    return {
      ...state,
      responses: {
        ...state.responses,
        [response.surveyId]: responses,
      },
      loading: false,
    };
  }),

  on(SurveyActions.storeSurveyError, (state, { fieldId, error, params }) => ({
    ...state,
    localSurveyErrors: {
      ...state.localSurveyErrors,
      [fieldId]: { error, params },
    },
  })),

  on(SurveyActions.cleanSurveyError, (state, { fieldId }) => ({
    ...state,
    localSurveyErrors: [...Object.entries(state.localSurveyErrors)].reduce((localSurveyErrors, [key, value]) => {
      if (key !== fieldId) localSurveyErrors[key] = value;
      return localSurveyErrors;
    }, {}),
  })),

  on(SurveyActions.unassignSurveySuccess, (state, { surveyId }) => {
    const surveys = { ...state.surveys };
    delete surveys[surveyId];

    return {
      ...state,
      surveys,
      loading: false,
    };
  }),

  on(SurveyActions.deleteSurveyDefinitionSuccess, (state, { definitionId }) => {
    const entities = {};
    Object.values(state.definitions)
      .filter(adminSurvey => adminSurvey.id !== definitionId)
      .forEach(adminSurvey => (entities[adminSurvey.id] = adminSurvey));

    return {
      ...state,
      definitions: entities,
      loading: false,
    };
  }),

  on(SurveyActions.deleteSurveyFieldSuccess, (state, { definitionId, fieldId }) => ({
    ...state,
    definitions: {
      ...state.definitions,
      [definitionId]: {
        ...state.definitions[definitionId],
        surveyFields: [...state.definitions[definitionId].surveyFields.filter(surveyField => surveyField.id !== fieldId)],
      },
    },
    loading: false,
  })),

  on(
    SurveyActions.hideLoading,
    SurveyActions.getDefinitionBySlugFailure,
    SurveyActions.getSurveyByIdFailure,
    SurveyActions.getSurveyByDefinitionSlugFailure,
    SurveyActions.getAssignedSurveysFailure,
    SurveyActions.getDefinitionByIdFailure,
    SurveyActions.getSurveyResponsesFailure,
    SurveyActions.createSurveyResponseFailure,
    SurveyActions.unassignSurveyFailure,
    SurveyActions.getSurveyDefinitionsFailure,
    SurveyActions.deleteSurveyDefinitionFailure,
    state => ({ ...state, loading: false }),
  ),
);
