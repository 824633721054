import { DOCUMENT } from "@angular/common";
import { ChangeDetectionStrategy, Component, Inject, Input } from "@angular/core";
import { ActionButtonDto } from "@api";
import { ModalController } from "@ionic/angular";
import { NavigationService } from "src/app/services/navigation/navigation.service";

@Component({
  selector: "app-action-button",
  templateUrl: "./action-button.page.html",
  styleUrls: ["./action-button.page.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionButtonPage {
  @Input() public actionButton: ActionButtonDto;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private modalController: ModalController,
    private navigationService: NavigationService,
  ) {}

  public dismiss(): void {
    this.modalController.dismiss();
  }

  public handleClick(actionButton: ActionButtonDto): void {
    const routerOutlets = this.document.querySelectorAll("ion-router-outlet");
    const presentingElement = routerOutlets[routerOutlets.length - 1];
    this.navigationService.handleNavigationTo(actionButton.actionLink, presentingElement);
    this.dismiss();
  }
}
