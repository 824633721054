import { ChapterDto, ChallengeDto, StepDto, LearningModuleDto } from "@api";

interface LearningModules {
  [id: string]: LearningModuleDto;
}

interface Chapters {
  [id: string]: ChapterDto;
}

interface Challenges {
  [id: string]: ChallengeDto;
}

interface ChapterChallenges {
  [id: string]: number;
}

interface ChallengeSteps {
  [id: string]: number;
}

interface Steps {
  [id: string]: StepDto;
}

export interface LearningModuleState {
  learningModules: LearningModules;
  chapters: Chapters;
  challenges: Challenges;
  steps: Steps;
  chapterChallenges: ChapterChallenges;
  challengeSteps: ChallengeSteps;
  loading: boolean;
}

export const initialState: LearningModuleState = {
  learningModules: {},
  chapters: {},
  challenges: {},
  steps: {},
  chapterChallenges: {},
  challengeSteps: {},
  loading: false,
};

export const persistedKeys: string[] = ["learningModules", "learningModuleChapters", "challengeSteps"];
