import { ReportDataDefinitionDto } from "../services/api/model/report-data-definition-dto";
import { ReportDto } from "../services/api/model/report-dto";

const getReportValueAsKPI = (report: ReportDto, reports: ReportDto[], dataDefinitions: ReportDataDefinitionDto[]): number => {
  const dataDefinition = dataDefinitions.find(dd => dd.id === report.dataDefinitionId);
  if (!dataDefinition?.operator || !dataDefinition?.threshold) return -1;

  const divider = dataDefinitions.find(dd => dd.id === dataDefinition.divider);
  let comparisonValue = report.value - dataDefinition.threshold;

  if (divider) {
    const dividerReport = reports.find(r => r.dataDefinitionId === divider.id);
    if (dividerReport?.value > 0) comparisonValue = (report.value / dividerReport.value) * 100 - dataDefinition.threshold;
  }

  return comparisonValue;
};

export const sortByKPI = (a: ReportDto, b: ReportDto, reports: ReportDto[], dataDefinitions: ReportDataDefinitionDto[]): number => {
  if (a.dataDefinitionId && !b.dataDefinitionId) return -1;
  if (b.dataDefinitionId && !a.dataDefinitionId) return 1;

  return getReportValueAsKPI(b, reports, dataDefinitions) - getReportValueAsKPI(a, reports, dataDefinitions);
};
