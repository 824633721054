import { NewsDto } from "@api";

export interface ManyNews {
  [key: string]: NewsDto;
}

export interface NewsState {
  data: ManyNews;
  loading: boolean;
  total: number;
  currentPage: number;
  pageCount: number;
}

export const initialState: NewsState = {
  data: {},
  loading: false,
  total: 0,
  currentPage: 1,
  pageCount: 0,
};

export const persistedKeys: string[] = [];
