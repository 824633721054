import { Pipe, PipeTransform } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { first, map } from "rxjs/operators";
import { RootState } from "src/app/store";
import * as FromReport from "src/app/store/report/selectors";

@Pipe({
  name: "getSymbol",
})
export class GetSymbolPipe implements PipeTransform {
  constructor(private store: Store<RootState>) {}

  public transform(dataDefinitionId: string): Observable<string> {
    if (!dataDefinitionId) return null;

    return this.store.select(FromReport.selectDataDefinitionById(dataDefinitionId)).pipe(
      first(),
      map(dataDefinition => dataDefinition?.symbol),
    );
  }
}
