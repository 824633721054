<ion-content class="action-button">
  <div class="action-button__container" [appBackgroundImage]="actionButton.imageUrl">
    <div class="action-button__container__content">
      <div class="action-button__container__tag" [innerHTML]="'ACTION_BUTTON_MODAL.YOUR_CHALLENGE' | translate"></div>
      <h2 class="action-button__container__title" [innerHTML]="actionButton.title"></h2>
      <h5 class="action-button__container__text" [innerHTML]="actionButton.message"></h5>
      <ion-button class="wow-button --white" [innerHTML]="actionButton.actionText" (click)="handleClick(actionButton)"></ion-button>
      <ion-button
        class="wow-button --text --white-text --small"
        [innerHTML]="'BUTTONS.CANCEL' | translate"
        (click)="dismiss()"
      ></ion-button>
    </div>
  </div>
</ion-content>
