import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ToEventParticipantsPipe } from "./to-event-participants.pipe";

@NgModule({
  imports: [CommonModule],
  declarations: [ToEventParticipantsPipe],
  exports: [ToEventParticipantsPipe],
})
export class ToEventParticipantsPipeModule {}
