import { getSelectors, RouterReducerState } from "@ngrx/router-store";
import { createFeatureSelector } from "@ngrx/store";
import { QueryParams, RouteParams } from "src/app/constants";
import { RootState } from "..";

export const selectRouter = createFeatureSelector<RootState, RouterReducerState<any>>("router");

const { selectRouteParam, selectQueryParam } = getSelectors(selectRouter);

export const selectEventContentId = selectRouteParam(RouteParams.contentId);

export const selectEventId = selectRouteParam(RouteParams.eventId);

export const selectScenarioId = selectRouteParam(RouteParams.scenarioId);

export const selectSurveySlug = selectRouteParam(RouteParams.surveySlug);

export const selectSurveyFieldId = selectRouteParam(RouteParams.surveyFieldId);

export const selectModuleId = selectRouteParam(RouteParams.moduleId);

export const selectChallengeId = selectRouteParam(RouteParams.challengeId);

export const selectChapterId = selectRouteParam(RouteParams.chapterId);

export const selectStepId = selectRouteParam(RouteParams.stepId);

export const selectSlugFromQueryParams = selectQueryParam(QueryParams.surveySlug);

export const selectMonthFromQueryParams = selectQueryParam(QueryParams.month);

export const selectYearFromQueryParams = selectQueryParam(QueryParams.year);

export const selectRefreshTokenFromQueryParams = selectQueryParam(QueryParams.refreshToken);
