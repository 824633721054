import * as SurveyActions from "src/app/store/survey/actions";
import * as ReportActions from "src/app/store/report/actions";
import * as FromSurvey from "src/app/store/survey/selectors";
import * as FromUser from "src/app/store/user/selectors";
import * as FromReport from "src/app/store/report/selectors";
import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from "@angular/core";
import { CreateReportDto, ReportDto, SurveyDefinitionDto, SurveyDto, SurveyFieldDto } from "@api";
import { ModalController, ToastController } from "@ionic/angular";
import { Observable, Subscription } from "rxjs";
import { Store } from "@ngrx/store";
import { RootState } from "src/app/store";
import { HandleChangeParams } from "src/app/components/survey-field/interfaces/handle-change-params.interface";
import { first, withLatestFrom } from "rxjs/operators";
import { AppRoutes, QueryParams, TOAST_DEFAULT_DURATION } from "src/app/constants";
import { Swiper, SwiperOptions } from "swiper";
import { validateSurveyResponseValue } from "src/app/utils/validate-survey-response";
import { LocalReportErrors } from "src/app/store/report/state";
import { StoreResponseParams } from "./store-response-params.interface";
import { Platform } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { IframeModal } from "../iframe-modal/iframe-modal.page";
import { kebabCaseToMacroCase } from "src/app/utils/kebab-case-to-macro-case";
import { ReportsWithQuestionPipe } from "src/app/pipes/reports-with-question/reports-with-question.pipe";

@Component({
  selector: "app-swipper-report",
  templateUrl: "./swipper-report.page.html",
  styleUrls: ["./swipper-report.page.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SwipperReportPage implements OnInit, OnDestroy {
  @Input() public slug: string;
  @Input() public month?: number;
  @Input() public year?: number;

  public loading$: Observable<boolean> = this.store.select(FromReport.selectLoading);
  public definition$: Observable<SurveyDefinitionDto>;
  public fields$: Observable<SurveyFieldDto[]>;
  public survey$: Observable<SurveyDto>;
  public reports$: Observable<ReportDto[]>;
  public errors$: Observable<LocalReportErrors> = this.store.select(FromReport.selectReportErrors);
  public date: Date;
  public currentIndex: number = 0;
  public showWelcome: boolean = true;
  public swiper: Swiper;
  public swiperConfig: SwiperOptions = {
    slidesPerView: 1,
    spaceBetween: 0,
    allowTouchMove: false,
  };

  private $keyboard: Subscription;

  constructor(
    public readonly store: Store<RootState>,
    public readonly platform: Platform,
    public readonly i18n: TranslateService,
    public readonly modalController: ModalController,
    public readonly toastController: ToastController,
    public readonly reportsWithQuestionPipe: ReportsWithQuestionPipe,
  ) {
    this.$keyboard = this.platform.keyboardDidShow.subscribe((ev: { keyboardHeight: number }) => {
      if (!this.platform.is("desktop")) {
        window.scrollTo({ top: ev.keyboardHeight, behavior: "smooth" });
      }
    });
  }

  public trackBy(_: number, field: SurveyFieldDto): string {
    return field.id;
  }

  public dismiss(): void {
    this.survey$.pipe(first()).subscribe(survey => {
      if (survey.dismissable) this.store.dispatch(SurveyActions.unassignSurvey({ surveyId: survey.id }));
      this.modalController.dismiss();
    });
  }

  public start(): void {
    this.showWelcome = false;
  }

  public onSwiper(swiper: Swiper): void {
    this.swiper = swiper;
  }

  public onSlideChange(): void {
    this.currentIndex = this.swiper.activeIndex;
  }

  public previousSlide(): void {
    this.swiper.slidePrev();
  }

  public nextSlide(): void {
    this.fields$.pipe(withLatestFrom(this.reports$), first()).subscribe(([fields, reports]) => {
      const field = fields[this.currentIndex];
      const value = reports.find(report => report.dataDefinitionId === field.dataDefinitionId)?.value?.toString();
      this.storeResponse({ field, month: this.date.getMonth() + 1, year: this.date.getFullYear(), value, goNext: true });
    });
  }

  public async goToSlide(index: number): Promise<void> {
    if (!this.swiper) {
      await new Promise(resolve => setTimeout(() => resolve(null), 100));
      this.goToSlide(index);
    }

    this.swiper.slideTo(index);
  }

  public ngOnInit(): void {
    const date = new Date();
    date.setDate(15);

    if (isNaN(this.month)) date.setMonth(date.getMonth() - 1);
    else date.setMonth(this.month - 1);

    date.setFullYear(this.year || date.getFullYear());

    this.date = date;
    this.fields$ = this.store.select(FromSurvey.selectDefinitionFieldsBySlug(this.slug));
    this.definition$ = this.store.select(FromSurvey.selectDefinitionBySlug(this.slug));
    this.survey$ = this.store.select(FromSurvey.selectSurveyByDefinitionSlug(this.slug));
    this.reports$ = this.store.select(
      FromReport.selectReportsForDate(
        kebabCaseToMacroCase(this.slug) as ReportDto.DataSourceEnum,
        this.date.getMonth() + 1,
        this.date.getFullYear(),
      ),
    );

    this.reports$.pipe(withLatestFrom(this.fields$), first()).subscribe(([reports, fields]) => {
      if (this.reportsWithQuestionPipe.transform(reports, fields).length) {
        this.showWelcome = false;
        if (reports.length !== fields.length) this.goToSlide(reports.length);
      }
    });
  }

  public ngOnDestroy(): void {
    this.$keyboard?.unsubscribe();
  }

  public async handleHelp(link: string): Promise<void> {
    this.store
      .select(FromUser.selectRefreshToken)
      .pipe(first())
      .subscribe(async refreshToken => {
        const modal = await this.modalController.create({
          component: IframeModal,
          componentProps: { url: `${link}?${QueryParams.refreshToken}=${refreshToken}` },
          presentingElement: await this.modalController.getTop(),
          cssClass: ["event-reschedule-modal"],
          swipeToClose: true,
        });

        await modal.present();
      });
  }

  public handleFieldResponse(response: HandleChangeParams): void {
    if (!response.value || isNaN(+response.value)) return;
    this.storeResponse({ field: response.field, month: this.date.getMonth() + 1, year: this.date.getFullYear(), value: response.value });
  }

  public send(): void {
    this.fields$.pipe(withLatestFrom(this.reports$), first()).subscribe(([fields, reports]) => {
      const field = fields[this.currentIndex];
      const value = reports.find(report => report.dataDefinitionId === field.dataDefinitionId)?.value?.toString();
      this.storeResponse({ field, month: this.date.getMonth() + 1, year: this.date.getFullYear(), value, isLast: true });
    });
  }

  public storeResponse({ field, month, year, value, goNext, isLast }: StoreResponseParams): void {
    this.store
      .select(FromUser.selectUser)
      .pipe(first())
      .subscribe(user => {
        if (!user.storeUsers) {
          return this.showNoStoresErrorToast();
        }

        const storeId: string = user.storeUsers[0]?.storeId;
        const report: CreateReportDto = {
          dataDefinitionId: field.dataDefinitionId,
          dataSource: this.getDataSource(),
          month,
          year,
          value: isNaN(+value) ? null : +value,
          storeId,
        };

        const { error, params } = validateSurveyResponseValue(field, value);

        if (error) {
          this.store.dispatch(ReportActions.storeReportError({ fieldId: field.id, error, params }));
        } else {
          this.store.dispatch(ReportActions.cleanReportError({ fieldId: field.id }));
          if (value) this.store.dispatch(ReportActions.createReport({ createReportDto: report }));
          if (isLast) this.store.dispatch(ReportActions.sendSurvey({ surveySlug: this.slug, month, year, storeId }));
          if (goNext) this.swiper.slideNext();
        }
      });
  }

  public async showNoStoresErrorToast(): Promise<void> {
    const toast = await this.toastController.create({
      color: "danger",
      message: this.i18n.instant("USER_NO_STORE_ACCESS"),
      duration: TOAST_DEFAULT_DURATION,
    });

    await toast.present();
  }

  protected ionViewDidEnter(): void {
    this.store.dispatch(SurveyActions.getDefinitionBySlug({ slug: this.slug }));
    this.store.dispatch(SurveyActions.getSurveyByDefinitionSlug({ definitionSlug: this.slug }));
  }

  private getDataSource(): CreateReportDto.DataSourceEnum {
    return this.slug === AppRoutes.successReport ? CreateReportDto.DataSourceEnum.SuccessReport : CreateReportDto.DataSourceEnum.BwaReport;
  }
}
