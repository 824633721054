import { createReducer, on } from "@ngrx/store";
import { initialState } from "./state";
import * as LearningModuleActions from "./actions";

export const learningModuleReducer = createReducer(
  initialState,

  on(
    LearningModuleActions.setLoading,
    LearningModuleActions.getLearningModules,
    LearningModuleActions.getLearningModule,
    LearningModuleActions.getLearningModuleChapters,
    LearningModuleActions.createLearningModule,
    LearningModuleActions.updateLearningModule,
    LearningModuleActions.deleteLearningModule,
    LearningModuleActions.getChallenge,
    LearningModuleActions.createChallenge,
    LearningModuleActions.updateChallenge,
    LearningModuleActions.deleteChallenge,
    LearningModuleActions.getChallengeSteps,
    LearningModuleActions.getChallengeStepsCount,
    LearningModuleActions.getChapterChallenges,
    LearningModuleActions.getChapterChallengesCount,
    LearningModuleActions.getChapter,
    LearningModuleActions.createChapter,
    LearningModuleActions.updateChapter,
    LearningModuleActions.deleteChapter,
    LearningModuleActions.getStep,
    LearningModuleActions.createStep,
    LearningModuleActions.updateStep,
    LearningModuleActions.deleteStep,
    LearningModuleActions.markStepAsCompleted,
    state => ({ ...state, loading: true }),
  ),

  on(LearningModuleActions.getLearningModulesSuccess, (state, { learningModules }) => ({
    ...state,
    learningModules: learningModules.reduce((res, cur) => {
      res[cur.id] = cur;
      return res;
    }, {}),
    loading: false,
  })),

  on(
    LearningModuleActions.getLearningModuleSuccess,
    LearningModuleActions.createLearningModuleSuccess,
    LearningModuleActions.updateLearningModuleSuccess,
    (state, { learningModule }) => ({
      ...state,
      learningModules: {
        ...state.learningModules,
        [learningModule.id]: learningModule,
      },
      loading: false,
    }),
  ),

  on(LearningModuleActions.getLearningModuleChaptersSuccess, (state, { chapters }) => ({
    ...state,
    chapters: {
      ...state.chapters,
      ...chapters.reduce((res, cur) => {
        res[cur.id] = cur;
        return res;
      }, {}),
    },
    loading: false,
  })),

  on(
    LearningModuleActions.getChallengeSuccess,
    LearningModuleActions.createChallengeSuccess,
    LearningModuleActions.updateChallengeSuccess,
    (state, { challenge }) => ({
      ...state,
      challenges: {
        ...state.challenges,
        [challenge.id]: challenge,
      },
      loading: false,
    }),
  ),

  on(LearningModuleActions.deleteLearningModuleSuccess, (state, { learningModuleId }) => {
    const entities = {};
    Object.values(state.learningModules)
      .filter(learningModule => learningModule.id !== learningModuleId)
      .forEach(learningModule => (entities[learningModule.id] = learningModule));

    return {
      ...state,
      learningModules: entities,
      loading: false,
    };
  }),

  on(LearningModuleActions.deleteChallengeSuccess, (state, { challengeId }) => {
    const entities = {};
    Object.values(state.challenges)
      .filter(challenge => challenge.id !== challengeId)
      .forEach(challenge => (entities[challenge.id] = challenge));

    return {
      ...state,
      challenges: entities,
      loading: false,
    };
  }),

  on(LearningModuleActions.getChapterChallengesSuccess, (state, { challenges }) => ({
    ...state,
    challenges: {
      ...state.challenges,
      ...challenges.reduce((res, cur) => {
        res[cur.id] = cur;
        return res;
      }, {}),
    },
    loading: false,
  })),

  on(LearningModuleActions.getChapterChallengesCountSuccess, (state, { chapterId, challenges }) => ({
    ...state,
    chapterChallenges: {
      ...state.chapterChallenges,
      [chapterId]: challenges,
    },
    loading: false,
  })),

  on(
    LearningModuleActions.getChapterSuccess,
    LearningModuleActions.createChapterSuccess,
    LearningModuleActions.updateChapterSuccess,
    (state, { chapter }) => ({
      ...state,
      chapters: {
        ...state.chapters,
        [chapter.id]: chapter,
      },
      loading: false,
    }),
  ),

  on(LearningModuleActions.deleteChapter, (state, { chapterId }) => {
    const entities = {};
    Object.values(state.chapters)
      .filter(chapter => chapter.id !== chapterId)
      .forEach(chapter => (entities[chapter.id] = chapter));

    return {
      ...state,
      chapters: entities,
      loading: false,
    };
  }),

  on(
    LearningModuleActions.getStepSuccess,
    LearningModuleActions.createStepSuccess,
    LearningModuleActions.updateStepSuccess,
    (state, { step }) => ({
      ...state,
      steps: {
        ...state.steps,
        [step.id]: step,
      },
      loading: false,
    }),
  ),

  on(LearningModuleActions.getChallengeStepsSuccess, (state, { steps }) => ({
    ...state,
    steps: {
      ...state.steps,
      ...steps.reduce((res, cur) => {
        res[cur.id] = cur;
        return res;
      }, {}),
    },
    loading: false,
  })),

  on(LearningModuleActions.getChallengeStepsCountSuccess, (state, { challengeId, steps }) => ({
    ...state,
    challengeSteps: {
      ...state.challengeSteps,
      [challengeId]: steps,
    },
    loading: false,
  })),

  on(LearningModuleActions.deleteStepSuccess, (state, { stepId }) => {
    const entities = {};
    Object.values(state.steps)
      .filter(step => step.id !== stepId)
      .forEach(step => (entities[step.id] = step));

    return {
      ...state,
      steps: entities,
      loading: false,
    };
  }),

  on(
    LearningModuleActions.hideLoading,
    LearningModuleActions.getLearningModulesFailure,
    LearningModuleActions.getLearningModuleFailure,
    LearningModuleActions.getLearningModuleChaptersFailure,
    LearningModuleActions.createLearningModuleFailure,
    LearningModuleActions.updateLearningModuleFailure,
    LearningModuleActions.deleteLearningModuleFailure,
    LearningModuleActions.getChallengeFailure,
    LearningModuleActions.createChallengeFailure,
    LearningModuleActions.updateChallengeFailure,
    LearningModuleActions.deleteChallengeFailure,
    LearningModuleActions.getChallengeStepsFailure,
    LearningModuleActions.getChallengeStepsCountFailure,
    LearningModuleActions.getChapterChallengesFailure,
    LearningModuleActions.getChapterChallengesCountFailure,
    LearningModuleActions.getChapterFailure,
    LearningModuleActions.createChapterFailure,
    LearningModuleActions.updateChapterFailure,
    LearningModuleActions.deleteChapterFailure,
    LearningModuleActions.getStepFailure,
    LearningModuleActions.createStepFailure,
    LearningModuleActions.updateStepFailure,
    LearningModuleActions.deleteStepFailure,
    LearningModuleActions.markStepAsCompletedSuccess,
    LearningModuleActions.markStepAsCompletedFailure,
    state => ({ ...state, loading: false }),
  ),
);
