import { Version } from "@api";

export interface AppState {
  shouldShowAndroidBeforeInstallPrompt: boolean;
  deviceToken: string;
  dismissedUpdateVersion: Version;
  online: boolean;
}

export const initialState: AppState = {
  shouldShowAndroidBeforeInstallPrompt: true,
  deviceToken: null,
  dismissedUpdateVersion: null,
  online: true,
};

export const persistedKeys: string[] = ["shouldShowAndroidBeforeInstallPrompt", "deviceToken", "dismissedUpdateVersion"];
