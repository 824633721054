/**
 * Wow family api
 * The Wow family API description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { ChallengeDto } from '../model/models';
import { ChapterDto } from '../model/models';
import { CreateChallengeDto } from '../model/models';
import { CreateChapterDto } from '../model/models';
import { CreateLearningModuleDto } from '../model/models';
import { CreateStepDto } from '../model/models';
import { LearningModuleDto } from '../model/models';
import { StepDto } from '../model/models';
import { UpdateChallengeDto } from '../model/models';
import { UpdateChapterDto } from '../model/models';
import { UpdateLearningModuleDto } from '../model/models';
import { UpdateStepDto } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import {
    LearningModulesServiceInterface,
    LearningModuleControllerCreateChallengeRequestParams,
    LearningModuleControllerCreateChapterRequestParams,
    LearningModuleControllerCreateLearningModuleRequestParams,
    LearningModuleControllerCreateStepRequestParams,
    LearningModuleControllerDeleteChallengeRequestParams,
    LearningModuleControllerDeleteChapterRequestParams,
    LearningModuleControllerDeleteLearningModuleRequestParams,
    LearningModuleControllerDeleteStepRequestParams,
    LearningModuleControllerGetChallengeByIdRequestParams,
    LearningModuleControllerGetChallengeStepsRequestParams,
    LearningModuleControllerGetChallengeStepsCountRequestParams,
    LearningModuleControllerGetChapterByIdRequestParams,
    LearningModuleControllerGetChapterChallengesRequestParams,
    LearningModuleControllerGetChapterChallengesCountRequestParams,
    LearningModuleControllerGetLearningModuleRequestParams,
    LearningModuleControllerGetLearningModuleChaptersRequestParams,
    LearningModuleControllerGetStepRequestParams,
    LearningModuleControllerMarkStepAsCompletedRequestParams,
    LearningModuleControllerUpdateChallengeRequestParams,
    LearningModuleControllerUpdateChapterRequestParams,
    LearningModuleControllerUpdateLearningModuleRequestParams,
    LearningModuleControllerUpdateStepRequestParams
} from './learning-modules.serviceInterface';



@Injectable({
  providedIn: 'root'
})
export class LearningModulesService implements LearningModulesServiceInterface {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public learningModuleControllerCreateChallenge(requestParameters: LearningModuleControllerCreateChallengeRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ChallengeDto>;
    public learningModuleControllerCreateChallenge(requestParameters: LearningModuleControllerCreateChallengeRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ChallengeDto>>;
    public learningModuleControllerCreateChallenge(requestParameters: LearningModuleControllerCreateChallengeRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ChallengeDto>>;
    public learningModuleControllerCreateChallenge(requestParameters: LearningModuleControllerCreateChallengeRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const createChallengeDto = requestParameters.createChallengeDto;
        if (createChallengeDto === null || createChallengeDto === undefined) {
            throw new Error('Required parameter createChallengeDto was null or undefined when calling learningModuleControllerCreateChallenge.');
        }
        const learningModuleId = requestParameters.learningModuleId;
        if (learningModuleId === null || learningModuleId === undefined) {
            throw new Error('Required parameter learningModuleId was null or undefined when calling learningModuleControllerCreateChallenge.');
        }
        const chapterId = requestParameters.chapterId;
        if (chapterId === null || chapterId === undefined) {
            throw new Error('Required parameter chapterId was null or undefined when calling learningModuleControllerCreateChallenge.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<ChallengeDto>(`${this.configuration.basePath}/learning-modules/${encodeURIComponent(String(learningModuleId))}/chapters/${encodeURIComponent(String(chapterId))}/challenges`,
            createChallengeDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public learningModuleControllerCreateChapter(requestParameters: LearningModuleControllerCreateChapterRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ChapterDto>;
    public learningModuleControllerCreateChapter(requestParameters: LearningModuleControllerCreateChapterRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ChapterDto>>;
    public learningModuleControllerCreateChapter(requestParameters: LearningModuleControllerCreateChapterRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ChapterDto>>;
    public learningModuleControllerCreateChapter(requestParameters: LearningModuleControllerCreateChapterRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const createChapterDto = requestParameters.createChapterDto;
        if (createChapterDto === null || createChapterDto === undefined) {
            throw new Error('Required parameter createChapterDto was null or undefined when calling learningModuleControllerCreateChapter.');
        }
        const learningModuleId = requestParameters.learningModuleId;
        if (learningModuleId === null || learningModuleId === undefined) {
            throw new Error('Required parameter learningModuleId was null or undefined when calling learningModuleControllerCreateChapter.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<ChapterDto>(`${this.configuration.basePath}/learning-modules/${encodeURIComponent(String(learningModuleId))}/chapters`,
            createChapterDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public learningModuleControllerCreateLearningModule(requestParameters: LearningModuleControllerCreateLearningModuleRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<LearningModuleDto>;
    public learningModuleControllerCreateLearningModule(requestParameters: LearningModuleControllerCreateLearningModuleRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<LearningModuleDto>>;
    public learningModuleControllerCreateLearningModule(requestParameters: LearningModuleControllerCreateLearningModuleRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<LearningModuleDto>>;
    public learningModuleControllerCreateLearningModule(requestParameters: LearningModuleControllerCreateLearningModuleRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const createLearningModuleDto = requestParameters.createLearningModuleDto;
        if (createLearningModuleDto === null || createLearningModuleDto === undefined) {
            throw new Error('Required parameter createLearningModuleDto was null or undefined when calling learningModuleControllerCreateLearningModule.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<LearningModuleDto>(`${this.configuration.basePath}/learning-modules`,
            createLearningModuleDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public learningModuleControllerCreateStep(requestParameters: LearningModuleControllerCreateStepRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<StepDto>;
    public learningModuleControllerCreateStep(requestParameters: LearningModuleControllerCreateStepRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<StepDto>>;
    public learningModuleControllerCreateStep(requestParameters: LearningModuleControllerCreateStepRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<StepDto>>;
    public learningModuleControllerCreateStep(requestParameters: LearningModuleControllerCreateStepRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const createStepDto = requestParameters.createStepDto;
        if (createStepDto === null || createStepDto === undefined) {
            throw new Error('Required parameter createStepDto was null or undefined when calling learningModuleControllerCreateStep.');
        }
        const learningModuleId = requestParameters.learningModuleId;
        if (learningModuleId === null || learningModuleId === undefined) {
            throw new Error('Required parameter learningModuleId was null or undefined when calling learningModuleControllerCreateStep.');
        }
        const chapterId = requestParameters.chapterId;
        if (chapterId === null || chapterId === undefined) {
            throw new Error('Required parameter chapterId was null or undefined when calling learningModuleControllerCreateStep.');
        }
        const challengeId = requestParameters.challengeId;
        if (challengeId === null || challengeId === undefined) {
            throw new Error('Required parameter challengeId was null or undefined when calling learningModuleControllerCreateStep.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<StepDto>(`${this.configuration.basePath}/learning-modules/${encodeURIComponent(String(learningModuleId))}/chapters/${encodeURIComponent(String(chapterId))}/challenges/${encodeURIComponent(String(challengeId))}/steps`,
            createStepDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public learningModuleControllerDeleteChallenge(requestParameters: LearningModuleControllerDeleteChallengeRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ChallengeDto>;
    public learningModuleControllerDeleteChallenge(requestParameters: LearningModuleControllerDeleteChallengeRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ChallengeDto>>;
    public learningModuleControllerDeleteChallenge(requestParameters: LearningModuleControllerDeleteChallengeRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ChallengeDto>>;
    public learningModuleControllerDeleteChallenge(requestParameters: LearningModuleControllerDeleteChallengeRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const learningModuleId = requestParameters.learningModuleId;
        if (learningModuleId === null || learningModuleId === undefined) {
            throw new Error('Required parameter learningModuleId was null or undefined when calling learningModuleControllerDeleteChallenge.');
        }
        const chapterId = requestParameters.chapterId;
        if (chapterId === null || chapterId === undefined) {
            throw new Error('Required parameter chapterId was null or undefined when calling learningModuleControllerDeleteChallenge.');
        }
        const challengeId = requestParameters.challengeId;
        if (challengeId === null || challengeId === undefined) {
            throw new Error('Required parameter challengeId was null or undefined when calling learningModuleControllerDeleteChallenge.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<ChallengeDto>(`${this.configuration.basePath}/learning-modules/${encodeURIComponent(String(learningModuleId))}/chapters/${encodeURIComponent(String(chapterId))}/challenges/${encodeURIComponent(String(challengeId))}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public learningModuleControllerDeleteChapter(requestParameters: LearningModuleControllerDeleteChapterRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ChapterDto>;
    public learningModuleControllerDeleteChapter(requestParameters: LearningModuleControllerDeleteChapterRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ChapterDto>>;
    public learningModuleControllerDeleteChapter(requestParameters: LearningModuleControllerDeleteChapterRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ChapterDto>>;
    public learningModuleControllerDeleteChapter(requestParameters: LearningModuleControllerDeleteChapterRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const learningModuleId = requestParameters.learningModuleId;
        if (learningModuleId === null || learningModuleId === undefined) {
            throw new Error('Required parameter learningModuleId was null or undefined when calling learningModuleControllerDeleteChapter.');
        }
        const chapterId = requestParameters.chapterId;
        if (chapterId === null || chapterId === undefined) {
            throw new Error('Required parameter chapterId was null or undefined when calling learningModuleControllerDeleteChapter.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<ChapterDto>(`${this.configuration.basePath}/learning-modules/${encodeURIComponent(String(learningModuleId))}/chapters/${encodeURIComponent(String(chapterId))}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public learningModuleControllerDeleteLearningModule(requestParameters: LearningModuleControllerDeleteLearningModuleRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<LearningModuleDto>;
    public learningModuleControllerDeleteLearningModule(requestParameters: LearningModuleControllerDeleteLearningModuleRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<LearningModuleDto>>;
    public learningModuleControllerDeleteLearningModule(requestParameters: LearningModuleControllerDeleteLearningModuleRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<LearningModuleDto>>;
    public learningModuleControllerDeleteLearningModule(requestParameters: LearningModuleControllerDeleteLearningModuleRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const learningModuleId = requestParameters.learningModuleId;
        if (learningModuleId === null || learningModuleId === undefined) {
            throw new Error('Required parameter learningModuleId was null or undefined when calling learningModuleControllerDeleteLearningModule.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<LearningModuleDto>(`${this.configuration.basePath}/learning-modules/${encodeURIComponent(String(learningModuleId))}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public learningModuleControllerDeleteStep(requestParameters: LearningModuleControllerDeleteStepRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<StepDto>;
    public learningModuleControllerDeleteStep(requestParameters: LearningModuleControllerDeleteStepRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<StepDto>>;
    public learningModuleControllerDeleteStep(requestParameters: LearningModuleControllerDeleteStepRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<StepDto>>;
    public learningModuleControllerDeleteStep(requestParameters: LearningModuleControllerDeleteStepRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const learningModuleId = requestParameters.learningModuleId;
        if (learningModuleId === null || learningModuleId === undefined) {
            throw new Error('Required parameter learningModuleId was null or undefined when calling learningModuleControllerDeleteStep.');
        }
        const chapterId = requestParameters.chapterId;
        if (chapterId === null || chapterId === undefined) {
            throw new Error('Required parameter chapterId was null or undefined when calling learningModuleControllerDeleteStep.');
        }
        const challengeId = requestParameters.challengeId;
        if (challengeId === null || challengeId === undefined) {
            throw new Error('Required parameter challengeId was null or undefined when calling learningModuleControllerDeleteStep.');
        }
        const stepId = requestParameters.stepId;
        if (stepId === null || stepId === undefined) {
            throw new Error('Required parameter stepId was null or undefined when calling learningModuleControllerDeleteStep.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<StepDto>(`${this.configuration.basePath}/learning-modules/${encodeURIComponent(String(learningModuleId))}/chapters/${encodeURIComponent(String(chapterId))}/challenges/${encodeURIComponent(String(challengeId))}/steps/${encodeURIComponent(String(stepId))}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public learningModuleControllerGetChallengeById(requestParameters: LearningModuleControllerGetChallengeByIdRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ChallengeDto>;
    public learningModuleControllerGetChallengeById(requestParameters: LearningModuleControllerGetChallengeByIdRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ChallengeDto>>;
    public learningModuleControllerGetChallengeById(requestParameters: LearningModuleControllerGetChallengeByIdRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ChallengeDto>>;
    public learningModuleControllerGetChallengeById(requestParameters: LearningModuleControllerGetChallengeByIdRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const learningModuleId = requestParameters.learningModuleId;
        if (learningModuleId === null || learningModuleId === undefined) {
            throw new Error('Required parameter learningModuleId was null or undefined when calling learningModuleControllerGetChallengeById.');
        }
        const chapterId = requestParameters.chapterId;
        if (chapterId === null || chapterId === undefined) {
            throw new Error('Required parameter chapterId was null or undefined when calling learningModuleControllerGetChallengeById.');
        }
        const challengeId = requestParameters.challengeId;
        if (challengeId === null || challengeId === undefined) {
            throw new Error('Required parameter challengeId was null or undefined when calling learningModuleControllerGetChallengeById.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ChallengeDto>(`${this.configuration.basePath}/learning-modules/${encodeURIComponent(String(learningModuleId))}/chapters/${encodeURIComponent(String(chapterId))}/challenges/${encodeURIComponent(String(challengeId))}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public learningModuleControllerGetChallengeSteps(requestParameters: LearningModuleControllerGetChallengeStepsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<StepDto>>;
    public learningModuleControllerGetChallengeSteps(requestParameters: LearningModuleControllerGetChallengeStepsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<StepDto>>>;
    public learningModuleControllerGetChallengeSteps(requestParameters: LearningModuleControllerGetChallengeStepsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<StepDto>>>;
    public learningModuleControllerGetChallengeSteps(requestParameters: LearningModuleControllerGetChallengeStepsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const learningModuleId = requestParameters.learningModuleId;
        if (learningModuleId === null || learningModuleId === undefined) {
            throw new Error('Required parameter learningModuleId was null or undefined when calling learningModuleControllerGetChallengeSteps.');
        }
        const chapterId = requestParameters.chapterId;
        if (chapterId === null || chapterId === undefined) {
            throw new Error('Required parameter chapterId was null or undefined when calling learningModuleControllerGetChallengeSteps.');
        }
        const challengeId = requestParameters.challengeId;
        if (challengeId === null || challengeId === undefined) {
            throw new Error('Required parameter challengeId was null or undefined when calling learningModuleControllerGetChallengeSteps.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<StepDto>>(`${this.configuration.basePath}/learning-modules/${encodeURIComponent(String(learningModuleId))}/chapters/${encodeURIComponent(String(chapterId))}/challenges/${encodeURIComponent(String(challengeId))}/steps`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public learningModuleControllerGetChallengeStepsCount(requestParameters: LearningModuleControllerGetChallengeStepsCountRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<number>;
    public learningModuleControllerGetChallengeStepsCount(requestParameters: LearningModuleControllerGetChallengeStepsCountRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<number>>;
    public learningModuleControllerGetChallengeStepsCount(requestParameters: LearningModuleControllerGetChallengeStepsCountRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<number>>;
    public learningModuleControllerGetChallengeStepsCount(requestParameters: LearningModuleControllerGetChallengeStepsCountRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const learningModuleId = requestParameters.learningModuleId;
        if (learningModuleId === null || learningModuleId === undefined) {
            throw new Error('Required parameter learningModuleId was null or undefined when calling learningModuleControllerGetChallengeStepsCount.');
        }
        const chapterId = requestParameters.chapterId;
        if (chapterId === null || chapterId === undefined) {
            throw new Error('Required parameter chapterId was null or undefined when calling learningModuleControllerGetChallengeStepsCount.');
        }
        const challengeId = requestParameters.challengeId;
        if (challengeId === null || challengeId === undefined) {
            throw new Error('Required parameter challengeId was null or undefined when calling learningModuleControllerGetChallengeStepsCount.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<number>(`${this.configuration.basePath}/learning-modules/${encodeURIComponent(String(learningModuleId))}/chapters/${encodeURIComponent(String(chapterId))}/challenges/${encodeURIComponent(String(challengeId))}/steps/count`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public learningModuleControllerGetChapterById(requestParameters: LearningModuleControllerGetChapterByIdRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ChapterDto>;
    public learningModuleControllerGetChapterById(requestParameters: LearningModuleControllerGetChapterByIdRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ChapterDto>>;
    public learningModuleControllerGetChapterById(requestParameters: LearningModuleControllerGetChapterByIdRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ChapterDto>>;
    public learningModuleControllerGetChapterById(requestParameters: LearningModuleControllerGetChapterByIdRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const learningModuleId = requestParameters.learningModuleId;
        if (learningModuleId === null || learningModuleId === undefined) {
            throw new Error('Required parameter learningModuleId was null or undefined when calling learningModuleControllerGetChapterById.');
        }
        const chapterId = requestParameters.chapterId;
        if (chapterId === null || chapterId === undefined) {
            throw new Error('Required parameter chapterId was null or undefined when calling learningModuleControllerGetChapterById.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ChapterDto>(`${this.configuration.basePath}/learning-modules/${encodeURIComponent(String(learningModuleId))}/chapters/${encodeURIComponent(String(chapterId))}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public learningModuleControllerGetChapterChallenges(requestParameters: LearningModuleControllerGetChapterChallengesRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<ChallengeDto>>;
    public learningModuleControllerGetChapterChallenges(requestParameters: LearningModuleControllerGetChapterChallengesRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<ChallengeDto>>>;
    public learningModuleControllerGetChapterChallenges(requestParameters: LearningModuleControllerGetChapterChallengesRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<ChallengeDto>>>;
    public learningModuleControllerGetChapterChallenges(requestParameters: LearningModuleControllerGetChapterChallengesRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const learningModuleId = requestParameters.learningModuleId;
        if (learningModuleId === null || learningModuleId === undefined) {
            throw new Error('Required parameter learningModuleId was null or undefined when calling learningModuleControllerGetChapterChallenges.');
        }
        const chapterId = requestParameters.chapterId;
        if (chapterId === null || chapterId === undefined) {
            throw new Error('Required parameter chapterId was null or undefined when calling learningModuleControllerGetChapterChallenges.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<ChallengeDto>>(`${this.configuration.basePath}/learning-modules/${encodeURIComponent(String(learningModuleId))}/chapters/${encodeURIComponent(String(chapterId))}/challenges`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public learningModuleControllerGetChapterChallengesCount(requestParameters: LearningModuleControllerGetChapterChallengesCountRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<number>;
    public learningModuleControllerGetChapterChallengesCount(requestParameters: LearningModuleControllerGetChapterChallengesCountRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<number>>;
    public learningModuleControllerGetChapterChallengesCount(requestParameters: LearningModuleControllerGetChapterChallengesCountRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<number>>;
    public learningModuleControllerGetChapterChallengesCount(requestParameters: LearningModuleControllerGetChapterChallengesCountRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const learningModuleId = requestParameters.learningModuleId;
        if (learningModuleId === null || learningModuleId === undefined) {
            throw new Error('Required parameter learningModuleId was null or undefined when calling learningModuleControllerGetChapterChallengesCount.');
        }
        const chapterId = requestParameters.chapterId;
        if (chapterId === null || chapterId === undefined) {
            throw new Error('Required parameter chapterId was null or undefined when calling learningModuleControllerGetChapterChallengesCount.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<number>(`${this.configuration.basePath}/learning-modules/${encodeURIComponent(String(learningModuleId))}/chapters/${encodeURIComponent(String(chapterId))}/challenges/count`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public learningModuleControllerGetLearningModule(requestParameters: LearningModuleControllerGetLearningModuleRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<LearningModuleDto>;
    public learningModuleControllerGetLearningModule(requestParameters: LearningModuleControllerGetLearningModuleRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<LearningModuleDto>>;
    public learningModuleControllerGetLearningModule(requestParameters: LearningModuleControllerGetLearningModuleRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<LearningModuleDto>>;
    public learningModuleControllerGetLearningModule(requestParameters: LearningModuleControllerGetLearningModuleRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const learningModuleId = requestParameters.learningModuleId;
        if (learningModuleId === null || learningModuleId === undefined) {
            throw new Error('Required parameter learningModuleId was null or undefined when calling learningModuleControllerGetLearningModule.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<LearningModuleDto>(`${this.configuration.basePath}/learning-modules/${encodeURIComponent(String(learningModuleId))}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public learningModuleControllerGetLearningModuleChapters(requestParameters: LearningModuleControllerGetLearningModuleChaptersRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<ChapterDto>>;
    public learningModuleControllerGetLearningModuleChapters(requestParameters: LearningModuleControllerGetLearningModuleChaptersRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<ChapterDto>>>;
    public learningModuleControllerGetLearningModuleChapters(requestParameters: LearningModuleControllerGetLearningModuleChaptersRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<ChapterDto>>>;
    public learningModuleControllerGetLearningModuleChapters(requestParameters: LearningModuleControllerGetLearningModuleChaptersRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const learningModuleId = requestParameters.learningModuleId;
        if (learningModuleId === null || learningModuleId === undefined) {
            throw new Error('Required parameter learningModuleId was null or undefined when calling learningModuleControllerGetLearningModuleChapters.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<ChapterDto>>(`${this.configuration.basePath}/learning-modules/${encodeURIComponent(String(learningModuleId))}/chapters`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve all the available learning modules
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public learningModuleControllerGetLearningModules(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<LearningModuleDto>>;
    public learningModuleControllerGetLearningModules(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<LearningModuleDto>>>;
    public learningModuleControllerGetLearningModules(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<LearningModuleDto>>>;
    public learningModuleControllerGetLearningModules(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<LearningModuleDto>>(`${this.configuration.basePath}/learning-modules`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public learningModuleControllerGetStep(requestParameters: LearningModuleControllerGetStepRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<StepDto>;
    public learningModuleControllerGetStep(requestParameters: LearningModuleControllerGetStepRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<StepDto>>;
    public learningModuleControllerGetStep(requestParameters: LearningModuleControllerGetStepRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<StepDto>>;
    public learningModuleControllerGetStep(requestParameters: LearningModuleControllerGetStepRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const learningModuleId = requestParameters.learningModuleId;
        if (learningModuleId === null || learningModuleId === undefined) {
            throw new Error('Required parameter learningModuleId was null or undefined when calling learningModuleControllerGetStep.');
        }
        const chapterId = requestParameters.chapterId;
        if (chapterId === null || chapterId === undefined) {
            throw new Error('Required parameter chapterId was null or undefined when calling learningModuleControllerGetStep.');
        }
        const challengeId = requestParameters.challengeId;
        if (challengeId === null || challengeId === undefined) {
            throw new Error('Required parameter challengeId was null or undefined when calling learningModuleControllerGetStep.');
        }
        const stepId = requestParameters.stepId;
        if (stepId === null || stepId === undefined) {
            throw new Error('Required parameter stepId was null or undefined when calling learningModuleControllerGetStep.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<StepDto>(`${this.configuration.basePath}/learning-modules/${encodeURIComponent(String(learningModuleId))}/chapters/${encodeURIComponent(String(chapterId))}/challenges/${encodeURIComponent(String(challengeId))}/steps/${encodeURIComponent(String(stepId))}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public learningModuleControllerMarkStepAsCompleted(requestParameters: LearningModuleControllerMarkStepAsCompletedRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public learningModuleControllerMarkStepAsCompleted(requestParameters: LearningModuleControllerMarkStepAsCompletedRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public learningModuleControllerMarkStepAsCompleted(requestParameters: LearningModuleControllerMarkStepAsCompletedRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public learningModuleControllerMarkStepAsCompleted(requestParameters: LearningModuleControllerMarkStepAsCompletedRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        const learningModuleId = requestParameters.learningModuleId;
        if (learningModuleId === null || learningModuleId === undefined) {
            throw new Error('Required parameter learningModuleId was null or undefined when calling learningModuleControllerMarkStepAsCompleted.');
        }
        const chapterId = requestParameters.chapterId;
        if (chapterId === null || chapterId === undefined) {
            throw new Error('Required parameter chapterId was null or undefined when calling learningModuleControllerMarkStepAsCompleted.');
        }
        const challengeId = requestParameters.challengeId;
        if (challengeId === null || challengeId === undefined) {
            throw new Error('Required parameter challengeId was null or undefined when calling learningModuleControllerMarkStepAsCompleted.');
        }
        const stepId = requestParameters.stepId;
        if (stepId === null || stepId === undefined) {
            throw new Error('Required parameter stepId was null or undefined when calling learningModuleControllerMarkStepAsCompleted.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/learning-modules/${encodeURIComponent(String(learningModuleId))}/chapters/${encodeURIComponent(String(chapterId))}/challenges/${encodeURIComponent(String(challengeId))}/steps/${encodeURIComponent(String(stepId))}/completed`,
            null,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public learningModuleControllerUpdateChallenge(requestParameters: LearningModuleControllerUpdateChallengeRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ChallengeDto>;
    public learningModuleControllerUpdateChallenge(requestParameters: LearningModuleControllerUpdateChallengeRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ChallengeDto>>;
    public learningModuleControllerUpdateChallenge(requestParameters: LearningModuleControllerUpdateChallengeRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ChallengeDto>>;
    public learningModuleControllerUpdateChallenge(requestParameters: LearningModuleControllerUpdateChallengeRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const updateChallengeDto = requestParameters.updateChallengeDto;
        if (updateChallengeDto === null || updateChallengeDto === undefined) {
            throw new Error('Required parameter updateChallengeDto was null or undefined when calling learningModuleControllerUpdateChallenge.');
        }
        const learningModuleId = requestParameters.learningModuleId;
        if (learningModuleId === null || learningModuleId === undefined) {
            throw new Error('Required parameter learningModuleId was null or undefined when calling learningModuleControllerUpdateChallenge.');
        }
        const chapterId = requestParameters.chapterId;
        if (chapterId === null || chapterId === undefined) {
            throw new Error('Required parameter chapterId was null or undefined when calling learningModuleControllerUpdateChallenge.');
        }
        const challengeId = requestParameters.challengeId;
        if (challengeId === null || challengeId === undefined) {
            throw new Error('Required parameter challengeId was null or undefined when calling learningModuleControllerUpdateChallenge.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.patch<ChallengeDto>(`${this.configuration.basePath}/learning-modules/${encodeURIComponent(String(learningModuleId))}/chapters/${encodeURIComponent(String(chapterId))}/challenges/${encodeURIComponent(String(challengeId))}`,
            updateChallengeDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public learningModuleControllerUpdateChapter(requestParameters: LearningModuleControllerUpdateChapterRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ChapterDto>;
    public learningModuleControllerUpdateChapter(requestParameters: LearningModuleControllerUpdateChapterRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ChapterDto>>;
    public learningModuleControllerUpdateChapter(requestParameters: LearningModuleControllerUpdateChapterRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ChapterDto>>;
    public learningModuleControllerUpdateChapter(requestParameters: LearningModuleControllerUpdateChapterRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const updateChapterDto = requestParameters.updateChapterDto;
        if (updateChapterDto === null || updateChapterDto === undefined) {
            throw new Error('Required parameter updateChapterDto was null or undefined when calling learningModuleControllerUpdateChapter.');
        }
        const learningModuleId = requestParameters.learningModuleId;
        if (learningModuleId === null || learningModuleId === undefined) {
            throw new Error('Required parameter learningModuleId was null or undefined when calling learningModuleControllerUpdateChapter.');
        }
        const chapterId = requestParameters.chapterId;
        if (chapterId === null || chapterId === undefined) {
            throw new Error('Required parameter chapterId was null or undefined when calling learningModuleControllerUpdateChapter.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.patch<ChapterDto>(`${this.configuration.basePath}/learning-modules/${encodeURIComponent(String(learningModuleId))}/chapters/${encodeURIComponent(String(chapterId))}`,
            updateChapterDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public learningModuleControllerUpdateLearningModule(requestParameters: LearningModuleControllerUpdateLearningModuleRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<LearningModuleDto>;
    public learningModuleControllerUpdateLearningModule(requestParameters: LearningModuleControllerUpdateLearningModuleRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<LearningModuleDto>>;
    public learningModuleControllerUpdateLearningModule(requestParameters: LearningModuleControllerUpdateLearningModuleRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<LearningModuleDto>>;
    public learningModuleControllerUpdateLearningModule(requestParameters: LearningModuleControllerUpdateLearningModuleRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const updateLearningModuleDto = requestParameters.updateLearningModuleDto;
        if (updateLearningModuleDto === null || updateLearningModuleDto === undefined) {
            throw new Error('Required parameter updateLearningModuleDto was null or undefined when calling learningModuleControllerUpdateLearningModule.');
        }
        const learningModuleId = requestParameters.learningModuleId;
        if (learningModuleId === null || learningModuleId === undefined) {
            throw new Error('Required parameter learningModuleId was null or undefined when calling learningModuleControllerUpdateLearningModule.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.patch<LearningModuleDto>(`${this.configuration.basePath}/learning-modules/${encodeURIComponent(String(learningModuleId))}`,
            updateLearningModuleDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public learningModuleControllerUpdateStep(requestParameters: LearningModuleControllerUpdateStepRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<StepDto>;
    public learningModuleControllerUpdateStep(requestParameters: LearningModuleControllerUpdateStepRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<StepDto>>;
    public learningModuleControllerUpdateStep(requestParameters: LearningModuleControllerUpdateStepRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<StepDto>>;
    public learningModuleControllerUpdateStep(requestParameters: LearningModuleControllerUpdateStepRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const updateStepDto = requestParameters.updateStepDto;
        if (updateStepDto === null || updateStepDto === undefined) {
            throw new Error('Required parameter updateStepDto was null or undefined when calling learningModuleControllerUpdateStep.');
        }
        const learningModuleId = requestParameters.learningModuleId;
        if (learningModuleId === null || learningModuleId === undefined) {
            throw new Error('Required parameter learningModuleId was null or undefined when calling learningModuleControllerUpdateStep.');
        }
        const chapterId = requestParameters.chapterId;
        if (chapterId === null || chapterId === undefined) {
            throw new Error('Required parameter chapterId was null or undefined when calling learningModuleControllerUpdateStep.');
        }
        const challengeId = requestParameters.challengeId;
        if (challengeId === null || challengeId === undefined) {
            throw new Error('Required parameter challengeId was null or undefined when calling learningModuleControllerUpdateStep.');
        }
        const stepId = requestParameters.stepId;
        if (stepId === null || stepId === undefined) {
            throw new Error('Required parameter stepId was null or undefined when calling learningModuleControllerUpdateStep.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.patch<StepDto>(`${this.configuration.basePath}/learning-modules/${encodeURIComponent(String(learningModuleId))}/chapters/${encodeURIComponent(String(chapterId))}/challenges/${encodeURIComponent(String(challengeId))}/steps/${encodeURIComponent(String(stepId))}`,
            updateStepDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
