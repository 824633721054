import { Component, EventEmitter, Input, Output } from "@angular/core";
import { EventContentDto, EventDto } from "@api";

@Component({
  selector: "app-event-detail-component",
  templateUrl: "./event-detail.component.html",
  styleUrls: ["./event-detail.component.scss"],
})
export class EventDetailComponent {
  @Input() public event: EventDto;

  @Output() public joinEvent: EventEmitter<void> = new EventEmitter();
  @Output() public rescheduleCheckup: EventEmitter<EventDto> = new EventEmitter();

  public checkUpType: EventContentDto.TypeEnum = EventContentDto.TypeEnum.CheckUp;
}
