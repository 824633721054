import { RootState } from "..";
import { ActionButtonDto, UserDto } from "@api";
import { DEFAULT_ACTION_BUTTON } from "src/app/constants";
import { createSelector, DefaultProjectorFn, MemoizedSelector } from "@ngrx/store";
import { UserState } from "./state";

const selectFeature = (state: RootState): UserState => state.user;

export const selectLoading = (state: RootState): boolean => state.user.loading;

export const selectAccessToken = (state: RootState): string => state.user.accessToken;

export const selectRefreshToken = (state: RootState): string => state.user.refreshToken;

export const selectUser = (state: RootState): UserDto => state.user.user;

export const selectUserId = (state: RootState): string => state.user.user?.id;

export const selectCustomer = (state: RootState): any => state.user.customer;

export const selectActionButton = (state: RootState): ActionButtonDto => state.user.actionButton || DEFAULT_ACTION_BUTTON;

export const selectEnabledModules = createSelector(selectFeature, state => state.enabledModules);

export const selectCompletedSteps = createSelector(selectFeature, state => state.completedChapterSteps);

export const selectStepCompleted = (stepId: string): MemoizedSelector<RootState, boolean, DefaultProjectorFn<boolean>> =>
  createSelector(selectFeature, state => state.completedChapterSteps.includes(stepId));
