import { createReducer, on } from "@ngrx/store";
import { initialState } from "./state";
import * as NewsActions from "./actions";

export const newsReducer = createReducer(
  initialState,

  on(NewsActions.getNews, NewsActions.likeNews, NewsActions.unlikeNews, state => ({ ...state, loading: true })),

  on(NewsActions.getNewsFailure, NewsActions.likeNewsFailure, NewsActions.unlikeNewsFailure, state => ({ ...state, loading: false })),

  on(NewsActions.getNewsSuccess, (state, { data, page, pageCount, total }) => {
    const entities = {};
    data.forEach(news => (entities[news.id] = news));

    return {
      ...state,
      data: {
        ...state.data,
        ...entities,
      },
      currentPage: page,
      total,
      pageCount,
      loading: false,
    };
  }),

  on(NewsActions.likeNewsSuccess, (state, { newsId, userId }) => ({
    ...state,
    data: {
      ...state.data,
      [newsId]: {
        ...state.data[newsId],
        likes: [...state.data[newsId].likes, { userId }],
      },
    },
    loading: false,
  })),

  on(NewsActions.unlikeNewsSuccess, (state, { newsId, userId }) => ({
    ...state,
    data: {
      ...state.data,
      [newsId]: {
        ...state.data[newsId],
        likes: state.data[newsId].likes.filter(like => like.userId !== userId),
      },
    },
    loading: false,
  })),
);
