import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { IonicModule } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";
import { EventAttendancePageModule } from "src/app/modals/event-attendance/event-attendance.module";
import { EventResponseModule } from "src/app/pipes/event-response/event-response.module";
import { EventResponsePipe } from "src/app/pipes/event-response/event-response.pipe";
import { RRuleNextExecutionModule } from "src/app/pipes/rrule-next-execution/rrule-next-execution.module";
import { RRuleNextExecutionPipe } from "src/app/pipes/rrule-next-execution/rrule-next-execution.pipe";
import { ColorSelectComponent } from "./color-select.component";

@NgModule({
  imports: [CommonModule, IonicModule, TranslateModule, EventResponseModule, RRuleNextExecutionModule, EventAttendancePageModule],
  declarations: [ColorSelectComponent],
  providers: [EventResponsePipe, RRuleNextExecutionPipe],
  exports: [ColorSelectComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ColorSelectModule {}
