import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ToReferentPipe } from "./to-referent.pipe";

@NgModule({
  imports: [CommonModule],
  declarations: [ToReferentPipe],
  exports: [ToReferentPipe],
})
export class ToReferentModule {}
