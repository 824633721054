import * as Sentry from "@sentry/capacitor";
import * as SentryAngular from "@sentry/angular";
import { APP_ID, APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, LOCALE_ID, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";
import { LogoModule } from "src/app/components/logo/logo.module";
import { IonicModule, IonicRouteStrategy, Platform } from "@ionic/angular";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { ActionReducer, MetaReducer, Store, StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { environment } from "../environments/environment";
import { EffectsModule } from "@ngrx/effects";
import { effects, reducers, RootState } from "./store";
import { localStorageSync } from "ngrx-store-localstorage";
import { logoutMetaReducer } from "./store/user/meta-reducer";
import { AuthInterceptor } from "./interceptors/auth/auth.interceptor";
import { appInitializer } from "./app.initializer";
import { EnvironmentService } from "./services/environment/environment.service";
import { PushNotificationsService } from "./services/push-notifications/push-notifications.service";
import { ServiceWorkerModule } from "@angular/service-worker";
import { AngularFireModule } from "@angular/fire/compat";
import {
  AngularFireAnalytics,
  AngularFireAnalyticsModule,
  APP_VERSION,
  DEBUG_MODE,
  ScreenTrackingService,
} from "@angular/fire/compat/analytics";
import { AngularFireMessagingModule } from "@angular/fire/compat/messaging";
import { registerLocaleData } from "@angular/common";
import localeDe from "@angular/common/locales/de";
import { ApiModule, Configuration } from "@api";
import { TranslateLoader, TranslateModule, TranslateModuleConfig, TranslateService } from "@ngx-translate/core";
import { DEFAULT_LANGUAGE, APP_RELEASE } from "./constants";
import { createTranslateLoader } from "./services/i18n/translate-loader";
import { persistedKeys as userPersistedKeys } from "src/app/store/user/state";
import { persistedKeys as eventPersistedKeys } from "./store/event/state";
import { AngularFireStorageModule, BUCKET } from "@angular/fire/compat/storage";
import { StoreRouterConnectingModule } from "@ngrx/router-store";
import { persistedKeys as newsPersistedKeys } from "./store/news/state";
import { persistedKeys as appPersistedKeys } from "./store/app/state";
import { persistedKeys as scenarioPersistedKeys } from "./store/scenario/state";
import { persistedKeys as reportPersistedKeys } from "./store/report/state";
import { persistedKeys as learningModulePersistedKeys } from "./store/learning-module/state";
import { VersionInterceptor } from "./interceptors/version/version.interceptor";
import { ActionButtonPageModule } from "./modals/action-button/action-button.module";
import { PopoverUiPageModule } from "./modals/popover-ui/popover-ui.module";
import { SwipperSurveyPageModule } from "./modals/swipper-survey/swipper-survey.module";
import { EventPageModule } from "./modals/event/event.module";
import { OfflineIndicatorComponentModule } from "./components/offline-indicator/offline-indicator.module";
import { SwipperReportPageModule } from "./modals/swipper-report/swipper-report.module";

const environmentService = new EnvironmentService();

Sentry.init(
  {
    release: `wow-customers-app@${environmentService.get("release")}`,
    dsn: "https://6060739a2a264faf93b615bcc970514d@o146990.ingest.sentry.io/5780143",
    environment: environmentService.get("name"),
    attachStacktrace: true,
    autoSessionTracking: true,
    enabled: environmentService.get("name") !== "local",
  },
  SentryAngular.init,
);

export const localStorageReduxSync = (reducer: ActionReducer<RootState>): ActionReducer<any> =>
  localStorageSync({
    keys: [
      { user: userPersistedKeys },
      { event: eventPersistedKeys },
      { news: newsPersistedKeys },
      { app: appPersistedKeys },
      { scenario: scenarioPersistedKeys },
      { report: reportPersistedKeys },
      { learningModule: learningModulePersistedKeys },
    ],
    rehydrate: true,
    storageKeySerializer: key => `${environmentService.get("name")}_${key}`,
  })(reducer);

const metaReducers: MetaReducer<any, any>[] = [localStorageReduxSync, logoutMetaReducer];

registerLocaleData(localeDe);

export const setApiConfig = (): Configuration => new Configuration({ basePath: environmentService.get("apiPath") });

export const translateModuleConfig: TranslateModuleConfig = {
  defaultLanguage: DEFAULT_LANGUAGE,
  loader: {
    provide: TranslateLoader,
    useFactory: createTranslateLoader,
    deps: [HttpClient],
  },
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot({ mode: "ios" }),
    AppRoutingModule,
    HttpClientModule,
    LogoModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    StoreRouterConnectingModule.forRoot(),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    EffectsModule.forRoot(effects),
    ApiModule.forRoot(setApiConfig),
    TranslateModule.forRoot(translateModuleConfig),
    ServiceWorkerModule.register("/ngsw-worker.js", {
      enabled: environment.production,
      registrationStrategy: "registerWhenStable:30000",
    }),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAnalyticsModule,
    AngularFireMessagingModule,
    AngularFireStorageModule,

    // Global modals
    ActionButtonPageModule,
    PopoverUiPageModule,
    SwipperSurveyPageModule,
    SwipperReportPageModule,
    EventPageModule,
    OfflineIndicatorComponentModule,
  ],
  providers: [
    ScreenTrackingService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: LOCALE_ID,
      deps: [TranslateService],
      useFactory: (translateService: TranslateService): string => translateService.currentLang || DEFAULT_LANGUAGE,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      deps: [Platform, EnvironmentService, PushNotificationsService, AngularFireAnalytics, Store],
      multi: true,
    },
    { provide: ErrorHandler, useValue: SentryAngular.createErrorHandler() },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: VersionInterceptor, multi: true },
    { provide: APP_ID, useValue: "wow-customers-app" },
    { provide: APP_RELEASE, useValue: environmentService.get("release") },
    { provide: APP_VERSION, useValue: environmentService.get("release") },
    { provide: DEBUG_MODE, useValue: !environmentService.get("production") },
    { provide: BUCKET, useValue: environmentService.get("bucket") },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
