import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RRuleNextExecutionPipe } from "./rrule-next-execution.pipe";

@NgModule({
  imports: [CommonModule],
  declarations: [RRuleNextExecutionPipe],
  exports: [RRuleNextExecutionPipe],
})
export class RRuleNextExecutionModule {}
