<ion-content class="popover-ui">
  <div class="popover-ui__container">
    <ion-img *ngIf="popoverUI.imageUrl; else empty" class="popover-ui__container__image" [src]="popoverUI.imageUrl"></ion-img>
    <ng-template #empty><div class="popover-ui__container__image"></div></ng-template>

    <div class="popover-ui__container__content">
      <h2 class="popover-ui__container__content__title" [innerHTML]="popoverUI.title"></h2>
      <h5 class="h2 popover-ui__container__content__text" [innerHTML]="popoverUI.message"></h5>
    </div>

    <div class="popover-ui__container__button">
      <ion-button class="wow-button" [innerHTML]="popoverUI.buttonText" (click)="dismiss()"></ion-button>
    </div>
  </div>
</ion-content>
