import * as qs from "querystring";
import * as UserActions from "src/app/store/user/actions";
import * as AppActions from "src/app/store/app/actions";
import * as FromUser from "src/app/store/user/selectors";
import { Component, HostListener, NgZone, OnInit } from "@angular/core";
import { MenuController, NavController, Platform } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { AppRoutes } from "src/app/constants";
import { RootState } from "./store";
import { Location } from "@angular/common";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { Browser } from "@capacitor/browser";
import { App, AppLaunchUrl } from "@capacitor/app";
import { UserDto } from "@api";
import { Network } from "@capacitor/network";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent implements OnInit {
  public logoImagePath = "./assets/logo-white.png";
  public menuItem: boolean;
  public menuSidePosition: "start" | "end";
  public currentActive: string;
  public routes = AppRoutes;
  public menuItemOpened: number;
  public isSafari: boolean;
  public user$: Observable<UserDto> = this.store.select(FromUser.selectUser);

  constructor(
    private menuController: MenuController,
    private navController: NavController,
    private store: Store<RootState>,
    private router: Router,
    private location: Location,
    public platform: Platform,
    private zone: NgZone,
  ) {
    this.platform.ready().then(() => {
      App.addListener("appUrlOpen", (data: AppLaunchUrl) =>
        this.zone.run(() => {
          const { pathname, search } = new URL(data.url);
          this.navController.navigateRoot(pathname, { queryParams: qs.parse(search) });
        }),
      );
      Network.addListener("networkStatusChange", ({ connected }) => {
        this.store.dispatch(AppActions.updateNetworkConnection({ online: connected }));
      });
    });
  }

  @HostListener("window:load", ["$event"])
  protected onLoad({ currentTarget }): void {
    this.onResize(currentTarget.innerWidth);
  }

  @HostListener("window:resize", ["$event"])
  protected resize({ target }): void {
    this.onResize(target.innerWidth);
  }

  @HostListener("window:beforeinstallprompt", ["$event"])
  protected async beforeinstallprompt(event: any): Promise<void> {
    this.store.dispatch(AppActions.showAndroidInstallPrompt({ event }));
  }

  public ngOnInit(): void {
    this.onResize(window.innerWidth);
    this.isSafari = /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent);
  }

  public toggleAccordionMenu(item: number): void {
    if (this.menuItemOpened === item) {
      this.menuItemOpened = null;
    } else {
      this.menuItemOpened = item;
    }
  }

  public closeMenu(): void {
    this.menuController.close();
  }

  public closeMenuAndSetCurrentAsNull(): void {
    this.currentActive = null;
    this.menuController.close();
  }

  public triggerWebBuilderNavigation(event: Event, url: string): void {
    event.stopPropagation();
    this.closeMenu();
    this.currentActive = url;

    if (this.router.url.includes(this.routes.webBuilder)) {
      this.location.replaceState(`/${this.routes.webBuilder}/${url}`);
    } else {
      this.router.navigate([this.routes.webBuilder, url]);
    }
  }

  public openExternalLink(url: string): void {
    this.closeMenu();
    Browser.open({ url });
  }

  public logout(): void {
    this.closeMenu();
    this.store.dispatch(UserActions.logout());
  }

  protected onResize(innerWidth: number): void {
    this.menuSidePosition = innerWidth > 1113 ? "start" : "end";
  }
}
