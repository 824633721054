import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SurveyFieldComponent } from "./survey-field.component";
import { BackgroundImageDirectiveModule } from "src/app/directives/background-image/background-image.module";
import { IonicModule } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
  declarations: [SurveyFieldComponent],
  imports: [CommonModule, IonicModule, TranslateModule, BackgroundImageDirectiveModule],
  exports: [SurveyFieldComponent],
})
export class SurveyFieldModule {}
