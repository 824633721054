import * as ScenarioActions from "./actions";
import { createReducer, on } from "@ngrx/store";
import { initialState } from "./state";

export const scenarioReducer = createReducer(
  initialState,

  on(
    ScenarioActions.getActions,
    ScenarioActions.getChanceNodes,
    ScenarioActions.getTriggers,
    ScenarioActions.runScenario,
    ScenarioActions.getScenarioRuns,
    ScenarioActions.getScenarioRunLogs,
    ScenarioActions.createChanceNode,
    ScenarioActions.updateChanceNode,
    ScenarioActions.deleteChanceNode,
    ScenarioActions.createChanceNodeOnScenario,
    ScenarioActions.updateChanceNodeOnScenario,
    ScenarioActions.deleteChanceNodeOnScenario,
    ScenarioActions.addActionToChanceNode,
    ScenarioActions.editChanceNodeAction,
    ScenarioActions.deleteChanceNodeAction,
    state => ({ ...state, loading: true }),
  ),

  on(
    ScenarioActions.getActionsFailure,
    ScenarioActions.getChanceNodesFailure,
    ScenarioActions.getTriggersFailure,
    ScenarioActions.runScenarioFailure,
    ScenarioActions.getScenarioRunsFailure,
    ScenarioActions.getScenarioRunLogsFailure,
    ScenarioActions.createChanceNodeFailure,
    ScenarioActions.updateChanceNodeFailure,
    ScenarioActions.deleteChanceNodeFailure,
    ScenarioActions.createChanceNodeOnScenarioFailure,
    ScenarioActions.updateChanceNodeOnScenarioFailure,
    ScenarioActions.deleteChanceNodeOnScenarioFailure,
    ScenarioActions.addActionToChanceNodeFailure,
    ScenarioActions.editChanceNodeActionFailure,
    ScenarioActions.deleteChanceNodeActionFailure,
    state => ({ ...state, loading: false }),
  ),

  on(ScenarioActions.getActionsSuccess, (state, { actions }) => ({ ...state, loading: false, actions })),

  on(ScenarioActions.getTriggersSuccess, (state, { triggers }) => ({ ...state, loading: false, triggers })),

  on(ScenarioActions.getChanceNodesSuccess, (state, { chanceNodes }) => {
    const entities = {};
    chanceNodes.data.forEach(chanceNode => (entities[chanceNode.id] = chanceNode));

    return {
      ...state,
      loading: false,
      chanceNodes: {
        ...(state.chanceNodes || {}),
        ...entities,
      },
    };
  }),

  on(
    ScenarioActions.createChanceNodeSuccess,
    ScenarioActions.updateChanceNodeSuccess,
    ScenarioActions.createChanceNodeOnScenarioSuccess,
    ScenarioActions.updateChanceNodeOnScenarioSuccess,
    (state, { chanceNode }) => ({
      ...state,
      loading: false,
      chanceNodes: {
        ...state.chanceNodes,
        [chanceNode.id]: {
          ...chanceNode,
          actions: state.chanceNodes[chanceNode.id]?.actions || [],
        },
      },
    }),
  ),

  on(ScenarioActions.deleteChanceNodeSuccess, ScenarioActions.deleteChanceNodeOnScenarioSuccess, (state, { chanceNodeId }) => {
    const entities = {};
    Object.values(state.chanceNodes)
      .filter(chanceNode => chanceNode.id !== chanceNodeId)
      .forEach(chanceNode => (entities[chanceNode.id] = chanceNode));

    return {
      ...state,
      chanceNodes: entities,
      loading: false,
    };
  }),

  on(ScenarioActions.getScenarioRunsSuccess, (state, { chanceNodeId, runs }) => ({
    ...state,
    runs: {
      ...state.runs,
      [chanceNodeId]: {
        ...(state.runs[chanceNodeId] || {}),
        ...runs,
        data: [
          ...(state.runs[chanceNodeId]?.data || []),
          ...runs.data.filter(run => !state.runs[chanceNodeId]?.data?.some(r => r.runId === run.runId)),
        ],
      },
    },
    loading: false,
  })),

  on(ScenarioActions.getScenarioRunLogsSuccess, (state, { chanceNodeId, logs, runId }) => {
    const runLogs = state.logs[chanceNodeId] && state.logs[chanceNodeId][runId] ? { ...state.logs[chanceNodeId][runId] } : { data: [] };

    if (runLogs.data.length) {
      const missingLogs = logs.data.filter(log => !state.logs[chanceNodeId][runId].data.some(l => l.id === log.id));
      runLogs.data = [...state.logs[chanceNodeId][runId].data, ...missingLogs];
    } else {
      runLogs.data = logs.data;
    }

    return {
      ...state,
      logs: {
        ...state.logs,
        [chanceNodeId]: {
          ...(state.logs[chanceNodeId] || {}),
          [runId]: {
            ...logs,
            ...runLogs,
            page: logs.page,
            pageCount: logs.pageCount,
          },
        },
      },
      loading: false,
    };
  }),

  on(ScenarioActions.cleanRunLogs, (state, { chanceNodeId }) => ({
    ...state,
    logs: {
      ...state.logs,
      [chanceNodeId]: {
        ...(state.logs[chanceNodeId] || {}),
        [0]: {
          data: [],
          page: 0,
          pageCount: 0,
          count: 0,
          total: 0,
        },
      },
    },
  })),

  on(ScenarioActions.runScenarioSuccess, (state, { chanceNodeId, runId, logs }) => ({
    ...state,
    logs: {
      ...state.logs,
      [chanceNodeId]: {
        ...(state.logs[chanceNodeId] || {}),
        [runId]: {
          data: logs,
          page: 1,
          pageCount: 1,
          count: 0,
          total: 0,
        },
      },
    },
    loading: false,
  })),

  on(ScenarioActions.addActionToChanceNodeSuccess, (state, { chanceNodeAction, chanceNodeId }) => {
    const chanceNode = { ...state.chanceNodes[chanceNodeId], actions: [...state.chanceNodes[chanceNodeId].actions] };
    chanceNode.actions.push(chanceNodeAction);

    return {
      ...state,
      chanceNodes: {
        ...state.chanceNodes,
        [chanceNodeId]: chanceNode,
      },
      loading: false,
    };
  }),

  on(ScenarioActions.editChanceNodeActionSuccess, (state, { chanceNodeId, chanceNodeAction }) => {
    const chanceNode = { ...state.chanceNodes[chanceNodeId], actions: [...state.chanceNodes[chanceNodeId].actions] };
    chanceNode.actions.splice(
      chanceNode.actions.findIndex(action => action.id === chanceNodeAction.id),
      1,
      chanceNodeAction,
    );

    return {
      ...state,
      chanceNodes: {
        ...state.chanceNodes,
        [chanceNodeId]: chanceNode,
      },
      loading: false,
    };
  }),

  on(ScenarioActions.deleteChanceNodeActionSuccess, (state, { chanceNodeActionId, chanceNodeId }) => ({
    ...state,
    chanceNodes: {
      ...state.chanceNodes,
      [chanceNodeId]: {
        ...state.chanceNodes[chanceNodeId],
        actions: state.chanceNodes[chanceNodeId].actions.filter(action => action.id !== chanceNodeActionId),
      },
    },
    loading: false,
  })),
);
