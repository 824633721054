import { SurveyDefinitionDto, SurveyDto, SurveyFieldDto, SurveyResponseDto } from "@api";
import { createSelector, DefaultProjectorFn, MemoizedSelector } from "@ngrx/store";
import { sortByName } from "src/app/utils/sort-by-name";
import { sortByOrder } from "src/app/utils/sort-by-order";
import { RootState } from "..";
import { SurveyState } from "./state";

const selectFeature = (state: RootState): SurveyState => state.survey;

export const selectLoading = createSelector(selectFeature, state => state.loading);

export const selectSurveyErrors = createSelector(selectFeature, state => state.localSurveyErrors);

export const selectDefinitionBySlug = (
  slug: string,
): MemoizedSelector<RootState, SurveyDefinitionDto, DefaultProjectorFn<SurveyDefinitionDto>> =>
  createSelector(selectFeature, state => Object.values(state.definitions).find(definition => definition.slug === slug));

export const selectDefinitionById = (
  id: string,
): MemoizedSelector<RootState, SurveyDefinitionDto, DefaultProjectorFn<SurveyDefinitionDto>> =>
  createSelector(selectFeature, state => state.definitions[id]);

export const selectDefinitionFieldsBySlug = (
  slug: string,
): MemoizedSelector<RootState, SurveyFieldDto[], DefaultProjectorFn<SurveyFieldDto[]>> =>
  createSelector(selectFeature, state =>
    [...(Object.values(state.definitions).find(definition => definition.slug === slug)?.surveyFields || [])].sort(sortByOrder),
  );

export const selectDefinitionField = (
  definitionId: string,
  fieldId: string,
): MemoizedSelector<RootState, SurveyFieldDto, DefaultProjectorFn<SurveyFieldDto>> =>
  createSelector(selectFeature, state => state.definitions[definitionId]?.surveyFields.find(surveyField => surveyField.id === fieldId));

export const selectDefinitionFieldsById = (
  definitionId: string,
): MemoizedSelector<RootState, SurveyFieldDto[], DefaultProjectorFn<SurveyFieldDto[]>> =>
  createSelector(selectFeature, state => [...(state.definitions[definitionId]?.surveyFields || [])].sort(sortByOrder));

export const selectSurveyByDefinitionSlug = (slug: string): MemoizedSelector<RootState, SurveyDto, DefaultProjectorFn<SurveyDto>> =>
  createSelector(selectFeature, state => {
    const definition = Object.values(state.definitions).find(d => d.slug === slug);
    return Object.values(state.surveys).find(survey => survey.definitionId === definition?.id);
  });

export const selectSurveyDefinitions = createSelector(selectFeature, state => Object.values(state.definitions).sort(sortByName));

export const selectSurveyById = (id: string): MemoizedSelector<RootState, SurveyDto, DefaultProjectorFn<SurveyDto>> =>
  createSelector(selectFeature, state => state.surveys[id]);

export const selectSurveyResponsesBySurveyId = (
  surveyId: string,
): MemoizedSelector<RootState, SurveyResponseDto[], DefaultProjectorFn<SurveyResponseDto[]>> =>
  createSelector(selectFeature, state => state.responses[surveyId]);

export const selectSurveyFieldResponse = (
  fieldId: string,
): MemoizedSelector<RootState, SurveyResponseDto, DefaultProjectorFn<SurveyResponseDto>> =>
  createSelector(selectFeature, state =>
    Object.values(state.responses)
      .flat()
      .find(surveyResponse => surveyResponse.surveyFieldId === fieldId),
  );
