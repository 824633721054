import { Pipe, PipeTransform } from "@angular/core";
import { EventUserDto } from "@api";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { RootState } from "src/app/store";
import * as FromEvent from "src/app/store/event/selectors";

@Pipe({ name: "toEventParticipants" })
export class ToEventParticipantsPipe implements PipeTransform {
  constructor(private store: Store<RootState>) {}

  public transform(eventId: string): Observable<EventUserDto[]> {
    return this.store.select(FromEvent.selectEventParticipantsByEventId(eventId));
  }
}
