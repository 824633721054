import { Injectable } from "@angular/core";
import { Actions, concatLatestFrom, createEffect, ofType } from "@ngrx/effects";
import { Observable, of } from "rxjs";
import { catchError, map, mergeMap, tap } from "rxjs/operators";
import * as EventActions from "./actions";
import * as FromEvent from "./selectors";
import { EventContentDto, EventsService } from "@api";
import { HttpErrorResponse } from "@angular/common/http";
import { AlertController, ModalController, NavController, ToastController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { AppRoutes, EVENTS_PER_PAGE, TOAST_DEFAULT_DURATION } from "src/app/constants";
import { Action, Store } from "@ngrx/store";
import { RootState } from "..";

@Injectable()
export class EventEffects {
  public createEventContent$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(EventActions.createEventContent),
      mergeMap(({ createEventContentDto }) =>
        this.eventService.eventControllerCreateEventContent({ createEventContentDto }).pipe(
          map(eventContent => EventActions.createEventContentSuccess({ eventContent })),
          catchError((error: HttpErrorResponse) => of(EventActions.createEventContentFailure({ reason: error.error?.message }))),
        ),
      ),
    ),
  );

  public createEventContentSuccess$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EventActions.createEventContentSuccess),
        tap(async ({ eventContent }) => await this.navController.navigateForward([AppRoutes.eventContents, eventContent.id])),
      ),
    { dispatch: false },
  );

  public createEventContentFailure$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EventActions.createEventContentFailure),
        tap(async ({ reason }) => await this.showErrorToast(reason)),
      ),
    { dispatch: false },
  );

  public getEventContentFailure$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EventActions.getEventContentFailure),
        tap(async ({ reason }) => await this.showErrorToast(reason)),
      ),
    { dispatch: false },
  );

  public updateEventContent$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(EventActions.updateEventContent),
      mergeMap(({ contentId, updateEventContentDto }) =>
        this.eventService.eventControllerUpdateEventContent({ contentId, updateEventContentDto }).pipe(
          map(eventContent => EventActions.updateEventContentSuccess({ eventContent })),
          catchError((error: HttpErrorResponse) => of(EventActions.updateEventContentFailure({ reason: error.error?.message }))),
        ),
      ),
    ),
  );

  public updateEventContentSuccess$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EventActions.updateEventContentSuccess),
        tap(async ({ eventContent }) => await this.navController.navigateBack([AppRoutes.eventContents, eventContent.id])),
      ),
    { dispatch: false },
  );

  public updateEventContentFailure$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EventActions.updateEventContentFailure),
        tap(async ({ reason }) => await this.showErrorToast(reason)),
      ),
    { dispatch: false },
  );

  public getEventContents$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(EventActions.getEventContents),
      mergeMap(({ limit, page }) =>
        this.eventService.eventControllerGetManyEventContents({ limit, page }).pipe(
          map(eventContents => EventActions.getEventContentsSuccess(eventContents)),
          catchError((error: HttpErrorResponse) => of(EventActions.getEventContentsFailure({ reason: error.error?.message }))),
        ),
      ),
    ),
  );

  public getEventContentsFailure$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EventActions.getEventContentsFailure),
        tap(async ({ reason }) => await this.showErrorToast(reason)),
      ),
    { dispatch: false },
  );

  public getEventContent$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(EventActions.getEventContent),
      mergeMap(({ contentId }) =>
        this.eventService.eventControllerGetEventContent({ contentId }).pipe(
          map(eventContent => EventActions.getEventContentSuccess({ eventContent })),
          catchError((error: HttpErrorResponse) => of(EventActions.getEventContentFailure({ reason: error.error?.message }))),
        ),
      ),
    ),
  );

  public removeEventContent$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(EventActions.removeEventContent),
      mergeMap(({ contentId }) =>
        this.eventService.eventControllerDeleteEventContent({ contentId }).pipe(
          map(() => EventActions.removeEventContentSuccess({ contentId })),
          catchError((error: HttpErrorResponse) => of(EventActions.removeEventContentFailure({ reason: error.error?.message }))),
        ),
      ),
    ),
  );

  public removeEventContentSuccess$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EventActions.removeEventContentSuccess),
        tap(async () => await this.navController.navigateBack([AppRoutes.eventContents])),
      ),
    { dispatch: false },
  );

  public removeEventContentFailure$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EventActions.removeEventContentFailure),
        tap(async ({ reason }) => await this.showErrorToast(reason)),
      ),
    { dispatch: false },
  );

  public getEvents$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(EventActions.getEvents),
      mergeMap(({ limit, page, event }) =>
        this.eventService.eventControllerGetManyEvents({ limit, page }).pipe(
          mergeMap(response => [
            EventActions.getEventsSuccess({ ...response, event }),
            ...response.data.map(singleEvent => EventActions.getEventInvitation({ eventId: singleEvent.id })),
            ...[...new Set(response.data.map(singleEvent => EventActions.getEventContent({ contentId: singleEvent.contentId })))],
            EventActions.getEventParticipants({ eventIds: [...new Set(response.data.map(singleEvent => singleEvent.id))] }),
          ]),
          catchError((error: HttpErrorResponse) => of(EventActions.getEventsFailure({ reason: error.error?.message }))),
        ),
      ),
    ),
  );

  public getEventsSuccess$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EventActions.getEventsSuccess),
        tap(({ event }) => event?.target.complete()),
      ),
    { dispatch: false },
  );

  public getEventParticipants$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(EventActions.getEventParticipants),
      mergeMap(({ eventIds }) =>
        this.eventService.eventControllerGetEventParticipants({ eventIds }).pipe(
          map(({ data }) => EventActions.getEventParticipantsSuccess({ data })),
          catchError((error: HttpErrorResponse) => of(EventActions.getEventParticipantsFailure({ reason: error.error?.message }))),
        ),
      ),
    ),
  );

  public getEventContentEvents$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(EventActions.getEventContentEvents),
      mergeMap(({ contentId }) =>
        this.eventService.eventControllerGetContentEvents({ contentId }).pipe(
          map(events => EventActions.getEventContentEventsSuccess({ events })),
          catchError((error: HttpErrorResponse) => of(EventActions.getEventContentEventsFailure({ reason: error.error?.message }))),
        ),
      ),
    ),
  );

  public getEventContentEventsFailure$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EventActions.getEventContentEventsFailure),
        tap(async ({ reason }) => await this.showErrorToast(reason)),
      ),
    { dispatch: false },
  );

  public getEvent$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(EventActions.getEvent),
      mergeMap(({ eventId, fetchNested }) =>
        this.eventService.eventControllerGetEvent({ eventId }).pipe(
          mergeMap(event => {
            const actions: Action[] = [
              EventActions.getEventSuccess({ event }),
              EventActions.getEventInvitation({ eventId: event.id }),
              EventActions.getEventParticipants({ eventIds: [event.id] }),
            ];
            if (fetchNested) {
              actions.push(EventActions.getEventContent({ contentId: event.contentId }));
            }
            return actions;
          }),
          catchError((error: HttpErrorResponse) => of(EventActions.getEventFailure({ reason: error.error?.message }))),
        ),
      ),
    ),
  );

  public createEvent$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(EventActions.createEvent),
      mergeMap(({ createEventDto }) =>
        this.eventService.eventControllerCreateEvent({ createEventDto }).pipe(
          map(event => EventActions.createEventSuccess({ event })),
          catchError((error: HttpErrorResponse) => of(EventActions.createEventFailure({ reason: error.error?.message }))),
        ),
      ),
    ),
  );

  public createEventSuccess$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EventActions.createEventSuccess),
        tap(({ event }) => this.navController.navigateBack([AppRoutes.eventContents, event.contentId])),
      ),
    { dispatch: false },
  );

  public createEventFailure$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EventActions.createEventFailure),
        tap(async ({ reason }) => await this.showErrorToast(reason)),
      ),
    { dispatch: false },
  );

  public updateEvent$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(EventActions.updateEvent),
      mergeMap(({ eventId, updateEventDto }) =>
        this.eventService.eventControllerUpdateEvent({ eventId, updateEventDto }).pipe(
          map(event => EventActions.updateEventSuccess({ event })),
          catchError((error: HttpErrorResponse) => of(EventActions.updateEventFailure({ reason: error.error?.message }))),
        ),
      ),
    ),
  );

  public updateEventSuccess$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EventActions.updateEventSuccess),
        tap(({ event }) => this.navController.navigateForward([AppRoutes.eventContents, event.contentId])),
      ),
    { dispatch: false },
  );

  public updateEventFailure$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EventActions.updateEventFailure),
        tap(async ({ reason }) => await this.showErrorToast(reason)),
      ),
    { dispatch: false },
  );

  public removeEvent$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(EventActions.removeEvent),
      mergeMap(({ eventId }) =>
        this.eventService.eventControllerDeleteEvent({ eventId }).pipe(
          map(() => EventActions.removeEventSuccess({ eventId })),
          catchError((error: HttpErrorResponse) => of(EventActions.removeEventFailure({ reason: error.error?.message }))),
        ),
      ),
    ),
  );

  public removeEventFailure$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EventActions.removeEventFailure),
        tap(async ({ reason }) => await this.showErrorToast(reason)),
      ),
    { dispatch: false },
  );

  public getEventInvitation$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(EventActions.getEventInvitation),
      mergeMap(({ eventId }) =>
        this.eventService.eventControllerGetUserInvitationsForEvent({ eventId }).pipe(
          map(invitation => EventActions.getEventInvitationSuccess({ invitation })),
          catchError((error: HttpErrorResponse) => of(EventActions.getEventInvitationFailure({ reason: error.error?.message }))),
        ),
      ),
    ),
  );

  public getEventInvitationFailure$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EventActions.getEventInvitationFailure),
        tap(async ({ reason }) => await this.showErrorToast(reason)),
      ),
    { dispatch: false },
  );

  public respondToEvent$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(EventActions.respondToEvent),
      concatLatestFrom(({ eventId, updateEventUserDto }) =>
        this.store.select(FromEvent.selectEventInvitation(eventId, updateEventUserDto.eventExecution)),
      ),
      mergeMap(([{ eventId, updateEventUserDto }, existing]) =>
        existing?.status === updateEventUserDto.status
          ? []
          : this.eventService.eventControllerRespondToInvitation({ eventId, updateEventUserDto }).pipe(
              mergeMap(invitation => [
                EventActions.respondToEventSuccess({ invitation }),
                EventActions.getEventParticipants({ eventIds: [eventId] }),
              ]),
              catchError((error: HttpErrorResponse) => {
                if (error.status === 423) {
                  return of(EventActions.respondToEventClosed());
                }
                if (error.status === 412) {
                  return of(EventActions.respondToEventFull(), EventActions.getEvents({ limit: EVENTS_PER_PAGE, page: 1 }));
                }
                if (error.status === 403) {
                  return of(EventActions.respondToEventForbidden({ eventId }));
                }
                return of(EventActions.respondToEventFailure({ reason: error.error?.message }));
              }),
            ),
      ),
    ),
  );

  public respondToEventForbidden$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EventActions.respondToEventForbidden),
        concatLatestFrom(({ eventId }) => this.store.select(FromEvent.selectEventById(eventId))),
        concatLatestFrom(([, event]) => this.store.select(FromEvent.selectContentById(event.contentId))),
        tap(([, content]) => this.showEventForbiddenAlert(content)),
      ),
    { dispatch: false },
  );

  public respondToEventClosed$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EventActions.respondToEventClosed),
        tap(() => this.showEventClosedAlert()),
      ),
    { dispatch: false },
  );

  public respondToEventFull$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EventActions.respondToEventFull),
        tap(() => this.showEventFullAlert()),
      ),
    { dispatch: false },
  );

  public respondToEventFailure$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EventActions.respondToEventFailure),
        tap(async ({ reason }) => await this.showErrorToast(reason)),
      ),
    { dispatch: false },
  );

  public getReferents$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(EventActions.getReferents),
      mergeMap(() =>
        this.eventService.eventControllerGetReferents().pipe(
          map(referents => EventActions.getReferentsSuccess({ referents })),
          catchError((error: HttpErrorResponse) => of(EventActions.getReferentsFailure({ reason: error.error?.message }))),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store<RootState>,
    private eventService: EventsService,
    private navController: NavController,
    private toastController: ToastController,
    private alertController: AlertController,
    private modalController: ModalController,
    private translateService: TranslateService,
  ) {}

  private async showErrorToast(message: string): Promise<void> {
    const toast = await this.toastController.create({
      color: "danger",
      message: this.translateService.instant((message || "DEFAULT_ERROR_MESSAGE").toString()),
      duration: TOAST_DEFAULT_DURATION,
    });
    await toast.present();
  }

  private async showEventForbiddenAlert(content: EventContentDto): Promise<void> {
    const name = content.title;
    const modules = content.learningModules.map(learningModule => learningModule.name).join(", ");

    if (!modules) return;

    const alert = await this.alertController.create({
      header: "Jetzt upgraden!",
      message: `Das Webinar <b>${name}</b> ist Teil des Moduls <b>${modules}</b>. Wenn du auch diese Inhalte nutzen möchtest, hast du jetzt die Gelegenheit, dein Paket um dieses Modul zu ergänzen und ein Upgrade zu buchen.`,
      buttons: [
        { text: "zurück", role: "cancel" },
        {
          text: "Jetzt upgraden!",
          handler: async (): Promise<void> => {
            const modal = await this.modalController.getTop();
            modal?.dismiss();
            this.navController.navigateForward([AppRoutes.askQuestion]);
          },
        },
      ],
      backdropDismiss: false,
    });
    await alert.present();
  }

  private async showEventClosedAlert(): Promise<void> {
    const alert = await this.alertController.create({
      header: "Anmeldeschluss abgelaufen",
      message: "Der Anmeldeschluss für diese Veranstaltung ist bereits abgelaufen.",
      buttons: [{ text: "verstanden", role: "cancel" }],
      backdropDismiss: false,
    });
    await alert.present();
  }

  private async showEventFullAlert(): Promise<void> {
    const alert = await this.alertController.create({
      header: "Veranstaltung ausgebucht",
      message:
        "Diese Veranstaltung ist leider bereits ausgebucht. Eine Anmeldung ist nicht mehr möglich. Bitte schauen sie im Veranstaltungskalender nach zukünftigen Terminen zu diesem Thema. Vielen Dank und viel Erfolg!",
      buttons: [{ text: "verstanden", role: "cancel" }],
      backdropDismiss: false,
    });
    await alert.present();
  }
}
