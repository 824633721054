/**
 * Wow family api
 * The Wow family API description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SurveyFieldDto { 
    createdAt: string;
    updatedAt: string;
    id: string;
    definitionId: string;
    dataDefinitionId: string;
    type: SurveyFieldDto.TypeEnum;
    question: string;
    description: string;
    order: number;
    required: boolean;
    options: Array<string>;
    defaultValue: string;
    imageUrl: string;
    min: number;
    max: number;
    helpText: string;
    helpUrl: string;
}
export namespace SurveyFieldDto {
    export type TypeEnum = 'boolean' | 'date' | 'datetime' | 'email' | 'json' | 'multiselect' | 'number' | 'range' | 'rating' | 'select' | 'tel' | 'text' | 'textarea' | 'time' | 'url';
    export const TypeEnum = {
        Boolean: 'boolean' as TypeEnum,
        Date: 'date' as TypeEnum,
        Datetime: 'datetime' as TypeEnum,
        Email: 'email' as TypeEnum,
        Json: 'json' as TypeEnum,
        Multiselect: 'multiselect' as TypeEnum,
        Number: 'number' as TypeEnum,
        Range: 'range' as TypeEnum,
        Rating: 'rating' as TypeEnum,
        Select: 'select' as TypeEnum,
        Tel: 'tel' as TypeEnum,
        Text: 'text' as TypeEnum,
        Textarea: 'textarea' as TypeEnum,
        Time: 'time' as TypeEnum,
        Url: 'url' as TypeEnum
    };
}


