import * as UserActions from "./actions";
import * as AppActions from "src/app/store/app/actions";
import * as SurveyActions from "src/app/store/survey/actions";
import * as FromUser from "./selectors";
import * as FromApp from "src/app/store/app/selectors";
import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AlertController, MenuController, ModalController, NavController, ToastController } from "@ionic/angular";
import { Actions, concatLatestFrom, createEffect, ofType } from "@ngrx/effects";
import { Observable, of } from "rxjs";
import { catchError, filter, map, mergeMap, tap } from "rxjs/operators";
import { AppRoutes, Miliseconds, TOAST_DEFAULT_DURATION } from "src/app/constants";
import { AngularFireAnalytics } from "@angular/fire/compat/analytics";
import { ActionButtonDto, ActionButtonsService, AuthService, PopoverUIDto, PopoverUIService, PushTokensService, UsersService } from "@api";
import { TranslateService } from "@ngx-translate/core";
import { Action, Store } from "@ngrx/store";
import { RootState } from "..";
import { ActionButtonPage } from "src/app/modals/action-button/action-button.page";
import { PopoverUiPage } from "src/app/modals/popover-ui/popover-ui.page";

@Injectable()
export class UserEffects {
  public checkEmailSuccess$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.checkEmailSuccess),
        tap(async ({ email, existsOnKajabi, existsOnWow, registered }) => {
          if (registered) {
            this.navController.navigateForward([AppRoutes.login], { queryParams: { email } });
          } else if (existsOnKajabi && existsOnWow) {
            this.navController.navigateForward([AppRoutes.register], { queryParams: { email } });
          } else {
            const alert = await this.alertController.create({
              header: "Ihre E-Mail Adresse wurde nicht gefunden",
              message:
                "Bitte verwenden sie die Adresse, welche Sie bei der wow family angegeben haben. Sollte dies nicht funktionieren, kontaktieren sie uns bitte über den “Hilfe”- Link",
              buttons: [{ text: "Ok" }],
              backdropDismiss: false,
            });
            await alert.present();
          }
        }),
      ),
    { dispatch: false },
  );

  public register$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.register),
      mergeMap(({ registerDto }) =>
        this.authService.authControllerCheckExistsEmail({ email: registerDto.email }).pipe(
          mergeMap(response =>
            response.registered
              ? [UserActions.login({ login: { username: registerDto.email, password: registerDto.password } })]
              : this.authService.authControllerRegister({ registerDto }).pipe(
                  map(() => UserActions.registerSuccess({ registerDto })),
                  catchError((error: HttpErrorResponse) => of(UserActions.registerFailure({ reason: error.error?.message }))),
                ),
          ),
          catchError((error: HttpErrorResponse) => of(UserActions.checkEmailFailure({ reason: error.error?.message }))),
        ),
      ),
    ),
  );

  public registerSuccess$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.registerSuccess),
      map(({ registerDto: { email, password } }) => UserActions.login({ login: { username: email, password } })),
    ),
  );

  public confirmRegistration$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.confirmRegistration),
      mergeMap(() =>
        this.authService.authControllerConfirmEmail().pipe(
          mergeMap(tokens => [
            UserActions.setAccessToken({ accessToken: tokens.accessToken }),
            UserActions.setRefreshToken({ refreshToken: tokens.refreshToken }),
            UserActions.loadUser(),
            UserActions.loadCustomer(),
            UserActions.confirmRegistrationSuccess(),
          ]),
          catchError((error: HttpErrorResponse) => of(UserActions.confirmRegistrationFailure({ reason: error.error?.message }))),
        ),
      ),
    ),
  );

  public confirmRegistrationSuccess$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.confirmRegistrationSuccess),
        tap(async () => {
          await this.navController.navigateRoot([AppRoutes.home]);
          await this.menu.enable(true);
        }),
      ),
    { dispatch: false },
  );

  public login$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.login),
      mergeMap(({ login }) =>
        this.authService.authControllerCheckExistsEmail({ email: login.username }).pipe(
          mergeMap(response =>
            response.registered
              ? this.authService.authControllerLogin({ login }).pipe(
                  mergeMap(tokens => [
                    UserActions.setAccessToken({ accessToken: tokens.accessToken }),
                    UserActions.setRefreshToken({ refreshToken: tokens.refreshToken }),
                    UserActions.loginSuccess(),
                    UserActions.loadUser(),
                    UserActions.loadCustomer(),
                    UserActions.getActionButton(),
                    UserActions.getPopoverUI(),
                  ]),
                  catchError((error: HttpErrorResponse) => of(UserActions.loginFailure({ status: error.status }))),
                )
              : [UserActions.checkEmailSuccess({ ...response, email: login.username })],
          ),
          catchError((error: HttpErrorResponse) => of(UserActions.checkEmailFailure({ reason: error.error?.message }))),
        ),
      ),
    ),
  );

  public loginSuccess$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.loginSuccess),
        tap(async () => {
          await this.navController.navigateRoot([AppRoutes.home]);
          await this.menu.enable(true);
        }),
      ),
    { dispatch: false },
  );

  public loginFailure$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.loginFailure),
        tap(async ({ status }) => {
          if (status === 412) {
            const alert = await this.alertController.create({
              header: "Email- Adresse bestätigen",
              message:
                "Bitte öffnen Sie ihren Email- Eingang und bestätigen Sie die Email, welche wir ihnen gesendet haben mit einem Click auf den “bestätigen” Button",
              buttons: [{ text: "Ok" }],
              backdropDismiss: false,
            });
            await alert.present();
          } else {
            this.showErrorToast("Der Benutzername oder das Passwort ist nicht korrekt.");
          }
        }),
      ),
    { dispatch: false },
  );

  public loadUser$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loadUser),
      mergeMap(() =>
        this.userService.userControllerGetSelf().pipe(
          map(user => UserActions.loadUserSuccess({ user })),
          catchError((error: HttpErrorResponse) => of(UserActions.loadUserFailure({ reason: error.error?.message }))),
        ),
      ),
    ),
  );

  public loadUserSuccess$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.loadUserSuccess),
        tap(async ({ user }) => {
          await this.analyticsService.setUserId(user.id);
          this.store.dispatch(UserActions.getActionButton());
          this.store.dispatch(UserActions.getPopoverUI());
          this.store.dispatch(SurveyActions.getAssignedSurveys());
        }),
      ),
    { dispatch: false },
  );

  public loadCustomer$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loadCustomer),
      mergeMap(() =>
        this.userService.userControllerGetSelfLegacy({}).pipe(
          map(customer => UserActions.loadCustomerSuccess({ customer })),
          catchError((error: HttpErrorResponse) => of(UserActions.loadCustomerFailure({ reason: error.error?.message }))),
        ),
      ),
    ),
  );

  public requestResetPassword$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.requestResetPassword),
      mergeMap(({ email }) =>
        this.authService.authControllerGetResetPasswordToken({ email }).pipe(
          map(() => UserActions.requestResetPasswordSuccess()),
          catchError((error: HttpErrorResponse) => of(UserActions.requestResetPasswordFailure({ reason: error.error?.message, email }))),
        ),
      ),
    ),
  );

  public requestResetPasswordSuccess$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.requestResetPasswordSuccess),
        tap(async () => {
          const alert = await this.alertController.create({
            header: "Passwortrücksetzung erfolgreich beantragt",
            message: "Ein Link zum Zurücksetzen des Passworts wurde an Ihre E-Mail gesendet. Bitte überprüfen Sie Ihren Posteingang.",
            buttons: [{ text: "Ok" }],
            backdropDismiss: false,
          });
          alert.present();
        }),
      ),
    { dispatch: false },
  );

  public resetPassword: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.resetPassword),
      mergeMap(({ passwordDto }) =>
        this.authService.authControllerResetPassword({ passwordDto }).pipe(
          mergeMap(tokens => [
            UserActions.setAccessToken({ accessToken: tokens.accessToken }),
            UserActions.setRefreshToken({ refreshToken: tokens.refreshToken }),
            UserActions.resetPasswordSuccess(),
            UserActions.loadUser(),
            UserActions.loadCustomer(),
          ]),
          catchError((error: HttpErrorResponse) => of(UserActions.resetPasswordFailure({ reason: error.error?.message }))),
        ),
      ),
    ),
  );

  public resetPasswordSuccess: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.resetPasswordSuccess),
        tap(async () => {
          await this.navController.navigateRoot([AppRoutes.home]);
          this.menu.enable(true);
        }),
      ),
    { dispatch: false },
  );

  public getActionButton$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.getActionButton),
      concatLatestFrom(() => this.store.select(FromUser.selectUserId)),
      filter(([, userId]) => !!userId),
      mergeMap(() =>
        this.actionButtonService.actionButtonControllerGetSelfActionButton().pipe(
          map(actionButton => UserActions.getActionButtonSuccess({ actionButton })),
          catchError((error: HttpErrorResponse) => of(UserActions.getActionButtonFailure({ reason: error.error?.message }))),
        ),
      ),
    ),
  );

  public getActionButtonSuccess$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.getActionButtonSuccess),
        tap(async ({ actionButton }) => {
          if (actionButton?.force) await this.showForceActionButtonModal(actionButton);
        }),
      ),
    { dispatch: false },
  );

  public getPopoverUI$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.getPopoverUI),
      concatLatestFrom(() => this.store.select(FromUser.selectUserId)),
      filter(([, userId]) => !!userId),
      mergeMap(() =>
        this.popoverUIService.popoverUIControllerGetUserPopoverUIToShow().pipe(
          map(popoverUI => UserActions.getPopoverUISuccess({ popoverUI })),
          catchError((error: HttpErrorResponse) => of(UserActions.getPopoverUIFailure({ reason: error.error?.message }))),
        ),
      ),
    ),
  );

  public getPopoverUISuccess$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.getPopoverUISuccess),
        tap(async ({ popoverUI }) => {
          if (popoverUI) await this.showPopoverUIModal(popoverUI);
        }),
      ),
    { dispatch: false },
  );

  public markPopoverUIAsSeen$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.markPopoverUIAsSeen),
      mergeMap(({ popoverId }) =>
        this.popoverUIService.popoverUIControllerMarkPopoverUIAsShown({ popoverId }).pipe(
          map(() => UserActions.markPopoverUIAsSeenSuccess()),
          catchError((error: HttpErrorResponse) => of(UserActions.markPopoverUIAsSeenFailure({ reason: error.error?.message }))),
        ),
      ),
    ),
  );

  public getUserModules$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.getUserModules),
      mergeMap(() =>
        this.userService.userControllerGetSelfModules().pipe(
          map(moduleIds => UserActions.getUserModulesSuccess({ moduleIds })),
          catchError((error: HttpErrorResponse) => of(UserActions.getUserModulesFailure({ reason: error.error?.message }))),
        ),
      ),
    ),
  );

  public getUserChallenges$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.getUserChallenges),
      mergeMap(() =>
        this.userService.userControllerGetSelfChallenges().pipe(
          map(challengeIds => UserActions.getUserChallengesSuccess({ challengeIds })),
          catchError((error: HttpErrorResponse) => of(UserActions.getUserChallengesFailure({ reason: error.error?.message }))),
        ),
      ),
    ),
  );

  public getCompletedSteps$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.getCompletedSteps),
      mergeMap(() =>
        this.userService.userControllerGetSelfCompletedLearningModulesSteps().pipe(
          map(chapterStepIds => UserActions.getCompletedStepsSuccess({ chapterStepIds })),
          catchError((error: HttpErrorResponse) => of(UserActions.getCompletedStepsFailure({ reason: error.error?.message }))),
        ),
      ),
    ),
  );

  public reset$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.reset),
      concatLatestFrom(() => this.store.select(FromApp.selectDeviceToken)),
      concatLatestFrom(() => this.store.select(FromUser.selectUserId)),
      mergeMap(([[, token], userId]) => {
        const actions = [AppActions.setDeviceToken({ token: null }), UserActions.resetSuccess()];
        if (token && userId) {
          return this.pushTokenService.pushTokenControllerUnregisterPushToken({ token, userId }).pipe(
            mergeMap(() => actions),
            catchError((error: HttpErrorResponse) => of(UserActions.resetFailure({ reason: error.error?.message }))),
          );
        }
        return actions;
      }),
    ),
  );

  public logout$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.logout),
      concatLatestFrom(() => this.store.select(FromApp.selectDeviceToken)),
      concatLatestFrom(() => this.store.select(FromUser.selectUserId)),
      mergeMap(([[, token], userId]) => {
        const actions = [AppActions.setDeviceToken({ token: null }), UserActions.logoutSuccess()];
        if (token && userId) {
          return this.pushTokenService.pushTokenControllerUnregisterPushToken({ token, userId }).pipe(
            mergeMap(() => actions),
            catchError((error: HttpErrorResponse) => of(UserActions.logoutFailure({ reason: error.error?.message }))),
          );
        }
        return actions;
      }),
    ),
  );

  public logoutSuccess$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.logoutSuccess, UserActions.logoutFailure),
        tap(() => {
          localStorage.clear();
          this.navController.navigateRoot([AppRoutes.login]);
          window.location.reload();
        }),
      ),
    { dispatch: false },
  );

  public showErrorToast$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          UserActions.checkEmailFailure,
          UserActions.registerFailure,
          UserActions.confirmRegistrationFailure,
          UserActions.loadUserFailure,
          UserActions.loadCustomerFailure,
          UserActions.requestResetPasswordFailure,
          UserActions.resetPasswordFailure,
          UserActions.getActionButtonFailure,
          UserActions.getPopoverUIFailure,
          UserActions.markPopoverUIAsSeenFailure,
          UserActions.getUserChallengesFailure,
          UserActions.getCompletedStepsFailure,
          UserActions.logoutFailure,
        ),
        tap(async ({ reason }) => await this.showErrorToast(reason)),
      ),
    { dispatch: false },
  );

  private forceActionButtonModalDismissed: number = 0;
  private forceActionButtonModalRunning: boolean = false;

  constructor(
    private actions$: Actions,
    private store: Store<RootState>,
    private authService: AuthService,
    private userService: UsersService,
    private actionButtonService: ActionButtonsService,
    private popoverUIService: PopoverUIService,
    private pushTokenService: PushTokensService,
    private navController: NavController,
    private modalController: ModalController,
    private toastController: ToastController,
    private alertController: AlertController,
    private menu: MenuController,
    private analyticsService: AngularFireAnalytics,
    private translateService: TranslateService,
  ) {}

  private async showErrorToast(message: string): Promise<void> {
    if (message) {
      const toast = await this.toastController.create({
        color: "danger",
        message: this.translateService.instant(this.translateService.instant(message || "DEFAULT_ERROR_MESSAGE")),
        duration: TOAST_DEFAULT_DURATION,
      });
      await toast.present();
    }
  }

  private async showForceActionButtonModal(actionButton: ActionButtonDto): Promise<void> {
    if (this.forceActionButtonModalRunning) return;

    this.forceActionButtonModalRunning = true;

    const topModal = await this.modalController.getTop();

    if (topModal?.classList.contains("--force-action-button")) return;
    if (Date.now() - this.forceActionButtonModalDismissed < Miliseconds.OneHour) return;

    const modal = await this.modalController.create({
      component: ActionButtonPage,
      componentProps: { actionButton },
      cssClass: ["--force-action-button"],
      backdropDismiss: false,
      swipeToClose: true,
    });

    await modal.present();
    await modal.onDidDismiss();

    this.forceActionButtonModalDismissed = Date.now();
    this.forceActionButtonModalRunning = false;
  }

  private async showPopoverUIModal(popoverUI: PopoverUIDto): Promise<void> {
    const topModal = await this.modalController.getTop();

    if (topModal) return;

    const modal = await this.modalController.create({
      component: PopoverUiPage,
      componentProps: { popoverUI },
      cssClass: ["--popover-ui"],
      backdropDismiss: false,
      swipeToClose: false,
    });

    await modal.present();
    await modal.onDidDismiss();

    this.store.dispatch(UserActions.markPopoverUIAsSeen({ popoverId: popoverUI.id }));
  }
}
