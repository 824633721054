import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ToEventContentPipe } from "./to-event-content.pipe";

@NgModule({
  imports: [CommonModule],
  declarations: [ToEventContentPipe],
  exports: [ToEventContentPipe],
})
export class ToEventContentModule {}
