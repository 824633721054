import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-reschedule-button",
  templateUrl: "./reschedule-button.component.html",
  styleUrls: ["./reschedule-button.component.scss"],
})
export class RescheduleButtonComponent {
  @Input() public size: "default" | "large" | "small" | undefined;

  @Output() public clicked: EventEmitter<void> = new EventEmitter();

  public stopPropagation(event: Event): void {
    event.stopPropagation();
  }
}
