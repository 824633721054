/* eslint-disable @typescript-eslint/naming-convention */
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { APP_ID, Inject, Injectable } from "@angular/core";
import { AlertController } from "@ionic/angular";
import { Observable, of, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { APP_RELEASE } from "src/app/constants";

@Injectable({ providedIn: "root" })
export class VersionInterceptor implements HttpInterceptor {
  constructor(
    @Inject(APP_ID) private origin: string,
    @Inject(APP_RELEASE) private release: string,
    private alertController: AlertController,
  ) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    req = req.clone({
      setHeaders: {
        "X-APP-RELEASE": this.release,
        "X-APP-ORIGIN": this.origin,
      },
    });

    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 426) return this.showAlert(error);
        return throwError(error, null);
      }),
    );
  }

  private showAlert(error: any): Observable<any> {
    this.alertController
      .create({
        header: "Aktualisierung erforderlich",
        message: "Um die Anwendung nutzen zu können, müssen Sie auf die neueste Version aktualisieren.",
        backdropDismiss: false,
      })
      .then(alert => alert.present());

    return of(error);
  }
}
