import { createReducer, on } from "@ngrx/store";
import { DataDefinitions, initialState, Reports } from "./state";
import * as ReportActions from "./actions";
import { ReportDto } from "@api";

export const reportReducer = createReducer(
  initialState,

  on(ReportActions.getReportDataDefinitions, ReportActions.getReportResponses, ReportActions.sendSurvey, state => ({
    ...state,
    loading: true,
  })),

  on(ReportActions.getReportDataDefinitionsSuccess, (state, { dataDefinitions }) => {
    const entities: DataDefinitions = {};
    dataDefinitions.forEach(dataDefinition => (entities[dataDefinition.id] = dataDefinition));

    return {
      ...state,
      dataDefinitions: entities,
      loading: false,
    };
  }),

  on(ReportActions.createReportSuccess, (state, { report }) => ({
    ...state,
    reports: {
      ...state.reports,
      [report.id]: report,
    },
    loading: false,
  })),

  on(ReportActions.storeReportError, (state, { fieldId, error, params }) => ({
    ...state,
    localReportErrors: {
      ...state.localReportErrors,
      [fieldId]: { error, params },
    },
  })),

  on(ReportActions.cleanReportError, (state, { fieldId }) => ({
    ...state,
    localReportErrors: [...Object.entries(state.localReportErrors)].reduce((localReportErrors, [key, value]) => {
      if (key !== fieldId) localReportErrors[key] = value;
      return localReportErrors;
    }, {}),
  })),

  on(ReportActions.getReportResponsesSuccess, ReportActions.sendSurveySuccess, (state, { reports }) => {
    const entities: Reports = {};
    reports.forEach((report: ReportDto) => {
      entities[report.id] = report;
    });

    return {
      ...state,
      reports: entities,
      loading: false,
    };
  }),

  on(
    ReportActions.getReportDataDefinitionsFailure,
    ReportActions.getReportResponsesFailure,
    ReportActions.createReportFailure,
    ReportActions.sendSurveyFailure,
    state => ({
      ...state,
      loading: false,
    }),
  ),
);
