import { ReportDataDefinitionDto, ReportDto, SurveyResponseDto } from "@api";
import { ValidateSurveyResponseValueResponse } from "src/app/utils/validate-survey-response-value-response.interface";

export interface DataDefinitions {
  [key: string]: ReportDataDefinitionDto;
}

export interface Reports {
  [key: string]: ReportDto;
}

export interface LocalReportErrors {
  [key: string]: ValidateSurveyResponseValueResponse;
}

export interface ReportState {
  dataDefinitions: DataDefinitions;
  reports: Reports;
  localReportErrors: LocalReportErrors;
  loading: boolean;
}

export const initialState: ReportState = {
  dataDefinitions: {},
  localReportErrors: {},
  reports: {},
  loading: false,
};

export const persistedKeys: string[] = ["dataDefinitions"];
