import {
  ManyNewsDto,
  NewsControllerDislikeNewsRequestParams,
  NewsControllerGetNewsRequestParams,
  NewsControllerLikeNewsRequestParams,
} from "@api";
import { createAction, props } from "@ngrx/store";

interface GetNewsInfiniteScroll extends NewsControllerGetNewsRequestParams {
  event?: CustomEvent;
}

interface ManyNews extends ManyNewsDto {
  event?: CustomEvent;
}

export const getNews = createAction("[NEWS] Get News", props<GetNewsInfiniteScroll>());
export const getNewsSuccess = createAction("[NEWS] Get News Success", props<ManyNews>());
export const getNewsFailure = createAction("[NEWS] Get News Failure", props<{ reason: string }>());

export const likeNews = createAction("[NEWS] Like News", props<NewsControllerLikeNewsRequestParams>());
export const likeNewsSuccess = createAction("[NEWS] Like News Success", props<{ newsId: string; userId: string }>());
export const likeNewsFailure = createAction("[NEWS] Like News Failure", props<{ reason: string }>());

export const unlikeNews = createAction("[NEWS] Unlike News", props<NewsControllerDislikeNewsRequestParams>());
export const unlikeNewsSuccess = createAction("[NEWS] Unlike News Success", props<{ newsId: string; userId: string }>());
export const unlikeNewsFailure = createAction("[NEWS] Unlike News Failure", props<{ reason: string }>());
