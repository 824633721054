import { Version } from "@api";
import { RootState } from "..";

export const selectShouldShowAndroidBeforeInstallPrompt = (state: RootState): boolean => state.app.shouldShowAndroidBeforeInstallPrompt;

export const selectDeviceToken = (state: RootState): string => state.app.deviceToken;

export const getDismissedUpdateVersion = (state: RootState): Version => state.app.dismissedUpdateVersion;

export const selectOnline = (state: RootState): boolean => state.app.online;
