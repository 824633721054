import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IonicModule } from "@ionic/angular";
import { EventPage } from "./event.page";
import { EventResponseModule } from "src/app/pipes/event-response/event-response.module";
import { ToEventContentModule } from "src/app/pipes/to-event-content/to-event-content.module";
import { CanShowJoinButtonModule } from "./pipes/can-show-join-button/can-show-join-button.module";
import { EventDetailModule } from "src/app/components/event-detail/event-detail.module";
import { IframeModalModule } from "../iframe-modal/iframe-modal.module";
import { RescheduleButtonComponentModule } from "src/app/components/reschedule-button/reschedule-button.module";
import { ColorSelectModule } from "src/app/components/color-select/color-select.module";
import { TranslateModule } from "@ngx-translate/core";
import { GetAvailablePlacesPipeModule } from "src/app/pipes/get-available-places/get-available-places.module";

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    EventResponseModule,
    ToEventContentModule,
    CanShowJoinButtonModule,
    EventDetailModule,
    IframeModalModule,
    RescheduleButtonComponentModule,
    ColorSelectModule,
    TranslateModule,
    GetAvailablePlacesPipeModule,
  ],
  declarations: [EventPage],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class EventPageModule {}
