import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { IonicModule } from "@ionic/angular";
import { PopoverComponent } from "./popover.component";

@NgModule({
  imports: [CommonModule, IonicModule],
  declarations: [PopoverComponent],
  exports: [PopoverComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PopoverModule {}
