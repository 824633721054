import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LinebreaksPipe } from "./linebreaks.pipe";

@NgModule({
  declarations: [LinebreaksPipe],
  exports: [LinebreaksPipe],
  imports: [CommonModule],
})
export class LinebreaksModule {}
