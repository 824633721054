import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ReportDto, ReportsService, SurveysService } from "@api";
import { ModalController, NavController, ToastController } from "@ionic/angular";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { Observable, of } from "rxjs";
import { catchError, map, mergeMap, tap } from "rxjs/operators";
import { AppRoutes, QueryParams, TOAST_DEFAULT_DURATION } from "src/app/constants";
import * as ReportActions from "./actions";

@Injectable()
export class ReportEffects {
  public getReportDataDefinitions$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ReportActions.getReportDataDefinitions),
      mergeMap(() =>
        this.reportService.reportControllerGetDataDefinitons().pipe(
          map(dataDefinitions => ReportActions.getReportDataDefinitionsSuccess({ dataDefinitions })),
          catchError((error: HttpErrorResponse) => of(ReportActions.getReportDataDefinitionsFailure({ reason: error.error?.message }))),
        ),
      ),
    ),
  );

  public createReport$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ReportActions.createReport),
      mergeMap(({ createReportDto }) =>
        this.reportService.reportControllerCreateReport({ createReportDto }).pipe(
          map(report => ReportActions.createReportSuccess({ report })),
          catchError((error: HttpErrorResponse) => of(ReportActions.createReportFailure({ reason: error.error?.message }))),
        ),
      ),
    ),
  );

  public sendSurvey$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ReportActions.sendSurvey),
      mergeMap(({ month, storeId, surveySlug, year }) =>
        this.reportService.reportControllerReportFilled({ month, storeId, surveySlug, year }).pipe(
          map(reports => ReportActions.sendSurveySuccess({ reports, slug: surveySlug, month, year })),
          catchError((error: HttpErrorResponse) => of(ReportActions.sendSurveyFailure({ reason: error.error?.message }))),
        ),
      ),
    ),
  );

  public sendSurveySuccess$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ReportActions.sendSurveySuccess),
        tap(async ({ reports, slug, month, year }) => {
          const modal = await this.modalController.getTop();
          modal?.dismiss();

          if (reports.some(report => report.status === ReportDto.StatusEnum.Success)) {
            this.navController.navigateRoot([AppRoutes.reports, slug, AppRoutes.success], {
              queryParams: { [QueryParams.month]: month, [QueryParams.year]: year },
            });
          } else {
            this.navController.navigateRoot([AppRoutes.reports, slug, AppRoutes.history, AppRoutes.detail], {
              queryParams: { [QueryParams.month]: month, [QueryParams.year]: year },
            });
          }
        }),
      ),
    { dispatch: false },
  );

  public getReportResponses$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ReportActions.getReportResponses),
      mergeMap(({ slug }) =>
        this.surveyService.surveyControllerGetDefinitionSurvey({ definitionSlug: slug }).pipe(
          mergeMap(survey =>
            this.surveyService.surveyControllerGetSurveyResponses({ surveyId: survey.id }).pipe(
              map(reports => ReportActions.getReportResponsesSuccess({ reports })),
              catchError((error: HttpErrorResponse) => of(ReportActions.getReportResponsesFailure({ reason: error.error?.message }))),
            ),
          ),
          catchError((error: HttpErrorResponse) => of(ReportActions.getReportResponsesFailure({ reason: error.error?.message }))),
        ),
      ),
    ),
  );

  public showErrorToast$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ReportActions.getReportDataDefinitionsFailure,
          ReportActions.createReportFailure,
          ReportActions.getReportResponsesFailure,
          ReportActions.sendSurveyFailure,
        ),
        tap(async ({ reason }) => this.showErrorToast(reason)),
      ),
    { dispatch: false },
  );

  constructor(
    private readonly actions$: Actions,
    private readonly reportService: ReportsService,
    private readonly surveyService: SurveysService,
    private readonly i18n: TranslateService,
    private readonly navController: NavController,
    private readonly toastController: ToastController,
    private readonly modalController: ModalController,
  ) {}

  private async showErrorToast(message: string): Promise<void> {
    const toast = await this.toastController.create({
      color: "danger",
      message: this.i18n.instant((message || "DEFAULT_ERROR_MESSAGE").toString()),
      duration: TOAST_DEFAULT_DURATION,
    });
    await toast.present();
  }
}
