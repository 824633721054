import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { IonicModule } from "@ionic/angular";
import { ChallengeLayoutComponent } from "./challenge-layout.component";

@NgModule({
  imports: [CommonModule, IonicModule],
  declarations: [ChallengeLayoutComponent],
  exports: [ChallengeLayoutComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ChallengeLayoutModule {}
