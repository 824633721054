import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { SwipperSurveyPage } from "./swipper-survey.page";
import { TranslateModule } from "@ngx-translate/core";
import { SurveyFieldModule } from "src/app/components/survey-field/survey-field.module";
import { GetSymbolModule } from "src/app/pipes/get-symbol/get-symbol.module";
import { DefaultValueModule } from "src/app/pipes/default-value/default-value.module";
import { SwiperModule } from "swiper/angular";
import { IsValidReportModule } from "src/app/pipes/is-valid-report/is-valid-report.module";
import { PopoverModule } from "src/app/components/popover/popover.module";
import { BackgroundImageDirectiveModule } from "src/app/directives/background-image/background-image.module";
import { IframeModalModule } from "../iframe-modal/iframe-modal.module";
import { SwipperReportPageModule } from "../swipper-report/swipper-report.module";
import { ToEventContentModule } from "src/app/pipes/to-event-content/to-event-content.module";

@NgModule({
  imports: [
    CommonModule,
    SwipperReportPageModule,
    FormsModule,
    IonicModule,
    TranslateModule,
    SurveyFieldModule,
    GetSymbolModule,
    DefaultValueModule,
    SwiperModule,
    IsValidReportModule,
    PopoverModule,
    BackgroundImageDirectiveModule,
    IframeModalModule,
    ToEventContentModule,
  ],
  declarations: [SwipperSurveyPage],
})
export class SwipperSurveyPageModule {}
