<div class="event__availability">
  <label
    for="progress"
    class="event__availability__label"
    [innerHTML]="
      (free <= 0 ? 'EVENT_DETAIL_COMPONENT.EVENT_FULL' : 'EVENT_DETAIL_COMPONENT.AVAILABLE')
        | translate: { participants: free, capacity: total }
    "
  ></label>
  <progress
    id="progress"
    class="event__availability__progress"
    [class.--available]="free > 0"
    [class.--full]="free <= 0"
    [max]="total"
    [value]="total - free"
  ></progress>
</div>
