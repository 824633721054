import { ActionReducerMap } from "@ngrx/store";
import { routerReducer, RouterReducerState } from "@ngrx/router-store";

import { appReducer } from "./app/reducer";
import { eventReducer } from "./event/reducer";
import { integrationReducer } from "./integration/reducer";
import { learningModuleReducer } from "./learning-module/reducer";
import { newsReducer } from "./news/reducer";
import { reportReducer } from "./report/reducer";
import { scenarioReducer } from "./scenario/reducer";
import { surveyReducer } from "./survey/reducer";
import { userReducer } from "./user/reducer";

import { AppState, initialState as app } from "./app/state";
import { EventState, initialState as event } from "./event/state";
import { IntegrationState, initialState as integration } from "./integration/state";
import { LearningModuleState, initialState as learningModule } from "./learning-module/state";
import { NewsState, initialState as news } from "./news/state";
import { ReportState, initialState as report } from "./report/state";
import { ScenarioState, initialState as scenario } from "./scenario/state";
import { SurveyState, initialState as survey } from "./survey/state";
import { UserState, initialState as user } from "./user/state";

import { AppEffects } from "./app/effects";
import { EventEffects } from "./event/effects";
import { IntegrationEffects } from "./integration/effects";
import { LearningModuleEffects } from "./learning-module/effects";
import { NewsEffects } from "./news/effects";
import { ReportEffects } from "./report/effects";
import { RouterEffects } from "./router/effects";
import { ScenarioEffects } from "./scenario/effects";
import { SurveyEffects } from "./survey/effects";
import { UserEffects } from "./user/effects";

export interface RootState {
  app: AppState;
  event: EventState;
  integration: IntegrationState;
  learningModule: LearningModuleState;
  news: NewsState;
  report: ReportState;
  router: RouterReducerState<any>;
  scenario: ScenarioState;
  survey: SurveyState;
  user: UserState;
}

export const reducers: ActionReducerMap<RootState> = {
  app: appReducer,
  event: eventReducer,
  integration: integrationReducer,
  learningModule: learningModuleReducer,
  news: newsReducer,
  report: reportReducer,
  router: routerReducer,
  scenario: scenarioReducer,
  survey: surveyReducer,
  user: userReducer,
};

export const initialRootState: RootState = {
  app,
  event,
  integration,
  learningModule,
  news,
  report,
  router: null,
  scenario,
  survey,
  user,
};

export const effects = [
  AppEffects,
  EventEffects,
  IntegrationEffects,
  LearningModuleEffects,
  NewsEffects,
  ReportEffects,
  RouterEffects,
  ScenarioEffects,
  SurveyEffects,
  UserEffects,
];
