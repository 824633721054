import { Component, Input } from "@angular/core";

@Component({
  selector: "app-event-availability",
  templateUrl: "./event-availability.component.html",
  styleUrls: ["./event-availability.component.scss"],
})
export class EventAvailabilityComponent {
  @Input() public text: string;
  @Input() public total: number;
  @Input() public free: number;
}
