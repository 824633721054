<ion-content>
  <ion-icon name="close" (click)="dismiss()"></ion-icon>

  <div *ngIf="showWelcome && (definition$ | async)" class="welcome" [appBackgroundImage]="(definition$ | async)?.imageUrl">
    <h2 class="welcome__title" [innerHTML]="(definition$ | async)?.title"></h2>
    <h5 class="welcome__text" [innerHTML]="(definition$ | async)?.description"></h5>
  </div>

  <swiper
    class="swiper"
    [class.--is-hidden]="showWelcome"
    [config]="swiperConfig"
    (swiper)="onSwiper($event)"
    (slideChange)="onSlideChange()"
  >
    <ng-template swiperSlide *ngFor="let field of (fields$ | async); let index = index; trackBy: trackBy">
      <app-survey-field
        [field]="field"
        [value]="field | defaultValue:date:slug:'REPORT' | async"
        [index]="index"
        [error]="(errors$ | async)[field.id]"
        [symbol]="field.dataDefinitionId | getSymbol | async"
        (handleHelp)="handleHelp($event)"
        (handleChange)="handleFieldResponse($event)"
      ></app-survey-field>
    </ng-template>
  </swiper>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button
        *ngIf="!showWelcome"
        class="button --text --dark --is-summary"
        [innerHTML]="'SURVEYS.SUMMARY' | translate: { current: currentIndex + 1, total: (fields$ | async)?.length }"
      ></ion-button>
    </ion-buttons>

    <ion-title *ngIf="showWelcome">
      <ion-text>{{ date | date:'MMMM YYYY' }}</ion-text>
    </ion-title>

    <ion-title *ngIf="!showWelcome" class="--is-progress">
      <div class="progress">
        <label
          for="progress"
          class="survey__label"
          [innerHTML]="'SUCCESS_REPORT.PROGRESS_LABEL' | translate:{ done: currentIndex + 1, total: (fields$ | async)?.length }"
        ></label>
        <progress id="progress" class="survey__progress" [max]="(fields$ | async)?.length" [value]="currentIndex + 1"></progress>
      </div>
    </ion-title>

    <ion-buttons slot="end">
      <ion-button
        *ngIf="showWelcome"
        class="button --small --primary"
        shape="round"
        [innerHTML]="(definition$ | async)?.startButtonText"
        (click)="start()"
      ></ion-button>

      <ion-button
        *ngIf="!showWelcome && currentIndex > 0"
        class="button --small --dark-outline"
        shape="round"
        [innerHTML]="'SURVEYS.BACK' | translate"
        (click)="previousSlide()"
      ></ion-button>

      <ion-button
        *ngIf="!showWelcome && (currentIndex + 1) !== (fields$ | async)?.length"
        class="button --small --primary"
        shape="round"
        [innerHTML]="'SURVEYS.NEXT' | translate"
        (click)="nextSlide()"
      ></ion-button>

      <ion-button
        *ngIf="(currentIndex + 1) === (fields$ | async)?.length && !showWelcome"
        class="button --small --primary"
        shape="round"
        (click)="send()"
      >
        <ion-spinner *ngIf="(loading$ | async); else text"></ion-spinner>
        <ng-template #text><ion-text [innerHTML]="'SURVEYS.FINISH' | translate"></ion-text></ng-template>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
