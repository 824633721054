/**
 * Wow family api
 * The Wow family API description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Version { 
    id: Version.IdEnum;
    version: string;
    updatedAt: string;
}
export namespace Version {
    export type IdEnum = 'ios' | 'android' | 'www';
    export const IdEnum = {
        Ios: 'ios' as IdEnum,
        Android: 'android' as IdEnum,
        Www: 'www' as IdEnum
    };
}


