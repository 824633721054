import { SurveyDefinitionDto, SurveyDto, SurveyResponseDto } from "@api";
import { ValidateSurveyResponseValueResponse } from "src/app/utils/validate-survey-response-value-response.interface";

interface Definitions {
  [id: string]: SurveyDefinitionDto;
}

export interface Surveys {
  [id: string]: SurveyDto;
}

export interface LocalSurveyErrors {
  [key: string]: ValidateSurveyResponseValueResponse;
}

interface SurveyResponses {
  [surveyId: string]: SurveyResponseDto[];
}

export interface SurveyState {
  definitions: Definitions;
  surveys: Surveys;
  responses: SurveyResponses;
  localSurveyErrors: LocalSurveyErrors;
  loading: boolean;
}

export const initialState: SurveyState = {
  definitions: {},
  surveys: {},
  responses: {},
  localSurveyErrors: {},
  loading: false,
};
