import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { environment as local } from "src/environments/environment";
import { environment as qa } from "src/environments/environment.qa";
import { environment as staging } from "src/environments/environment.staging";
import { environment as production } from "src/environments/environment.production";
import { BehaviorSubject } from "rxjs";
import { LocalStorage } from "src/app/constants";

const ENV: { [key in EnvironmentName]: Environment } = { local, qa, staging, production };

@Injectable({ providedIn: "root" })
export class EnvironmentService {
  private readonly activeEnvironment: EnvironmentName;
  private readonly environment$: BehaviorSubject<Environment>;

  constructor() {
    this.activeEnvironment = (localStorage.getItem(LocalStorage.activeEnvironment) as EnvironmentName) || environment.name;
    this.environment$ = new BehaviorSubject<Environment>(ENV[this.activeEnvironment]);
  }

  public get environments(): string[] {
    return Object.keys(ENV);
  }

  public environment(): BehaviorSubject<Environment> {
    return this.environment$;
  }

  public changeEnvironment(name: EnvironmentName): void {
    localStorage.setItem(LocalStorage.activeEnvironment, name);
    window.location.reload();
  }

  public get<T>(variable: EnvironmentVariable): T {
    return ENV[this.activeEnvironment][variable] as unknown as T;
  }
}
