/**
 * Wow family api
 * The Wow family API description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { CreateSurveyDefinitionDto } from '../model/models';
import { CreateSurveyDto } from '../model/models';
import { CreateSurveyFieldDto } from '../model/models';
import { CreateSurveyResponseDto } from '../model/models';
import { SurveyDefinitionDto } from '../model/models';
import { SurveyDto } from '../model/models';
import { SurveyFieldDto } from '../model/models';
import { SurveyResponseDto } from '../model/models';
import { UpdateSurveyDefinitionDto } from '../model/models';
import { UpdateSurveyDto } from '../model/models';
import { UpdateSurveyFieldDto } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import {
    SurveysServiceInterface,
    SurveyControllerCreateSurveyRequestParams,
    SurveyControllerCreateSurveyDefinitionRequestParams,
    SurveyControllerCreateSurveyFieldRequestParams,
    SurveyControllerDeleteSurveyRequestParams,
    SurveyControllerDeleteSurveyDefinitionRequestParams,
    SurveyControllerDeleteSurveyFieldRequestParams,
    SurveyControllerGetDefinitionSurveyRequestParams,
    SurveyControllerGetDefinitionSurveysRequestParams,
    SurveyControllerGetSurveyByIdRequestParams,
    SurveyControllerGetSurveyDefinitionByIdRequestParams,
    SurveyControllerGetSurveyDefinitionBySlugRequestParams,
    SurveyControllerGetSurveyResponsesRequestParams,
    SurveyControllerStoreSurveyFieldResponseRequestParams,
    SurveyControllerSurveyFilledRequestParams,
    SurveyControllerUnassignSurveyRequestParams,
    SurveyControllerUpdateSurveyRequestParams,
    SurveyControllerUpdateSurveyDefinitionRequestParams,
    SurveyControllerUpdateSurveyFieldRequestParams
} from './surveys.serviceInterface';



@Injectable({
  providedIn: 'root'
})
export class SurveysService implements SurveysServiceInterface {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public surveyControllerCreateSurvey(requestParameters: SurveyControllerCreateSurveyRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<SurveyDto>;
    public surveyControllerCreateSurvey(requestParameters: SurveyControllerCreateSurveyRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<SurveyDto>>;
    public surveyControllerCreateSurvey(requestParameters: SurveyControllerCreateSurveyRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<SurveyDto>>;
    public surveyControllerCreateSurvey(requestParameters: SurveyControllerCreateSurveyRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const createSurveyDto = requestParameters.createSurveyDto;
        if (createSurveyDto === null || createSurveyDto === undefined) {
            throw new Error('Required parameter createSurveyDto was null or undefined when calling surveyControllerCreateSurvey.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<SurveyDto>(`${this.configuration.basePath}/surveys`,
            createSurveyDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public surveyControllerCreateSurveyDefinition(requestParameters: SurveyControllerCreateSurveyDefinitionRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<SurveyDefinitionDto>;
    public surveyControllerCreateSurveyDefinition(requestParameters: SurveyControllerCreateSurveyDefinitionRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<SurveyDefinitionDto>>;
    public surveyControllerCreateSurveyDefinition(requestParameters: SurveyControllerCreateSurveyDefinitionRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<SurveyDefinitionDto>>;
    public surveyControllerCreateSurveyDefinition(requestParameters: SurveyControllerCreateSurveyDefinitionRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const createSurveyDefinitionDto = requestParameters.createSurveyDefinitionDto;
        if (createSurveyDefinitionDto === null || createSurveyDefinitionDto === undefined) {
            throw new Error('Required parameter createSurveyDefinitionDto was null or undefined when calling surveyControllerCreateSurveyDefinition.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<SurveyDefinitionDto>(`${this.configuration.basePath}/surveys/definitions`,
            createSurveyDefinitionDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public surveyControllerCreateSurveyField(requestParameters: SurveyControllerCreateSurveyFieldRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<SurveyFieldDto>;
    public surveyControllerCreateSurveyField(requestParameters: SurveyControllerCreateSurveyFieldRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<SurveyFieldDto>>;
    public surveyControllerCreateSurveyField(requestParameters: SurveyControllerCreateSurveyFieldRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<SurveyFieldDto>>;
    public surveyControllerCreateSurveyField(requestParameters: SurveyControllerCreateSurveyFieldRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const createSurveyFieldDto = requestParameters.createSurveyFieldDto;
        if (createSurveyFieldDto === null || createSurveyFieldDto === undefined) {
            throw new Error('Required parameter createSurveyFieldDto was null or undefined when calling surveyControllerCreateSurveyField.');
        }
        const definitionId = requestParameters.definitionId;
        if (definitionId === null || definitionId === undefined) {
            throw new Error('Required parameter definitionId was null or undefined when calling surveyControllerCreateSurveyField.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<SurveyFieldDto>(`${this.configuration.basePath}/surveys/definitions/${encodeURIComponent(String(definitionId))}/fields`,
            createSurveyFieldDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public surveyControllerDeleteSurvey(requestParameters: SurveyControllerDeleteSurveyRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<SurveyDto>;
    public surveyControllerDeleteSurvey(requestParameters: SurveyControllerDeleteSurveyRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<SurveyDto>>;
    public surveyControllerDeleteSurvey(requestParameters: SurveyControllerDeleteSurveyRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<SurveyDto>>;
    public surveyControllerDeleteSurvey(requestParameters: SurveyControllerDeleteSurveyRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const surveyId = requestParameters.surveyId;
        if (surveyId === null || surveyId === undefined) {
            throw new Error('Required parameter surveyId was null or undefined when calling surveyControllerDeleteSurvey.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<SurveyDto>(`${this.configuration.basePath}/surveys/${encodeURIComponent(String(surveyId))}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public surveyControllerDeleteSurveyDefinition(requestParameters: SurveyControllerDeleteSurveyDefinitionRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<SurveyDefinitionDto>;
    public surveyControllerDeleteSurveyDefinition(requestParameters: SurveyControllerDeleteSurveyDefinitionRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<SurveyDefinitionDto>>;
    public surveyControllerDeleteSurveyDefinition(requestParameters: SurveyControllerDeleteSurveyDefinitionRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<SurveyDefinitionDto>>;
    public surveyControllerDeleteSurveyDefinition(requestParameters: SurveyControllerDeleteSurveyDefinitionRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const definitionId = requestParameters.definitionId;
        if (definitionId === null || definitionId === undefined) {
            throw new Error('Required parameter definitionId was null or undefined when calling surveyControllerDeleteSurveyDefinition.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<SurveyDefinitionDto>(`${this.configuration.basePath}/surveys/definitions/${encodeURIComponent(String(definitionId))}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public surveyControllerDeleteSurveyField(requestParameters: SurveyControllerDeleteSurveyFieldRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<SurveyFieldDto>;
    public surveyControllerDeleteSurveyField(requestParameters: SurveyControllerDeleteSurveyFieldRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<SurveyFieldDto>>;
    public surveyControllerDeleteSurveyField(requestParameters: SurveyControllerDeleteSurveyFieldRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<SurveyFieldDto>>;
    public surveyControllerDeleteSurveyField(requestParameters: SurveyControllerDeleteSurveyFieldRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const definitionId = requestParameters.definitionId;
        if (definitionId === null || definitionId === undefined) {
            throw new Error('Required parameter definitionId was null or undefined when calling surveyControllerDeleteSurveyField.');
        }
        const fieldId = requestParameters.fieldId;
        if (fieldId === null || fieldId === undefined) {
            throw new Error('Required parameter fieldId was null or undefined when calling surveyControllerDeleteSurveyField.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<SurveyFieldDto>(`${this.configuration.basePath}/surveys/definitions/${encodeURIComponent(String(definitionId))}/fields/${encodeURIComponent(String(fieldId))}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public surveyControllerGetDefinitionSurvey(requestParameters: SurveyControllerGetDefinitionSurveyRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<SurveyDto>;
    public surveyControllerGetDefinitionSurvey(requestParameters: SurveyControllerGetDefinitionSurveyRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<SurveyDto>>;
    public surveyControllerGetDefinitionSurvey(requestParameters: SurveyControllerGetDefinitionSurveyRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<SurveyDto>>;
    public surveyControllerGetDefinitionSurvey(requestParameters: SurveyControllerGetDefinitionSurveyRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const definitionSlug = requestParameters.definitionSlug;
        if (definitionSlug === null || definitionSlug === undefined) {
            throw new Error('Required parameter definitionSlug was null or undefined when calling surveyControllerGetDefinitionSurvey.');
        }
        const eventId = requestParameters.eventId;
        const learningModuleId = requestParameters.learningModuleId;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (eventId !== undefined && eventId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>eventId, 'event_id');
        }
        if (learningModuleId !== undefined && learningModuleId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>learningModuleId, 'learning_module_id');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<SurveyDto>(`${this.configuration.basePath}/surveys/${encodeURIComponent(String(definitionSlug))}`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public surveyControllerGetDefinitionSurveys(requestParameters: SurveyControllerGetDefinitionSurveysRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<SurveyDto>>;
    public surveyControllerGetDefinitionSurveys(requestParameters: SurveyControllerGetDefinitionSurveysRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<SurveyDto>>>;
    public surveyControllerGetDefinitionSurveys(requestParameters: SurveyControllerGetDefinitionSurveysRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<SurveyDto>>>;
    public surveyControllerGetDefinitionSurveys(requestParameters: SurveyControllerGetDefinitionSurveysRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const definitionId = requestParameters.definitionId;
        if (definitionId === null || definitionId === undefined) {
            throw new Error('Required parameter definitionId was null or undefined when calling surveyControllerGetDefinitionSurveys.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (definitionId !== undefined && definitionId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>definitionId, 'definitionId');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<SurveyDto>>(`${this.configuration.basePath}/surveys`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public surveyControllerGetSurveyById(requestParameters: SurveyControllerGetSurveyByIdRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<SurveyDto>;
    public surveyControllerGetSurveyById(requestParameters: SurveyControllerGetSurveyByIdRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<SurveyDto>>;
    public surveyControllerGetSurveyById(requestParameters: SurveyControllerGetSurveyByIdRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<SurveyDto>>;
    public surveyControllerGetSurveyById(requestParameters: SurveyControllerGetSurveyByIdRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const surveyId = requestParameters.surveyId;
        if (surveyId === null || surveyId === undefined) {
            throw new Error('Required parameter surveyId was null or undefined when calling surveyControllerGetSurveyById.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<SurveyDto>(`${this.configuration.basePath}/surveys/${encodeURIComponent(String(surveyId))}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public surveyControllerGetSurveyDefinitionById(requestParameters: SurveyControllerGetSurveyDefinitionByIdRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<SurveyDefinitionDto>;
    public surveyControllerGetSurveyDefinitionById(requestParameters: SurveyControllerGetSurveyDefinitionByIdRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<SurveyDefinitionDto>>;
    public surveyControllerGetSurveyDefinitionById(requestParameters: SurveyControllerGetSurveyDefinitionByIdRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<SurveyDefinitionDto>>;
    public surveyControllerGetSurveyDefinitionById(requestParameters: SurveyControllerGetSurveyDefinitionByIdRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const definitionId = requestParameters.definitionId;
        if (definitionId === null || definitionId === undefined) {
            throw new Error('Required parameter definitionId was null or undefined when calling surveyControllerGetSurveyDefinitionById.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<SurveyDefinitionDto>(`${this.configuration.basePath}/surveys/definitions/${encodeURIComponent(String(definitionId))}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public surveyControllerGetSurveyDefinitionBySlug(requestParameters: SurveyControllerGetSurveyDefinitionBySlugRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<SurveyDefinitionDto>;
    public surveyControllerGetSurveyDefinitionBySlug(requestParameters: SurveyControllerGetSurveyDefinitionBySlugRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<SurveyDefinitionDto>>;
    public surveyControllerGetSurveyDefinitionBySlug(requestParameters: SurveyControllerGetSurveyDefinitionBySlugRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<SurveyDefinitionDto>>;
    public surveyControllerGetSurveyDefinitionBySlug(requestParameters: SurveyControllerGetSurveyDefinitionBySlugRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const slug = requestParameters.slug;
        if (slug === null || slug === undefined) {
            throw new Error('Required parameter slug was null or undefined when calling surveyControllerGetSurveyDefinitionBySlug.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<SurveyDefinitionDto>(`${this.configuration.basePath}/surveys/definitions/${encodeURIComponent(String(slug))}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public surveyControllerGetSurveyDefinitions(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<SurveyDefinitionDto>>;
    public surveyControllerGetSurveyDefinitions(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<SurveyDefinitionDto>>>;
    public surveyControllerGetSurveyDefinitions(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<SurveyDefinitionDto>>>;
    public surveyControllerGetSurveyDefinitions(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<SurveyDefinitionDto>>(`${this.configuration.basePath}/surveys/definitions`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public surveyControllerGetSurveyResponses(requestParameters: SurveyControllerGetSurveyResponsesRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public surveyControllerGetSurveyResponses(requestParameters: SurveyControllerGetSurveyResponsesRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public surveyControllerGetSurveyResponses(requestParameters: SurveyControllerGetSurveyResponsesRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public surveyControllerGetSurveyResponses(requestParameters: SurveyControllerGetSurveyResponsesRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        const surveyId = requestParameters.surveyId;
        if (surveyId === null || surveyId === undefined) {
            throw new Error('Required parameter surveyId was null or undefined when calling surveyControllerGetSurveyResponses.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<any>(`${this.configuration.basePath}/surveys/${encodeURIComponent(String(surveyId))}/responses`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns the logged user
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public surveyControllerGetUserAssignedSurveys(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<SurveyDto>>;
    public surveyControllerGetUserAssignedSurveys(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<SurveyDto>>>;
    public surveyControllerGetUserAssignedSurveys(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<SurveyDto>>>;
    public surveyControllerGetUserAssignedSurveys(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<SurveyDto>>(`${this.configuration.basePath}/surveys/self`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public surveyControllerStoreSurveyFieldResponse(requestParameters: SurveyControllerStoreSurveyFieldResponseRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<SurveyResponseDto>;
    public surveyControllerStoreSurveyFieldResponse(requestParameters: SurveyControllerStoreSurveyFieldResponseRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<SurveyResponseDto>>;
    public surveyControllerStoreSurveyFieldResponse(requestParameters: SurveyControllerStoreSurveyFieldResponseRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<SurveyResponseDto>>;
    public surveyControllerStoreSurveyFieldResponse(requestParameters: SurveyControllerStoreSurveyFieldResponseRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const createSurveyResponseDto = requestParameters.createSurveyResponseDto;
        if (createSurveyResponseDto === null || createSurveyResponseDto === undefined) {
            throw new Error('Required parameter createSurveyResponseDto was null or undefined when calling surveyControllerStoreSurveyFieldResponse.');
        }
        const surveyId = requestParameters.surveyId;
        if (surveyId === null || surveyId === undefined) {
            throw new Error('Required parameter surveyId was null or undefined when calling surveyControllerStoreSurveyFieldResponse.');
        }
        const surveyFieldId = requestParameters.surveyFieldId;
        if (surveyFieldId === null || surveyFieldId === undefined) {
            throw new Error('Required parameter surveyFieldId was null or undefined when calling surveyControllerStoreSurveyFieldResponse.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<SurveyResponseDto>(`${this.configuration.basePath}/surveys/${encodeURIComponent(String(surveyId))}/fields/${encodeURIComponent(String(surveyFieldId))}/responses`,
            createSurveyResponseDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public surveyControllerSurveyFilled(requestParameters: SurveyControllerSurveyFilledRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<SurveyResponseDto>>;
    public surveyControllerSurveyFilled(requestParameters: SurveyControllerSurveyFilledRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<SurveyResponseDto>>>;
    public surveyControllerSurveyFilled(requestParameters: SurveyControllerSurveyFilledRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<SurveyResponseDto>>>;
    public surveyControllerSurveyFilled(requestParameters: SurveyControllerSurveyFilledRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const surveyId = requestParameters.surveyId;
        if (surveyId === null || surveyId === undefined) {
            throw new Error('Required parameter surveyId was null or undefined when calling surveyControllerSurveyFilled.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<Array<SurveyResponseDto>>(`${this.configuration.basePath}/surveys/${encodeURIComponent(String(surveyId))}/filled`,
            null,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public surveyControllerUnassignSurvey(requestParameters: SurveyControllerUnassignSurveyRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public surveyControllerUnassignSurvey(requestParameters: SurveyControllerUnassignSurveyRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public surveyControllerUnassignSurvey(requestParameters: SurveyControllerUnassignSurveyRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public surveyControllerUnassignSurvey(requestParameters: SurveyControllerUnassignSurveyRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        const surveyId = requestParameters.surveyId;
        if (surveyId === null || surveyId === undefined) {
            throw new Error('Required parameter surveyId was null or undefined when calling surveyControllerUnassignSurvey.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/surveys/${encodeURIComponent(String(surveyId))}/unassign`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public surveyControllerUpdateSurvey(requestParameters: SurveyControllerUpdateSurveyRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<SurveyDto>;
    public surveyControllerUpdateSurvey(requestParameters: SurveyControllerUpdateSurveyRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<SurveyDto>>;
    public surveyControllerUpdateSurvey(requestParameters: SurveyControllerUpdateSurveyRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<SurveyDto>>;
    public surveyControllerUpdateSurvey(requestParameters: SurveyControllerUpdateSurveyRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const updateSurveyDto = requestParameters.updateSurveyDto;
        if (updateSurveyDto === null || updateSurveyDto === undefined) {
            throw new Error('Required parameter updateSurveyDto was null or undefined when calling surveyControllerUpdateSurvey.');
        }
        const surveyId = requestParameters.surveyId;
        if (surveyId === null || surveyId === undefined) {
            throw new Error('Required parameter surveyId was null or undefined when calling surveyControllerUpdateSurvey.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.patch<SurveyDto>(`${this.configuration.basePath}/surveys/${encodeURIComponent(String(surveyId))}`,
            updateSurveyDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public surveyControllerUpdateSurveyDefinition(requestParameters: SurveyControllerUpdateSurveyDefinitionRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<SurveyDefinitionDto>;
    public surveyControllerUpdateSurveyDefinition(requestParameters: SurveyControllerUpdateSurveyDefinitionRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<SurveyDefinitionDto>>;
    public surveyControllerUpdateSurveyDefinition(requestParameters: SurveyControllerUpdateSurveyDefinitionRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<SurveyDefinitionDto>>;
    public surveyControllerUpdateSurveyDefinition(requestParameters: SurveyControllerUpdateSurveyDefinitionRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const updateSurveyDefinitionDto = requestParameters.updateSurveyDefinitionDto;
        if (updateSurveyDefinitionDto === null || updateSurveyDefinitionDto === undefined) {
            throw new Error('Required parameter updateSurveyDefinitionDto was null or undefined when calling surveyControllerUpdateSurveyDefinition.');
        }
        const definitionId = requestParameters.definitionId;
        if (definitionId === null || definitionId === undefined) {
            throw new Error('Required parameter definitionId was null or undefined when calling surveyControllerUpdateSurveyDefinition.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.patch<SurveyDefinitionDto>(`${this.configuration.basePath}/surveys/definitions/${encodeURIComponent(String(definitionId))}`,
            updateSurveyDefinitionDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public surveyControllerUpdateSurveyField(requestParameters: SurveyControllerUpdateSurveyFieldRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<SurveyFieldDto>;
    public surveyControllerUpdateSurveyField(requestParameters: SurveyControllerUpdateSurveyFieldRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<SurveyFieldDto>>;
    public surveyControllerUpdateSurveyField(requestParameters: SurveyControllerUpdateSurveyFieldRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<SurveyFieldDto>>;
    public surveyControllerUpdateSurveyField(requestParameters: SurveyControllerUpdateSurveyFieldRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const updateSurveyFieldDto = requestParameters.updateSurveyFieldDto;
        if (updateSurveyFieldDto === null || updateSurveyFieldDto === undefined) {
            throw new Error('Required parameter updateSurveyFieldDto was null or undefined when calling surveyControllerUpdateSurveyField.');
        }
        const definitionId = requestParameters.definitionId;
        if (definitionId === null || definitionId === undefined) {
            throw new Error('Required parameter definitionId was null or undefined when calling surveyControllerUpdateSurveyField.');
        }
        const fieldId = requestParameters.fieldId;
        if (fieldId === null || fieldId === undefined) {
            throw new Error('Required parameter fieldId was null or undefined when calling surveyControllerUpdateSurveyField.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.patch<SurveyFieldDto>(`${this.configuration.basePath}/surveys/definitions/${encodeURIComponent(String(definitionId))}/fields/${encodeURIComponent(String(fieldId))}`,
            updateSurveyFieldDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
